import "./editCampaign.css";
import { Stack } from "react-bootstrap";
import "./campaignList.css";
import settingIcon from "../../assets/icons/overviewSeiingIcon.svg";
import { Search } from "react-bootstrap-icons";
import SplitDropdownBtn from "../../components/Buttons/splitDropdownBtn";
import calenderIcon from "../../assets/icons/calenderIcon.png";

import {
  TOAST_POSITION_TOP_RIGHT,
  TOAST_SUCCESS,
} from "../../constant/HardTypes";
import TableRb from "../../components/Tables/tableRb";
import SwitchBtn from "../../components/Buttons/switchBtn";
import editIcon from "../../assets/icons/edit.svg";
import {v4 as uuidv4} from 'uuid';
import { useDispatch } from "react-redux";
import { campaignListActions } from "../../redux/slices/campaignList";
import React, { useEffect, useState } from "react";
import SectionTittle from "../../components/sectionTitle";
import SecondaryBtnRb from "../../components/Buttons/secondaryBtnRb/secondaryBtnRb";
import "./editCampaignMainPage.css";
import settingOptionIcon from "../../assets/icons/overviewSeiingIcon.svg";
import SearchbarForEditCampaign from "../../components/searchbarForEditCampaign";
import CheckboxRb from "../../components/Buttons/checkbox/checkbox";
// import dashIcon from "../../assets/icons/dash-icon.png";
import dashIcon from "../../assets/svgs/minus_box.svg";
import ascendingIconSvg from "../../assets/svgs/acendingIconSvg.svg";
import descendingIconSvg from "../../assets/svgs/descendingIcon.svg";
import FilterLogicModal from "../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import AddTargetingModal from "../../components/modal/campaignListModals/addTargeting/addTargetingModal";
import SearchBarForCampaignListOnly from "../../components/searchBarForCampaignListOnly";
import Loader from "../../components/loader";
import { masterApi } from "../../apis/masterApi";
import { useSelector } from "react-redux";
import EditBidModal from "../../components/modal/campaignListModals/editBidModal";
import { useGlobalContext } from "../../context/global";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import redirect from "../../constant/Redirect";
import checkCircle from "../../assets/svgs/check_circle.svg";
import { roundFigureNum } from "../../constant/roundFigureNum";
import EnablePauseFilter from "../../helper/CampaignList/editTargeting/EnablePauseFilter";
import { consolidateTargetingStatData } from "../../helper/CampaignList/editTargeting/consolidatetargetingdata";
import { useAuthHeader } from "react-auth-kit";
import { onInputRangeChangeHandler } from "../../helper/CampaignList/filterByMetrices";
import getAdsetData from "../../helper/CampaignList/editTargeting/AsynCalls/getAdsetData";
import Spinner from "../../components/spinners/spinner";
import getCampaignData from "../../helper/CampaignList/editTargeting/AsynCalls/getCampaignData";
import MasterTagetinngActionAlertMModal from "../../components/modal/masterTargetinngActionnAlertMModal/masterTargetingActionnAlertModal";
import EditBidModalForTargeting from "../../components/modal/editBidModalForTargeting";
import { getCurrencySymbol } from "../../constant/defaultCurrencyConfigurations";
import { calculateACOS, calculateAOV, calculateCPC, calculateCPM, calculateCTR, calculateCVR, calculateROAS } from "../../helper/CalculateMetrices/calculateMetrics";
import useHighlightList from "../../hooks/useHighlightList";
import InfoChip from "../../components/Chips/InfoChip/InfoChip";
import {toast} from 'react-toastify';

const EditCampaign = () => {
  const client_id =
    JSON.parse(localStorage.getItem("clientId")) === null
      ? "aiekYz4hQy"
      : JSON.parse(localStorage.getItem("clientId"));
  const [minMaxFilterActivated, setMinMaxFilterActivated] = useState(false);
  const [selectedBtn, setselectedBtn] = useState("all-btn");
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const authHeader = useAuthHeader();
  const token = authHeader();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [modalToShow, setmodalToShow] = useState("");

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [isTargetingBoxChecked, setsTargetingBoxChecked] = useState(false);
  const [checkedAdGroupData, setCheckedAdGroupData] = useState([]);
  const { showAlert } = useGlobalContext();
  const [adsetTargetStatDataForTable, setAdsetTargetStatDataForTable] =
    useState([]);
  const [handleHighlightList] = useHighlightList(adsetTargetStatDataForTable,setAdsetTargetStatDataForTable)
  const [
    copyOfAdsetTargetStatDataForTable,
    setCopyOfAdsetTargetStatDataForTable,
  ] = useState([]);
  const editBtnclickedCampaignDetailsForEditTargetingTable = useSelector(
    (state) =>
      state.campaignListData.editBtnclickedCampaignDetailsForEditTargetingTable
  );
  // const { name } = editBtnclickedCampaignDetailsForEditTargetingTable?.adset;
  const { calendarDate, setCalendarDate , applyCalendar } = useGlobalContext();
  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );
  const [initialConsolidatedAdset, setInitialConsolidatedAdset] = useState([]);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [updateAdsetStatusOnToggle, setUpdateAdsetStatusOnToggle] = useState(
    []
  );
  const [dataHeads, setdataHeads] = useState([]);
  const [targettingTypeForTable, seTargettingTypeForTable] = useState("");
  const [updateTargetDataForBulk, setUpdateTargetDataForBulk] = useState([]);
  const getUpdatedConfigHeadsForEditAdsetTargetListSection = useSelector(
    (state) =>
      state.campaignListData.getUpdatedConfigHeadsForEditAdsetTargetListSection
  );
  const getUpdatedConfigHeadsForEditAdsetProductTargetListSection = useSelector(
    (state) =>
      state.campaignListData
        .getUpdatedConfigHeadsForEditAdsetProductTargetListSection
  );
  const targetingTypeForEditTargetSection = useSelector(
    (state) => state.campaignListData.targetingTypeForEditTargetSection
  );
  const actiontoEditCollectivebid = useSelector(
    (state) => state.campaignListData.actionEditCollectiveBidForMasterTargeting
  );
  const [btnToShowAsperTargetingType, setBtnToShowAsperTargetingType] =
    useState("");
  const [isSortBoxVisible, setIsSortBoxVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(5);
  const [FilteredData, setFilteredData] = useState([]);
  const [keywordDataForSuggestedBid, setKeywordDataForSuggestedBid] = useState(
    []
  );
  const [suggestedBidApiResponse, setsuggestedBidApiResponse] = useState([]);
  const bulkEditBidModalVisibilty = useSelector(
    (state) => state.campaignListData.bulkEditBidModalVisibilty
  );
  const [
    requestBodyForProductTargetingBid,
    setRequestBodyForProductTargetingBid,
  ] = useState([]);
  const [
    spProductTargetingSuggestedBidApiResponse,
    setSpProductTargetingSuggestedBidApiResponse,
  ] = useState([]);
  const [
    sdProductTargetingSuggestedBidApiResponse,
    setSdProductTargetingSuggestedBidApiResponse,
  ] = useState([]);
  const [totalEnabledCampaign, setTotalEnabledCampaign] = useState([]);
  const [totalPausedCampaign, setTotalPausedCampaign] = useState([]);
  const [modalBody, setModalBody] = useState("keyword");

  // Three Main States
  const [adsetData, setAdsetData] = useState([]);
  const [adsetStats, setAdsetStats] = useState([]);
  const [bidData, setBiddata] = useState([]);
  const [currentCampaign, setCurrentCampaign] = useState([]);
  const [loaders, setLoaders] = useState({ suggestedBidLoader: false });
  const [showAlertMsgOf, setShowAlertMsgOf] = useState("");
  const [alertModalCauseAction, setAlertModalCauseAction] = useState(null);
  const [
    currentEditecCampaignDefaultDetail,
    setCurrentEditecCampaignDefaultDetail,
  ] = useState({});
  const appliedFilterData = useSelector(
    (state) => state.campaignListData.appliedFilterData
  );

  const currencySymbol = getCurrencySymbol();
  const navigate = useNavigate();

  const { targetingName } = params;

  const [
    targetingNameLinkFromMasterTargeting,
    setTargetingNameLinkFromMasterTargeting,
  ] = useState(targetingName ? targetingName : null);

  const [text, setText] = useState(targetingName ? targetingName : "");


  const [sortingActiveKey,setSortingActiveKey] = useState("spends")

  useEffect(() => {
    if (requestBodyForProductTargetingBid.length) {
      if (
       ( currentCampaign?.objective === "SP" &&
        currentCampaign?.amz_targeting_type === "Product") || (currentCampaign?.objective === "SP" && currentCampaign?.amz_targeting_type === "Unknown" && adsetData?.amz_product_targeting)
      ) {
        geySuggestedBidForSPProductTargeting();
      } else if (
       ( currentCampaign?.objective === "SD" &&
        currentCampaign?.amz_targeting_type === "Product") || (currentCampaign?.objective === "SD" && currentCampaign?.amz_targeting_type === "Unknown" && adsetData?.amz_product_targeting)
      ) {
        getSuggestedBidFoeSdProduct();
      }
    }
  }, [requestBodyForProductTargetingBid]);

  const geySuggestedBidForSPProductTargeting = () => {
    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    var raw = JSON.stringify({
      type: "SP_MANUAL_PRODUCT",
      adset_id: params?.adsetId,
      targeting_clauses: requestBodyForProductTargetingBid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://adyogiadsapi.adyogi.com/client/${client_id}:fetch-bid-recommendation?source=amazon`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        const resultMain = JSON.parse(result);
        setSpProductTargetingSuggestedBidApiResponse(resultMain.data);
      })
      .catch((error) => console.log("error", error));
  };

  async function adSetTargetStatByCampNAdsetId(campaignId, adsetId) {
    setIsLoaderVisible(true);
    try {
      // let dataForEncodeForFilter = [];
      let dataForEncodeForFilter = `[{"field_name":"campaign_id","operator":"EQUALS","value":${campaignId}},{"field_name":"adset_id","operator":"IN","values":[${adsetId}]}]`;

      let dataForFiltering = encodeData(dataForEncodeForFilter);

      const data = {
        level: "adset",
        platform: "amazon",
        // date_preset: "last_7d",
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        fields:
          "campaign_id,adset_id,target_id,spends,roas,clicks,campaign_name,adset_name,target,match_type,sale_value,clicks,impressions,cpc,cvr,aov,acos,cpm,orders,ctr",
        group_by: "target_id",
        filtering: dataForFiltering,
      };
      const result = await masterApi(data, "targetStat");
      // const mainResult  =  consolidateTargetingStatData(result.data,editBtnclickedCampaignDetailsForEditTargetingTable.adset);
      // consolidateTargetStatData(
      //   result.data.data,
      //   editBtnclickedCampaignDetailsForEditTargetingTable.adset
      // );

      if (redirect(result)) return navigate("/login");
      // setAdsetTargetStatMainData(result.data.data);
      setIsLoaderVisible(false);
      return result;
    } catch (e) {
      setIsLoaderVisible(false);
    }
  }

  async function getSuggestedBid() {
    const biddingStatretegyUpdatedFormat =
      currentCampaign?.bidding?.bidding_strategy === "autoForSales"
        ? "AUTO_FOR_SALES"
        : currentCampaign?.bidding?.bidding_strategy === "legacyForSales"
        ? "LEGACY_FOR_SALES"
        : undefined;

    const myHeaders = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    const requestbodyForSuggestedBody = {
      type: "SP_KEYWORD",
      adset_id: params?.adsetId,
      keywords: keywordDataForSuggestedBid,
      bidding_strategy: biddingStatretegyUpdatedFormat,
    };

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestbodyForSuggestedBody),
      redirect: "follow",
    };

    setLoaders({ ...loaders, suggestedBidLoader: true });
    const response = await fetch(
      `https://adyogiadsapi.adyogi.com/client/${client_id}:fetch-bid-recommendation?source=amazon`,
      requestOptions
    );
    const resultMain = await response.json();
    setsuggestedBidApiResponse(resultMain.data);
    setLoaders({ ...loaders, suggestedBidLoader: false });
  }

  const getSuggestedBidFoeSdProduct = () => {
    var myHeaders = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    var raw = JSON.stringify({
      type: "SD_CATEGORY",
      adset_id: params?.adsetId,
      targeting_clauses: requestBodyForProductTargetingBid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // setIsLoaderVisible(true);
    fetch(
      `https://adyogiadsapi.adyogi.com/client/${client_id}:fetch-bid-recommendation?source=amazon`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {        // setIsLoaderVisible(false);
        const mainResult = JSON.parse(result);
        setSdProductTargetingSuggestedBidApiResponse(mainResult.data);
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    if (targetingTypeForEditTargetSection === "amz_keyword_targeting") {
      setBtnToShowAsperTargetingType(targetingTypeForEditTargetSection);
    } else if (targetingTypeForEditTargetSection === "amz_product_targeting") {
      setBtnToShowAsperTargetingType(targetingTypeForEditTargetSection);
    }
  }, [targetingTypeForEditTargetSection]);

  useEffect(() => {
    setUpdateTargetDataForBulk([]);
    setUpdateAdsetStatusOnToggle([]);
    async function getData() {
      try {
        const currentCampaignData = await getCampaignData(
          client_id,
          params?.campaignId
        );
        setCurrentCampaign(currentCampaignData);
        const adsetData = await getAdsetData(
          params?.campaignId,
          params?.adsetId,
          client_id
        );
        setAdsetData(adsetData);
        const adsetStats = await adSetTargetStatByCampNAdsetId(
          params?.numericCampaignId,
          params?.numericAdsetId
        );
        setAdsetStats(adsetStats?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
    getData();
  }, [applyCalendar]);

  useEffect(() => {
    // Data will be consolidated
    if(Object.keys(adsetData)?.length) {
    const consolidatedData = [
      ...consolidateTargetStatData(adsetStats, adsetData),
    ];
    let targettingTypeFortableDataShow = adsetData.amz_keyword_targeting
    ? "amz_keyword_targeting"
    : adsetData.amz_product_targeting
    ? "amz_product_targeting"
    : adsetData.amz_automatic_targeting
    ? "amz_automatic_targeting"
    : null;


    // Search Input  Filters
    const searchedData = onSearch(text, consolidatedData);
    // Data will go through Main filters
    const filteredData = onInputRangeChangeHandler(
      searchedData,
      appliedFilterData,
      setMinMaxFilterActivated
    );

    // Enabled Disable Count set
    const totalEnabledAdsets = filteredData.filter((adset,index) => {
      if(index!==0) {
        return adset[targettingTypeFortableDataShow][0]?.status === "ENABLED";
      }
    });
    const totalPausedAdsets = filteredData.filter((adset,index) => {
      if(index!==0) {
        return adset[targettingTypeFortableDataShow][0]?.status === "PAUSED";
      }
    });
    // Data will get filtered according to buttonStatus
    const dataWithEnableDisableStatus = EnablePauseFilter(
      targettingTypeForTable,
      filteredData,
      selectedBtn
    );

    const sortedData = onSortData(sortingActiveKey,dataWithEnableDisableStatus)

    // Necessary State Updates
    setTotalEnabledCampaign(totalEnabledAdsets);
    setTotalPausedCampaign(totalPausedAdsets);
    setAdsetTargetStatDataForTable(sortedData ?? []);
  } }, [adsetData, adsetStats, appliedFilterData, selectedBtn, text]);

  // useEffect(() => {
  //   adSetTargetStatByCampNAdsetId(params?.campaignId,params?.adsetId);
  // }, [
  //   editBtnclickedCampaignDetailsForEditTargetingTable,
  //   calendarDate,
  //   editAdGroupSectionCurrentselectedBtnName,
  // ]);

  useEffect(() => {
    if (updateTargetDataForBulk.length) {
      checkCampaignsForChanges("Bulkbid");
      dispatch(
        campaignListActions.actionEditCollectiveBidForMasterTargeting({})
      );
      uncheckedAllselectedTarget();
      setCheckedAdGroupData([]);
    }
  }, [updateTargetDataForBulk]);

  useEffect(() => {
    if (
      actiontoEditCollectivebid.targetIdsToChagedbid &&
      actiontoEditCollectivebid.targetIdsToChagedbid.length
    ) {
      setShowAlertMsgOf(
        "Are you sure you want to Change Bid For Selected Targeting"
      );
      setAlertModalCauseAction({
        alertCauseActionType: "bulkBid",
        fieldToUpdate: "bid",
      });
      dispatch(campaignListActions.showActionAlertModal());
    } else {
      let tempTargetStat = JSON.stringify(adsetTargetStatDataForTable);
      tempTargetStat = JSON.parse(tempTargetStat);

      for (let i = 0; i < tempTargetStat.length; i++) {
        tempTargetStat[i].isTargetChecked = false;
      }

      setCheckedAdGroupData([]);
      setAdsetTargetStatDataForTable(tempTargetStat);
      // setCopyOfAdsetTargetStatDataForTable(tempTargetStat);
    }
  }, [actiontoEditCollectivebid]);

  useEffect(() => {
    if (updateAdsetStatusOnToggle.length) {
      checkCampaignsForChanges("status");
      setCheckedAdGroupData([]);
      uncheckedAllselectedTarget();
    }
  }, [updateAdsetStatusOnToggle]);

  useEffect(() => {
    // Checking Targeting  Type keyword  or product
    // const checker =
    //   targetingTypeForEditTargetSection === "amz_keyword_targeting"
    //     ? getUpdatedConfigHeadsForEditAdsetTargetListSection
    //     : getUpdatedConfigHeadsForEditAdsetProductTargetListSection;

    // // If Modal is visible & No Filter selected then call the API
    // if (
    //   filterModalInCampaignListVisibilty &&
    //   checker.every((value) => value.maxRange === "" && value.minRange === "")
    // ) {
    //   // adSetTargetStatByCampNAdsetId();
    //   setAdsetTargetStatDataForTable(copyOfAdsetTargetStatDataForTable);
    // }

    // // If Modal is not visible & No Filter Selected then call the API
    // if (
    //   !filterModalInCampaignListVisibilty &&
    //   checker.every((value) => value.maxRange === "" && value.minRange === "")
    // ) {
    //   // dispatch(campaignListActions.appliedFilterData([]));
    //   // adSetTargetStatByCampNAdsetId();
    //   setAdsetTargetStatDataForTable(copyOfAdsetTargetStatDataForTable);
    // }
    setIsFilterVisible(filterModalInCampaignListVisibilty);
  }, [filterModalInCampaignListVisibilty]);

  useEffect(() => {
    // getUpdatedConfigHeadsForEditAdsetProductTargetListSection
    if (targetingTypeForEditTargetSection === "amz_keyword_targeting") {
      setdataHeads(getUpdatedConfigHeadsForEditAdsetTargetListSection);
    } else if (targetingTypeForEditTargetSection === "amz_product_targeting") {
      setdataHeads(getUpdatedConfigHeadsForEditAdsetProductTargetListSection);
    }
  }, [
    getUpdatedConfigHeadsForEditAdsetTargetListSection,
    getUpdatedConfigHeadsForEditAdsetProductTargetListSection,
    targetingTypeForEditTargetSection,
  ]);

  // useEffect(() => {
  //   // adSetTargetStatByCampNAdsetId();
  //   onInputRangeChangeHandler();
  // }, [appliedFilterData]);

  const NoClickHandler = () => {
    if (alertModalCauseAction.alertCauseActionType === "campaignStatus") {
      // onSwitchBtnHandler(
      //   alertModalCauseAction.id,
      //   alertModalCauseAction.currentSatus === "ENABLED" ? true : false
      // );
    }
    if (alertModalCauseAction.alertCauseActionType === "currentBid") {
      setInputValue(
        alertModalCauseAction.id,
        alertModalCauseAction.defaulBidValue !== "" &&
          alertModalCauseAction.defaulBidValue !== undefined &&
          alertModalCauseAction.defaulBidValue !== null
          ? alertModalCauseAction.defaulBidValue
          : ""
      );
    }

    dispatch(campaignListActions.hideActionAlertModal());
  };

  const onYesClickHandler = () => {
    if (alertModalCauseAction.alertCauseActionType === "campaignStatus") {
      onSwitchBtnHandler(
        alertModalCauseAction.id,
        alertModalCauseAction.updatedValue
      );
    }
    if (
      alertModalCauseAction.alertCauseActionType === "campaignStatusBulkPause"
    ) {
      bulkPausehandler("pause");
    }

    if(alertModalCauseAction.alertCauseActionType === "campaignStatusBulkEnable") {
      bulkPausehandler("enable")
    }

    if (alertModalCauseAction.alertCauseActionType === "currentBid") {
      checkCampaignsForChanges(alertModalCauseAction.fieldToUpdate);
    }
    if (alertModalCauseAction.alertCauseActionType === "bulkBid") {
      if (
        actiontoEditCollectivebid.targetIdsToChagedbid &&
        actiontoEditCollectivebid.targetIdsToChagedbid.length
      ) {
        // if (
        //   alertModalCauseAction.alertCauseActionType ===
        //   "chagedEditCollectiveBidInputOnSaveClick"
        // ) {
        dispatch(campaignListActions.hideActionAlertModal());
        setInputValue("", "", "bulkEdit");
        // }
      }
    }

    dispatch(campaignListActions.hideActionAlertModal());
  };

  const onButtonClickHandler = (e, type, value) => {
    setselectedBtn(e.target.id);
  };

  const onFilterIconhandler = () => {
    dispatch(campaignListActions.showFilterModalInCampaignList());
  };
  const encodeData = (data) => {
    let encodedData = encodeURIComponent(data);
    return encodedData;
  };

  const formatDateFn = (date) => {
    const selectedDate = new Date(date);
    const formattedDate =
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear();
    return (
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear()
    );
  };

  useEffect(() => {
    formatDateFn(calendarDate[0].startDate);
  }, []);

  useEffect(() => {
    if (keywordDataForSuggestedBid.length) {
      getSuggestedBid();
    }
  }, [keywordDataForSuggestedBid]);

  useEffect(() => {
    if (adsetTargetStatDataForTable && adsetTargetStatDataForTable.length) {
      let tempTargetStat = JSON.stringify(adsetTargetStatDataForTable);
      tempTargetStat = JSON.parse(tempTargetStat);

      for (let l = 0; l < tempTargetStat.length; l++) {
        if (suggestedBidApiResponse && suggestedBidApiResponse.length) {
          for (let j = 0; j < suggestedBidApiResponse.length; j++) {
            if (
              suggestedBidApiResponse[j].keyword == tempTargetStat[l].target &&
              suggestedBidApiResponse[j].keyword_type ==
                tempTargetStat[l].match_type
            ) {
              tempTargetStat[l].suggestedBid =
                suggestedBidApiResponse[j].recommended_suggested_bid;
            }
          }
        }

        if (
          spProductTargetingSuggestedBidApiResponse &&
          spProductTargetingSuggestedBidApiResponse.length
        ) {
          for (
            let t = 0;
            t < spProductTargetingSuggestedBidApiResponse.length;
            t++
          ) {
            //here we are matching indexes

            if (t == l) {
              tempTargetStat[l].suggestedBid =
                spProductTargetingSuggestedBidApiResponse[
                  t
                ].recommended_suggested_bid;
            }
          }
        }

        if (
          sdProductTargetingSuggestedBidApiResponse &&
          sdProductTargetingSuggestedBidApiResponse.length
        ) {
          for (
            let h = 0;
            h < sdProductTargetingSuggestedBidApiResponse.length;
            h++
          ) {
            //here we are matching indexes

            if (h == l) {
              tempTargetStat[l].suggestedBid =
                sdProductTargetingSuggestedBidApiResponse[
                  h
                ].recommended_suggested_bid;
            }
          }
        }
      }
      setAdsetTargetStatDataForTable(tempTargetStat);
      setCopyOfAdsetTargetStatDataForTable(tempTargetStat);
      // setInitialConsolidatedAdset(tempTargetStat);
    }
  }, [
    suggestedBidApiResponse,
    spProductTargetingSuggestedBidApiResponse,
    sdProductTargetingSuggestedBidApiResponse,
  ]);

  useEffect(() => {
    // if (
    //   selectedBtn === "all-btn" ||
    //   selectedBtn === "enabled-btn" ||
    //   selectedBtn === "PAUSED"
    // ) {
    //   setTotalEnabledCampaign([]);
    //   setTotalPausedCampaign([]);
    //   for (let i = 0; i < adsetTargetStatDataForTable.length; i++) {
    //     if (
    //       adsetTargetStatDataForTable[i][targetingTypeForEditTargetSection][0].status ===
    //       "ENABLED"
    //     ) {
    //       setTotalEnabledCampaign((totalEnabledCampaign) => [
    //         ...totalEnabledCampaign,
    //         adsetTargetStatDataForTable[i].status,
    //       ]);
    //     }
    //     if (
    //       adsetTargetStatDataForTable[i][targetingTypeForEditTargetSection][0].status ===
    //       "PAUSED"
    //     ) {
    //       setTotalPausedCampaign((totalPausedCampaign) => [
    //         ...totalPausedCampaign,
    //         adsetTargetStatDataForTable[i].status,
    //       ]);
    //     }
    //   }
    // }
  }, [
    // initialConsolidatedAdset,
    adsetTargetStatDataForTable,
    // filteredAdsetData,
  ]);

  // useEffect(() => {
  //   // setCopyOfAdsetTargetStatDataForTable(adsetTargetStatDataForTable);
  // }, []);

  // useEffect(() => {
  //   // Checking if there is no filter applied
  //   let checker =
  //     initialConsolidatedAdset.length === adsetTargetStatDataForTable.length;
  //   setAdsetTargetStatDataForTable(initialConsolidatedAdset);
  //   // setCopyOfAdsetTargetStatDataForTable(initialConsolidatedAdset);
  //   // No filter then real state otherwise filteredState
  //   let adsetTargetDataCopyArray = checker
  //     ? copyOfAdsetTargetStatDataForTable
  //     : filteredAdsetData;
  //   const dataRecieved = EnablePauseFilter(
  //     targetingTypeForEditTargetSection,
  //     adsetTargetDataCopyArray,
  //     selectedBtn
  //   );
  //   setAdsetTargetStatDataForTable(dataRecieved);
  // }, [selectedBtn, filteredAdsetData]);

  const consolidateTargetStatData = (targetStat, adset) => {

    let targettingTypeFortableDataShow = adset.amz_keyword_targeting
      ? "amz_keyword_targeting"
      : adset.amz_product_targeting
      ? "amz_product_targeting"
      : adset.amz_automatic_targeting
      ? "amz_automatic_targeting"
      : null;

    seTargettingTypeForTable(targettingTypeFortableDataShow);

    let consolidatedTargetWithBid = [];
    let keywordDataForSugBid = [];

     consolidatedTargetWithBid = adset[targettingTypeFortableDataShow]?.map((data) => {
      let modifiedData = {}
      modifiedData[targettingTypeFortableDataShow] = [data]
      const foundedKey = targetStat?.find((targetStatEl) => targetStatEl?.target_id == data?.id)
      if(foundedKey) {
        foundedKey.iseditBudgetValueDisabled = true
        foundedKey.isTargetChecked = false
        foundedKey.suggestedBid = "-"
        foundedKey.defaultBidCopy = data?.bid
      }
      if (
        (currentCampaign?.objective == "SP" &&
        currentCampaign.amz_targeting_type == "Keyword") || ((targettingTypeFortableDataShow.includes("keyword") && currentCampaign?.amz_targeting_type === "Unknown"))
      )  {
        keywordDataForSugBid?.push({value:data?.text,type:data?.match?.toUpperCase()})
      }
      return {
        ...modifiedData,
        target: targettingTypeFortableDataShow ==="amz_keyword_targeting" ?  data?.text : (data?.expression[0]?.name ?? data?.expression[0]?.value),
        iseditBudgetValueDisabled:true,
        isTargetChecked:false,
        suggestedBid:"-",
        defaultBidCopy:data?.bid,
        target_id:Number(data?.id),
        match_type:data?.match?.toUpperCase(),
        ...(foundedKey && {...foundedKey})
      }
    }) ?? []

      // for (let i = 0; i < targetStat?.length; i++) {
      //   if (adset.amz_keyword_targeting) {
      //     targetStat[i].amz_keyword_targeting = [];
          
      //   }
      //   if (adset.amz_product_targeting) {
      //     targetStat[i].amz_product_targeting = [];
      //   }
      //   if (adset.amz_audience_targeting) {
      //     targetStat[i].amz_audience_targeting = [];
      //   }
  
      //   targetStat[i].iseditBudgetValueDisabled = true;
      //   targetStat[i].isTargetChecked = false;
      //   targetStat[i].suggestedBid = "-";
  
      //   for (let t = 0; t < adset[targettingTypeFortableDataShow]?.length; t++) {
      //     if (
      //       targetStat[i].target_id == adset[targettingTypeFortableDataShow][t].id
      //     ) {
      //       if (adset.amz_keyword_targeting) {
      //         targetStat[i].defaultBidCopy =
      //           adset[targettingTypeFortableDataShow][t].bid;
      //         targetStat[i].amz_keyword_targeting.push(
      //           adset[targettingTypeFortableDataShow][t]
      //         );
      //         consolidatedTargetWithBid.push(targetStat[i]);
  
      //         if (
      //           (currentCampaign?.objective == "SP" &&
      //           currentCampaign.amz_targeting_type == "Keyword") || ((targettingTypeFortableDataShow.includes("keyword") && currentCampaign?.amz_targeting_type === "Unknown"))
      //         ) {
      //           let keywordDataObject = {
      //             type: targetStat[i].match_type,
  
      //             value: targetStat[i].target,
      //           };
      //           keywordDataForSugBid.push(keywordDataObject);
      //         }
      //       } else if (adset.amz_product_targeting) {
      //         targetStat[i].defaultBidCopy =
      //           adset[targettingTypeFortableDataShow][t].bid;
      //         targetStat[i].amz_product_targeting.push(
      //           adset[targettingTypeFortableDataShow][t]
      //         );
      //         consolidatedTargetWithBid.push(targetStat[i]);
      //       } else if (adset.amz_automatic_targeting) {
      //         targetStat[i].defaultBidCopy =
      //           adset[targettingTypeFortableDataShow][t].bid;
      //         targetStat[i].amz_automatic_targeting.push(
      //           adset[targettingTypeFortableDataShow][t]
      //         );
      //         consolidatedTargetWithBid.push(targetStat[i]);
      //       }
      //     }
      //   }
      // }
    dispatch(
      campaignListActions.targetingTypeForEditTargetSection(
        targettingTypeFortableDataShow
      )
    );

    setKeywordDataForSuggestedBid(keywordDataForSugBid);

    if (
      (currentCampaign.objective === "SP" &&
      currentCampaign.amz_targeting_type === "Product") || ((currentCampaign?.amz_targeting_type === "Unknown") && (currentCampaign?.objective === "SP"))
    ) {
      addBidSpProduct(consolidatedTargetWithBid);
    }
    if (
     ( currentCampaign?.objective === "SD" &&
      currentCampaign?.amz_targeting_type === "Product") || ((currentCampaign?.amz_targeting_type === "Unknown") && (currentCampaign?.objective === "SD"))
    ) {
      addBidSDProduct(consolidatedTargetWithBid); 
    }
    let totalSummaryObject
    if(consolidatedTargetWithBid?.length>0) {
      const totalClicks = consolidatedTargetWithBid.reduce((sum,target) => sum + (target?.clicks === undefined?0:target?.clicks),0)
      const totalImpressions = consolidatedTargetWithBid.reduce((sum,target) => sum + (target?.impressions === undefined?0:target?.impressions),0)
      const totalOrders  = calculateTotal(consolidatedTargetWithBid,"orders")
      const totalSpends = calculateTotal(consolidatedTargetWithBid,"spends")
      const totalRevenue = calculateTotal(consolidatedTargetWithBid,"sale_value")
      totalSummaryObject = {
        "clicks": totalClicks,
        "impressions": totalImpressions,
        "orders": calculateTotal(consolidatedTargetWithBid,"orders"),
        "cpm": calculateCPM(totalSpends,totalImpressions),
        "ctr": calculateCTR(totalClicks,totalImpressions),
        "cpc": calculateCPC(totalSpends,totalClicks),
        "cvr": calculateCVR(totalOrders,totalClicks),
        "roas": calculateROAS(totalRevenue,totalSpends),
        "spends": calculateTotal(consolidatedTargetWithBid,"spends"),
        "sale_value":calculateTotal(consolidatedTargetWithBid,"sale_value"),
        "acos":calculateACOS(totalSpends,totalRevenue),
        "aov":calculateAOV(totalRevenue,totalOrders),
        "campaign_name": "",
        "campaign_id": uuidv4(),
        "target": "",
        "adset_id": uuidv4(),
        "adset_name": "",
        "target_id": uuidv4(),
        "amz_product_targeting": [
            {
                "status": "",
                "expression": [
                    {
                        "type": "",
                        "value": uuidv4(),
                        "name": null
                    }
                ],
                "bid": "",
                "id": uuidv4()
            }
        ],
        "iseditBudgetValueDisabled": true,
        "isSummaryRow":true,
        "isTargetChecked": false,
        "suggestedBid": "-",
        "defaultBidCopy": ""
    }
    }
    // const totalSummaryObject = {
    //     "clicks": consolidatedTargetWithBid.reduce((sum,target) => sum + target.clicks,0),
    //     "impressions": consolidatedTargetWithBid.reduce((sum,target) => sum + target.impressions,0),
    //     "orders": calculateTotal(consolidatedTargetWithBid,"orders"),
    //     "cpm": calculateTotal(consolidatedTargetWithBid,"cpm"),
    //     "ctr": calculateTotal(consolidatedTargetWithBid,"ctr"),
    //     "cpc": calculateTotal(consolidatedTargetWithBid,"cpc"),
    //     "cvr": calculateTotal(consolidatedTargetWithBid,"cvr"),
    //     "roas": calculateTotal(consolidatedTargetWithBid,"sale_value")/calculateTotal(consolidatedTargetWithBid,"spends"),
    //     "spends": calculateTotal(consolidatedTargetWithBid,"spends"),
    //     "sale_value":calculateTotal(consolidatedTargetWithBid,"sale_value"),
    //     "acos":calculateTotal(consolidatedTargetWithBid,"acos"),
    //     "aov":calculateTotal(consolidatedTargetWithBid,"aov"),
    //     "campaign_name": "Brand | SD | PT | Self",
    //     "campaign_id": "113427331002635",
    //     "target": "asin=\"B08L6LNCY9\"",
    //     "adset_id": "194271153374849",
    //     "adset_name": "Upsell",
    //     "target_id": 194086978699843,
    //     "amz_product_targeting": [
    //         {
    //             "status": "ENABLED",
    //             "expression": [
    //                 {
    //                     "type": "product",
    //                     "value": "B08L6LNCY9",
    //                     "name": null
    //                 }
    //             ],
    //             "bid": 21.61,
    //             "id": "194086978699843"
    //         }
    //     ],
    //     "iseditBudgetValueDisabled": true,
    //     "isSummaryRow":true,
    //     "isTargetChecked": false,
    //     "suggestedBid": "-",
    //     "defaultBidCopy": 21.61
    // }
    return [totalSummaryObject,...consolidatedTargetWithBid]  
  };

  const calculateTotal = (array,key) => {
    return array.reduce((acc,arr) => acc + (arr[key]===undefined ? 0 : arr[key]),0)
  }

  const addBidSpProduct = (adsetTargetStatDataForTable) => {
    if (adsetData?.amz_product_targeting?.length) {
      let targetingClausesDataForRequestBody = [];
      let allExpression = {};

      for (let i = 0; i < adsetTargetStatDataForTable.length; i++) {
        let expressions = [];
        for (
          let j = 0;
          j <
          adsetTargetStatDataForTable[i].amz_product_targeting[0].expression
            .length;
          j++
        ) {
          let cuurentExpression =
            adsetTargetStatDataForTable[i].amz_product_targeting[0].expression[
              j
            ];
          let expressionObject = {};
          if (cuurentExpression) {
            expressionObject = {
              type: cuurentExpression.type,
              value: cuurentExpression.value,
            };
            expressions.push(expressionObject);
          }
        }

        targetingClausesDataForRequestBody.push(expressions);
      }
      setRequestBodyForProductTargetingBid(targetingClausesDataForRequestBody);
    }
  };
  const addBidSDProduct = (adsetTargetStatDataForTable) => {
    if (adsetData?.amz_product_targeting?.length) {
      let targetingClausesDataForRequestBody = [];
      let allExpression = {};
      for (let i = 0; i < adsetTargetStatDataForTable.length; i++) {
        let expressions = [];
        for (
          let j = 0;
          j <
          adsetTargetStatDataForTable[i].amz_product_targeting[0].expression
            .length;
          j++
        ) {
          let cuurentExpression =
            adsetTargetStatDataForTable[i].amz_product_targeting[0].expression[
              j
            ];
          let expressionObject = {};
          if (cuurentExpression) {
            expressionObject = {
              type: cuurentExpression.type,
              value: cuurentExpression.value,
            };
            expressions.push(expressionObject);
          }
        }
        let expressionsData = {
          expressions: expressions,
        };
        targetingClausesDataForRequestBody.push(expressionsData);
      }
      setRequestBodyForProductTargetingBid(targetingClausesDataForRequestBody);
    }
  };
  const addKeywordHandler = (body) => {
    setIsVisible(!isVisible);

    if (body === "keyword") {
      setModalBody("keyword");
    } else {
      setModalBody("product");
    }
    setmodalToShow("addTargeting");
  };
  const filterClickHandler = () => {
    setIsVisible(!isVisible);
    setmodalToShow("filter");
  };

  const uncheckedAllselectedTarget = () => {
    let tempTargetStat = JSON.stringify(adsetTargetStatDataForTable);
    tempTargetStat = JSON.parse(tempTargetStat);

    for (let i = 0; i < tempTargetStat.length; i++) {
      tempTargetStat[i].isTargetChecked = false;
    }
    setCheckedAdGroupData((checkedAdGroupData) => [...checkedAdGroupData]);
    setsTargetingBoxChecked(false);
    setAdsetTargetStatDataForTable(tempTargetStat);
  };

  const onSortData = (value,data) => {
    let tempTargetStat = JSON.stringify(data);
      tempTargetStat = JSON.parse(tempTargetStat);
    // if(data?.length && initialLoad) {
      const mainValue = value === "REVENUE" ? "sale_value" : value;
      tempTargetStat = tempTargetStat?.sort((a, b) => {
        // if(a.isSummaryRow) return 0
        // if(b.isSummaryRow) return 0
          let x = a?.[mainValue.toLowerCase()] ?? 0; // This method will check if  the data is undefined , null false then will return 0
          let y = b?.[mainValue.toLowerCase()] ?? 0;
          return y-x;
      });
      // setAdsetTargetStatDataForTable(tempTargetStat);
      // setInitialLoad(false)
    // }
    return tempTargetStat
  }

  const onSpendFilterClickHandler = (value, index) => {
    let tempTargetStat = JSON.stringify(adsetTargetStatDataForTable);
    tempTargetStat = JSON.parse(tempTargetStat);
    const mainValue = value === "REVENUE" ? "sale_value" : value;
    tempTargetStat = tempTargetStat?.sort((a, b) => {
      if(a.isSummaryRow) return -1
      if(b.isSummaryRow) return 1
        let x = a?.[mainValue.toLowerCase()] ?? 0; // This method will check if  the data is undefined , null false then will return 0
        let y = b?.[mainValue.toLowerCase()] ?? 0;
        return isSortBoxVisible ? y - x : x - y;
    });
    setAdsetTargetStatDataForTable(tempTargetStat);
    setIsSortBoxVisible(!isSortBoxVisible);
    setCurrentIndex(index);
    setSortingActiveKey(mainValue?.toLowerCase())

  };

  const checkboxHandler = (e, targetId) => {
    let tempTargetStat = JSON.stringify(adsetTargetStatDataForTable);
    tempTargetStat = JSON.parse(tempTargetStat);

    for (let i = 0; i < tempTargetStat.length; i++) {
      if (tempTargetStat[i].target_id === targetId) {
        if (tempTargetStat[i].isTargetChecked) {
          tempTargetStat[i].isTargetChecked = false;

          for (let index = 0; index < checkedAdGroupData.length; index++) {
            if (
              checkedAdGroupData[index].targetId &&
              checkedAdGroupData[index].targetId === targetId
            ) {
              checkedAdGroupData.splice(index, 1);
            }
          }
          setCheckedAdGroupData((checkedAdGroupData) => [
            ...checkedAdGroupData,
          ]);
          setsTargetingBoxChecked(false);
        } else {
          tempTargetStat[i].isTargetChecked = true;

          setsTargetingBoxChecked(true);
          setCheckedAdGroupData((checkedAdGroupData) => [
            ...checkedAdGroupData,
            { targetId: targetId, checkedStatus: true },
          ]);
        }
      }
    }
    setAdsetTargetStatDataForTable(tempTargetStat);
    // setCopyOfAdsetTargetStatDataForTable(tempTargetStat);
  };

  const updateAdsetOnServer = (
    campaignId,
    adSetInstance,
    fieldsToUpdate,
    type
  ) => {
    let fieldsToUpdateType = fieldsToUpdate.amz_keyword_targeting
      ? fieldsToUpdate.amz_keyword_targeting
      : fieldsToUpdate.amz_product_targeting
      ? fieldsToUpdate.amz_product_targeting
      : fieldsToUpdate.amz_automatic_targeting
      ? fieldsToUpdate.amz_automatic_targeting
      : null;

    let keyToUpdate = fieldsToUpdate.amz_keyword_targeting
      ? "amz_keyword_targeting"
      : fieldsToUpdate.amz_product_targeting
      ? "amz_product_targeting"
      : fieldsToUpdate.amz_automatic_targeting
      ? "amz_automatic_targeting"
      : null;

    var myHeaders = {
      "Content-Type": "application/merge-patch+json",
      Authorization: token,
    };

    let preRaw = {};
    if (keyToUpdate) {
      preRaw[keyToUpdate] = fieldsToUpdateType;
    }
    var raw = JSON.stringify(preRaw);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    // setIsLoaderVisible(true);
    fetch(
      `https://adyogiadsapi.adyogi.com/client/${client_id}/campaigns/${campaignId}/adsets/${adSetInstance}?source=amazon`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setIsLoaderVisible(false);
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_SUCCESS,
        //   title: "Success",
        //   description: `Targeting ${type} Updated Successfully`,
        // });
        toast.error(`Targeting ${type} Updated Successfully`,)
        if (JSON.parse(result).success) {
          async function getData() {
            try {
              const adsetData = await getAdsetData(
                params?.campaignId,
                params?.adsetId,
                client_id
              );
              setAdsetData(adsetData);
            } catch (error) {
              console.log(error);
            }
          }
          getData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkCampaignsForChanges = (type) => {
    let adssetInstCopy = JSON.stringify(adsetData);
    adssetInstCopy = JSON.parse(adssetInstCopy);
    let adsetInstTargetingType = adssetInstCopy.amz_keyword_targeting
      ? adssetInstCopy.amz_keyword_targeting
      : adssetInstCopy.amz_product_targeting
      ? adssetInstCopy.amz_product_targeting
      : adssetInstCopy.amz_automatic_targeting
      ? adssetInstCopy.amz_automatic_targeting
      : null;

    for (let i = 0; i < adsetTargetStatDataForTable.length; i++) {
      let initialConsolidatedAdsettargetingtype = adsetTargetStatDataForTable[i]
        .amz_keyword_targeting
        ? adsetTargetStatDataForTable[i].amz_keyword_targeting
        : adsetTargetStatDataForTable[i].amz_product_targeting
        ? adsetTargetStatDataForTable[i].amz_product_targeting
        : adsetTargetStatDataForTable[i].amz_automatic_targeting
        ? adsetTargetStatDataForTable[i].amz_automatic_targeting
        : null;

      if (initialConsolidatedAdsettargetingtype.length) {
        for (let u = 0; u < initialConsolidatedAdsettargetingtype.length; u++) {
          if (
            initialConsolidatedAdsettargetingtype[u] !=
            initialConsolidatedAdsettargetingtype[0][u]
          ) {
            for (
              let index = 0;
              index < adsetInstTargetingType.length;
              index++
            ) {
              for (let e = 0; e < adsetTargetStatDataForTable.length; e++) {
                let adsetTargetStatDataForTabletargetingType =
                  adsetTargetStatDataForTable[e].amz_keyword_targeting
                    ? adsetTargetStatDataForTable[e].amz_keyword_targeting
                    : adsetTargetStatDataForTable[e].amz_product_targeting
                    ? adsetTargetStatDataForTable[e].amz_product_targeting
                    : adsetTargetStatDataForTable[e].amz_automatic_targeting
                    ? adsetTargetStatDataForTable[e].amz_automatic_targeting
                    : null;

                if (type === "bid") {
                  if (adsetTargetStatDataForTabletargetingType.length) {
                    if (
                      adsetInstTargetingType[index].id ==
                      adsetTargetStatDataForTabletargetingType[0].id
                    ) {
                      adsetInstTargetingType[index].bid =
                        adsetTargetStatDataForTabletargetingType[0].bid;
                    }
                  }
                }
                if (type === "Bulkbid") {
                  if (adsetTargetStatDataForTabletargetingType.length) {
                    if (
                      adsetInstTargetingType[index].id ==
                      adsetTargetStatDataForTabletargetingType[0].id
                    ) {
                      adsetInstTargetingType[index].bid =
                        adsetTargetStatDataForTabletargetingType[0].bid;
                    }
                  }
                }
                if (type === "status") {
                  if (adsetTargetStatDataForTabletargetingType.length) {
                    if (
                      adsetInstTargetingType[index].id ==
                      adsetTargetStatDataForTabletargetingType[0].id
                    ) {
                      adsetInstTargetingType[index].status =
                        adsetTargetStatDataForTabletargetingType[0].status;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    updateAdsetOnServer(
      params.campaignId,
      params.adsetId,
      adssetInstCopy,
      type
    );
  };

  const editBidHandler = () => {
    // setIsVisible(!isVisible);
    // setmodalToShow("editBid");
    dispatch(campaignListActions.showBulkEditBidModal());
    // dispatch(campaignListActions.targetCheckedForBulkEdit(checkedAdGroupData));
    dispatch(
      campaignListActions.checkedTargetDataForBulEdit(checkedAdGroupData)
    );
  };

  const setInputValue = (id, updatedValue, type) => {
    setAdsetTargetStatDataForTable((adsetTargetStatDataForTable) => [
      ...adsetTargetStatDataForTable,
    ]);
    let tempAdset = JSON.stringify(adsetTargetStatDataForTable);
    tempAdset = JSON.parse(tempAdset);
    tempAdset.map((targetAdsetInstance) => {
      let tempAdseetInstTargetingType =
        targetAdsetInstance.amz_keyword_targeting
          ? targetAdsetInstance.amz_keyword_targeting
          : targetAdsetInstance.amz_product_targeting
          ? targetAdsetInstance.amz_product_targeting
          : "";

      if (tempAdseetInstTargetingType && tempAdseetInstTargetingType.length) {
        if (
          actiontoEditCollectivebid.targetIdsToChagedbid &&
          actiontoEditCollectivebid.targetIdsToChagedbid.length
        ) {
          for (
            let l = 0;
            l < actiontoEditCollectivebid.targetIdsToChagedbid.length;
            l++
          ) {
            if (
              targetAdsetInstance.target_id ===
              actiontoEditCollectivebid.targetIdsToChagedbid[l].targetId
            ) {
              for (let u = 0; u < tempAdseetInstTargetingType.length; u++) {
                if (type === "bulkEdit") {
                  let valueForUpdate;
                  if (
                    targetAdsetInstance.target_id ===
                    actiontoEditCollectivebid.targetIdsToChagedbid[l].targetId
                  ) {
                    if (
                      actiontoEditCollectivebid.selectedAction == "increase-by"
                    ) {
                      tempAdseetInstTargetingType[u].bid =
                        Number(tempAdseetInstTargetingType[u].bid) +
                        (tempAdseetInstTargetingType[u].bid / 100) *
                          actiontoEditCollectivebid.valuePercentage;
                    } else if(actiontoEditCollectivebid.selectedAction == "decrease-by") {
                      tempAdseetInstTargetingType[u].bid =
                        Number(tempAdseetInstTargetingType[u].bid) -
                        (tempAdseetInstTargetingType[u].bid / 100) *
                          actiontoEditCollectivebid.valuePercentage;
                    } else {
                      tempAdseetInstTargetingType[u].bid = actiontoEditCollectivebid?.setAsValue
                    }
                  }
                }
              }
            }
          }
          setUpdateTargetDataForBulk([...tempAdset]);
        } else if (targetAdsetInstance.target_id === id) {
          for (let u = 0; u < tempAdseetInstTargetingType.length; u++) {
            if (targetAdsetInstance.target_id === id) {
              tempAdseetInstTargetingType[u].bid = updatedValue;
            }
          }
        }
      }
    });
    setAdsetTargetStatDataForTable(tempAdset);
    // setCopyOfAdsetTargetStatDataForTable(tempAdset);
  };

  const campaignEditClickHandler = (id) => {
    let campaignStatusValue;
    let tempAdset = JSON.stringify(adsetTargetStatDataForTable);
    tempAdset = JSON.parse(tempAdset);
    tempAdset.map((targetAdsetInstance) => {
      if (targetAdsetInstance.target_id == id) {
        if (!targetAdsetInstance.iseditBudgetValueDisabled) {
          // checkCampaignsForChanges("bid");
          // if (!editState) {
          // let campaignInsCopy = campaignInstance;
          dispatch(campaignListActions.showActionAlertModal());
          setShowAlertMsgOf("Are you sure you want to Change Bid");
          setAlertModalCauseAction({
            alertCauseActionType: "currentBid",
            id: id,
            fieldToUpdate: "bid",
            defaulBidValue: targetAdsetInstance.defaultBidCopy,
            // campaignNeedToUpdate: campaignInsCopy,
          });
          // }
        }
        if (!targetAdsetInstance.iseditBudgetValueDisabled) {
          targetAdsetInstance.iseditBudgetValueDisabled = true;
          return;
        }
        if (targetAdsetInstance.target_id === id) {
          targetAdsetInstance.iseditBudgetValueDisabled = false;
        }
      }
    });

    setAdsetTargetStatDataForTable(tempAdset);
    setCopyOfAdsetTargetStatDataForTable(tempAdset);
  };

  const mainBulkPauseHandler = (status) => {
    const type = status === "Enable" ? "ENABLED": "PAUSED";
    const actionType = status === "Enable" ? "campaignStatusBulkEnable":"campaignStatusBulkPause"
    setShowAlertMsgOf(`Are you sure you want to ${type} Selected Targeting`);
    setAlertModalCauseAction({
      alertCauseActionType: actionType,
    });
    dispatch(campaignListActions.showActionAlertModal());
  };
  const bulkPausehandler = (status) => {
    let tempTargetStat = JSON.stringify(adsetTargetStatDataForTable);
    tempTargetStat = JSON.parse(tempTargetStat);
    if (checkedAdGroupData.length) {
      for (let r = 0; r < tempTargetStat.length; r++) {
        let temptargetStatType = tempTargetStat[r].amz_keyword_targeting
          ? tempTargetStat[r].amz_keyword_targeting
          : tempTargetStat[r].amz_product_targeting
          ? tempTargetStat[r].amz_product_targeting
          : tempTargetStat[r].amz_automatic_targeting
          ? tempTargetStat[r].amz_automatic_targeting
          : null;

        for (let u = 0; u < checkedAdGroupData.length; u++) {
          if (checkedAdGroupData[u].targetId === tempTargetStat[r].target_id) {
            for (let i = 0; i < temptargetStatType.length; i++) {
              temptargetStatType[i].status = status === "enable"?"ENABLED":"PAUSED";
            }
          }
        }
      }
    }
    setAdsetTargetStatDataForTable(tempTargetStat);
    setCopyOfAdsetTargetStatDataForTable(tempTargetStat);

    setUpdateAdsetStatusOnToggle(tempTargetStat);
  };

  const onMainSwitchBtnToggleHandler = (id, updatedValue, currentSatus) => {
    if (updatedValue) {
      setShowAlertMsgOf("Are you sure you want to ENABLED Targeting");
      setAlertModalCauseAction({
        id: id,
        alertCauseActionType: "campaignStatus",
        updatedValue: updatedValue,
      });
    } else {
      setShowAlertMsgOf("Are you sure you want to PAUSED Targeting");
      setAlertModalCauseAction({
        id: id,
        alertCauseActionType: "campaignStatus",
        updatedValue: updatedValue,
      });
    }
    dispatch(campaignListActions.showActionAlertModal());
  };

  const onAllSelectHandler = (checked) => {
    const allTargetings = JSON.parse(JSON.stringify(adsetTargetStatDataForTable))
    const updatedTargetings = allTargetings?.map((data) => {
      if(data?.target_id && !data?.isSummaryRow) {
        data.isTargetChecked = checked
      }
      return data
    })
    const reducedDataForBulkSelection = allTargetings?.reduce((acc,val) => {
      if(val?.target_id && !val?.isSummaryRow) {
        acc.push({
          targetId:val?.target_id,
          checkedStatus:checked
        }) 
      } 
      return acc
    },[])
    setCheckedAdGroupData( checked ?[...reducedDataForBulkSelection]:[])
    setAdsetTargetStatDataForTable([...updatedTargetings])
  }

  const onSwitchBtnHandler = (id, updatedValue) => {
    let adsetInstanceToUpdate = undefined;
    let tempTargetStat = JSON.stringify(adsetTargetStatDataForTable);
    tempTargetStat = JSON.parse(tempTargetStat);

    for (let r = 0; r < tempTargetStat.length; r++) {
      let type = tempTargetStat[r].amz_keyword_targeting
        ? tempTargetStat[r].amz_keyword_targeting
        : tempTargetStat[r].amz_product_targeting
        ? tempTargetStat[r].amz_product_targeting
        : "";

      if (tempTargetStat[r].target_id === id) {
        if (updatedValue === true) {
          for (let i = 0; i < type.length; i++) {
            type[i].status = "ENABLED";
          }
        } else {
          for (let i = 0; i < type.length; i++) {
            type[i].status = "PAUSED";
          }
        }
        adsetInstanceToUpdate = tempTargetStat;
      }
    }

    setAdsetTargetStatDataForTable(tempTargetStat);
    setCopyOfAdsetTargetStatDataForTable(tempTargetStat);
    setUpdateAdsetStatusOnToggle(tempTargetStat);
  };

  // const onInputRangeChangeHandler = (passedData) => {
  //   let tempCampaigns = JSON.stringify(passedData);

  //   tempCampaigns = JSON.parse(tempCampaigns);

  //   let filteredCampaigns = [];
  //   let filterWithDashAsZero = [];

  //   for (let i = 0; i < appliedFilterData?.length; i++) {
  //     let temp = JSON.parse(JSON.stringify(appliedFilterData[i]));
  //     temp.minRange = isNaN(temp.minRange) ? 0 : Number(temp.minRange);
  //     temp.maxRange =
  //       temp.maxRange == "" || isNaN(temp.maxRange)
  //         ? Infinity
  //         : Number(temp.maxRange);

  //     if (
  //       (temp.minRange != 0 && temp.maxRange != Infinity) ||
  //       (temp.minRange == 0 && temp.maxRange != Infinity)
  //     ) {
  //       filterWithDashAsZero.push(temp);
  //     } else if (
  //       (temp.minRange != 0 && temp.maxRange == Infinity) ||
  //       (temp.minRange != 0 && temp.maxRange == "")
  //     ) {
  //       filterWithDashAsZero.push(temp);
  //     }
  //   }

  //   for (let r = 0; r < tempCampaigns.length; r++) {
  //     let shouldPass = true;

  //     for (let i = 0; i < filterWithDashAsZero.length; i++) {
  //       let currentFilter = filterWithDashAsZero[i];

  //       if (
  //         (currentFilter.minRange != 0 && currentFilter.maxRange != Infinity) ||
  //         (currentFilter.minRange == 0 && currentFilter.maxRange != Infinity) ||
  //         (currentFilter.minRange != 0 && currentFilter.maxRange == Infinity)
  //       ) {
  //         let currentCampaign = tempCampaigns[r];
  //         if (
  //           currentCampaign[currentFilter.id] != undefined &&
  //           currentCampaign[currentFilter.id] !== null
  //         ) {
  //           if (
  //             currentCampaign[currentFilter.id] < currentFilter.minRange ||
  //             (currentFilter.maxRange !== Infinity &&
  //               currentCampaign[currentFilter.id] > currentFilter.maxRange)
  //           ) {
  //             shouldPass = false;
  //           }
  //         } else {
  //           shouldPass = false;
  //         }
  //       } else {
  //         shouldPass = false;
  //       }
  //     }
  //     if (shouldPass) {
  //       filteredCampaigns.push(tempCampaigns[r]);
  //     }
  //     console.log(filteredCampaigns, "Check");
  //     // setAdsetTargetStatDataForTable(filteredCampaigns);
  //     // This is the data  which we will show after the campaigns will get filtered
  //     // setFilteredAdsetData(filteredCampaigns);
  //     // setCopyOfAdsetTargetStatDataForTable(filteredCampaigns);
  //   }
  //   return filteredCampaigns;
  // };

  const dashIconInBulKOperationHandler = () => {
    uncheckedAllselectedTarget();
    dispatch(campaignListActions.actionEditCollectiveBidForMasterTargeting({}));
  };

  const onSearch = (searchText, searchData) => {
    const filteredData = searchData.filter((data) => {
      if (searchText === "") return data;
      if (data?.amz_keyword_targeting) {
        if (targetingNameLinkFromMasterTargeting) {
          return (
            data.amz_keyword_targeting[0]?.text.toLowerCase() === searchText.toLowerCase()
          );
        } else {
          return data.amz_keyword_targeting[0]?.text
            .toLowerCase()
            .includes(searchText.toLowerCase());
        }
      }
      if (data?.amz_product_targeting) {
        if (targetingNameLinkFromMasterTargeting) {
          return data?.target.replace(/\\/g, "").toLowerCase() === searchText.toLowerCase();
        } else {
          return data?.target
            .replace(/\\/g, "")
            .toLowerCase()
            .includes(searchText.toLowerCase());
        }
      }
    });

    return filteredData;
  };

  const onSearchHandler = (searchValue) => {
    if (targetingName) {
      setTargetingNameLinkFromMasterTargeting(null);
    }
    setText(searchValue);
  };

  const cancelIconInSearchInput = () => {
    onSearchHandler("");
    // document.getElementById("search-input-cl").value = ""
  };

  let editCampaigntableBody = (
    <div className="edit-ad-table-container">
      {adsetTargetStatDataForTable.length ? (
        <table>
          <thead>
            <tr>
              {dataHeads.map((th, mainIndex) => {
                return (
                  <th
                    className={
                      th.isHeadRightAlign && (mainIndex === currentIndex)
                        ? "et-end-align  et-table-heads selected"
                        : th.isHeadRightAlign
                        ? "et-end-align  et-table-heads"
                        :  isSortBoxVisible && (mainIndex === currentIndex) ? "et-text-left-align et-table-heads selected" : "et-text-left-align et-table-heads"
                    }
                  >
                    {th.isHeadWithIcon === "sort" ? (
                      <div
                        className={
                          !th.isAbbrivation
                            ? "d-flex flex-row align-item-center justify-content-end captital-first-letter"
                            : "d-flex flex-row align-item-center justify-content-end"
                        }
                      >
                        <span>
                          {!th.isAbbrivation
                            ? th.fieldName.toLowerCase()
                            : th.fieldName}
                        </span>
                        <span
                          className="spends-icon"
                          onClick={() =>
                            onSpendFilterClickHandler(th.fieldName, mainIndex)
                          }
                        >
                          <img
                            src={
                              isSortBoxVisible && mainIndex === currentIndex
                                ? ascendingIconSvg
                                : descendingIconSvg
                            }
                            className={
                              mainIndex === currentIndex
                                ? "spends-icon-activated"
                                : ""
                            }
                          ></img>
                        </span>
                        <div
                          className={
                            isSortBoxVisible ? "sortBox-container" : "hide"
                          }
                        ></div>
                      </div>
                    ) : th.fieldName === "TARGETING" ? (<div>
                      <CheckboxRb
                          onChange={(e) => {
                            e.stopPropagation()
                            onAllSelectHandler(e.target.checked)
                          }}
                          // checked={true}
                          label={"Targeting"}
                        ></CheckboxRb>
                    </div>) :
                      (<div
                        className={
                          th.fieldName.toLowerCase() === "suggested bid" ||
                          th.fieldName.toLowerCase() === "match type"
                            ? "captital-first-letter heads-with-two-words"
                            : "captital-first-letter"
                        }
                      >
                        {th.fieldName.toLowerCase()}
                      </div>)
                    }
                  </th>
                );
              })}
            </tr>
          </thead>
          {adsetTargetStatDataForTable.map((data, index) => {
            let targettingType = data.amz_keyword_targeting
              ? data.amz_keyword_targeting
              : data.amz_product_targeting
              ? data.amz_product_targeting
              : data.amz_audience_targeting
              ? data.amz_audience_targeting
              : "";
            return (
              <tbody>
                {
                  (data?.isSummaryRow  && index === 0) ? (
                    <>
                      <tr >
                        <td className="edit-first-column">-</td>
                        <td className="edit-second-column fw-bold">Overall Summary</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td> 
                        {dataHeads.map((currentDataHead) => {
                    if (!currentDataHead.isDefaultHead) {
                      return (
                        <td
                          className={
                            currentDataHead.isHeadRightAlign
                              ? "et-end-align  et-table-heads fw-bold"
                              : "et-text-left-align et-table-heads fw-bold"
                          }
                        >
                          {data[currentDataHead.id] ||
                          data[currentDataHead.id] === 0
                            ? currentDataHead.id === "spends" ||
                              currentDataHead.id === "sale_value"
                              ? `${currencySymbol}${Math.trunc(data[currentDataHead.id])}`
                              : roundFigureNum(data[currentDataHead.id])
                            : "-"}
                        </td>
                      );
                    }
                  })}
                      </tr>
                    </>
                  ) : (
                    <>
                       <tr id={data.target_id} onClick={(e) => {
                        e.stopPropagation()
                        handleHighlightList(index)
                        }} className={data?.isHighlighted ? "cursor-pointer highlight" : "cursor-pointer"} key={data.target_id}>
                  <td className="edit-first-column">
                    {
                      <SwitchBtn
                        checked={
                          targettingType.length &&
                          targettingType[0].status === ("ENABLED" || "enabled")
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          onMainSwitchBtnToggleHandler(
                            data.target_id,
                            e.target.checked,
                            targettingType[0].status
                          )
                        }
                        disabled={data?.target_id === null || isNaN(data?.target_id)}
                      ></SwitchBtn>
                    }
                  </td>

                  <td className="edit-second-column">
                    {data.target ? (
                      <div className="d-flex justify-content-start align-items-center ">
                        <CheckboxRb
                          onChange={(e) => {
                            e.stopPropagation()
                            checkboxHandler(e, data.target_id)
                          }}
                          checked={data.isTargetChecked}
                          label={data.target}
                          disabled={data?.target_id === null}
                        ></CheckboxRb>
                        {
                          data?.target_id === null ?  <div className="chip-container-targeting">
                           <InfoChip text={"Not Synced"}/> 
                          </div> : null
                        }
                       
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>{data.match_type ? data.match_type : "-"}</td>

                  <td>
                    {loaders.suggestedBidLoader ? (
                      <Spinner size={"sm"} shape={"grow"} />
                    ) : data?.suggestedBid ? (
                      data?.suggestedBid
                    ) : (
                      "-"
                    )}
                  </td>

                  <td>
                    {targettingType.length ? (
                      targettingType?.map((tarData) => {
                        // return tarData.bid ? tarData.bid : "-"
                        return (
                          <div className="d-flex justify-content-evenly align-items-center current-bid-td-container">
                            <input
                              type="number"
                              max="999"
                              className={
                                data.iseditBudgetValueDisabled
                                  ? "bid-value"
                                  : "budget-value-edit"
                              }
                              value={tarData.bid}
                              disabled={data.iseditBudgetValueDisabled || data?.target_id === null}
                              onChange={(e) => {
                                e.stopPropagation()
                                setInputValue(
                                  data.target_id,
                                  e.target.value,
                                  "editBid"
                                );
                              }}
                            ></input>
                          {  data?.target_id ? <span
                              className="edit-budget-icon-container"
                              onClick={(e) =>
                                {
                                  e.stopPropagation()
                                  campaignEditClickHandler(data.target_id)
                                }
                              }
                            >
                              <img
                                src={
                                  data.iseditBudgetValueDisabled
                                    ? editIcon
                                    : checkCircle
                                }
                                
                                alt={"EditCheck"}
                              ></img>
                            </span>:<span
                              className="edit-budget-icon-container"
                            >
                              <img
                                src={
                                  data.iseditBudgetValueDisabled
                                    ? editIcon
                                    : checkCircle
                                }
                                
                                alt={"EditCheck"}
                              ></img>
                            </span>
                        }
                          </div>
                        );
                      })
                    ) : (
                      <div className="d-flex justify-content-evenly align-items-center current-bid-td-container">
                        <input
                          type="number"
                          max="999"
                          className="bid-value "
                          defaultValue="-"
                        ></input>
                        <span
                          className="edit-budget-icon-container"
                          onClick={(e) =>
                            {
                              e.stopPropagation()
                              campaignEditClickHandler(
                                data.target_id,
                                "EditCampaignNamePencil"
                              )
                            }
                            
                          }
                        >
                          <img src={editIcon}></img>
                        </span>
                      </div>
                    )}
                  </td>

                  {dataHeads.map((currentDataHead) => {
                    if (!currentDataHead.isDefaultHead) {
                      return (
                        <td
                          className={
                            currentDataHead.isHeadRightAlign
                              ? "et-end-align  et-table-heads"
                              : "et-text-left-align et-table-heads"
                          }
                        >
                          {data[currentDataHead.id] ||
                          data[currentDataHead.id] == 0
                            ? currentDataHead.id === "spends" ||
                              currentDataHead.id === "sale_value"
                              ? `${currencySymbol}${(Math.trunc(data[currentDataHead.id]))}`
                              : roundFigureNum(data[currentDataHead.id])
                            : "-"}
                        </td>
                      );
                    }
                  })}
                </tr>
                    </>
                  )
                }
               
              </tbody>
            );
          })}
        </table>
      ) : (
       !isLoaderVisible && !adsetTargetStatDataForTable?.length && <p>NO DATA FOUND</p>
      )}{" "}
    </div>
  );

  return (
    <div className="edit-ads-targetting-card-container ">
      <Loader visible={isLoaderVisible}></Loader>
      <div>
        {
          <FilterLogicModal
            filterFor="edit-targetting"
            show={isFilterVisible}
          ></FilterLogicModal>
        }
      </div>
      <div>
        {
          <MasterTagetinngActionAlertMModal
            // onYesClick={actioAlertYesClickhandler}
            alertText={showAlertMsgOf}
            onYesClick={onYesClickHandler}
            onNoClick={NoClickHandler}
          ></MasterTagetinngActionAlertMModal>
        }
      </div>
      <div>
        {modalToShow === "addTargeting"
          ? isVisible && (
              <AddTargetingModal
                setIsVisible={setIsVisible}
                isVisible={modalToShow === "addTargeting" ? isVisible : null}
                modalBody={modalBody}
                currentCampaign={currentCampaign}
                adset={adsetData}
              ></AddTargetingModal>
            )
          : //  modalToShow === "editBid" ? (
            //   // <EditBidModal
            //   //   isVisible={modalToShow === "editBid" ? isVisible : null}
            //   // ></EditBidModal>

            // ) :
            null}
      </div>
      <div>
        <EditBidModalForTargeting
          show={bulkEditBidModalVisibilty}
          setBidAs={true}
        ></EditBidModalForTargeting>
      </div>

      {/* <ModalRb ModalMainBody={Modal} customiseMainModalClass="main-modal-for-bid-config"></ModalRb> */}
      <div className="edit-targetting-card-header">
        <div className="d-flex flex-row align-items-center justify-content-start flex-grow-1">
          <SectionTittle sectionTitleHeading="Edit Targeting"></SectionTittle>
          <div className="d-flex align-items-center justify-content-center px-3 ">
            <div className="filter-icon-container-main">
              <div
                className={
                  minMaxFilterActivated ? "activated-min-max-filter" : "hide"
                }
              ></div>
             
              <img
                src={settingOptionIcon}
                onClick={onFilterIconhandler}
                className="cursor-pointer-global  filter-icon-img-mt"
                alt="settingsFilter"
              ></img>
            </div>
          </div>
          {/* <div className={showfilterOptions ? "et-filter-options-container" : "hide"}> */}
          <div
            className={
              checkedAdGroupData.length ? "et-filter-options-container-formasterTargeting filter-box-position" : "hide"
            }
          >
            <button className="selected-row-count mx-2">
            <img
              className="dash-icon"
              src={dashIcon}
              alt={"cancel"}
              onClick={dashIconInBulKOperationHandler}
            ></img>
            <span className="selected-text-with-count">
              {checkedAdGroupData && `${checkedAdGroupData?.length}  Selected`}
              {/* <div>{checkedAdGroupData.map((ad) => {})}</div> */}
            </span>
          </button>
          <button
            className="alignn-with-sug-bid-box-selective "
            onClick={editBidHandler}
            data-toggle="modal"
              data-target="#exampleModalCenter"
          >
          Edit Bid
          </button>
          <button
          onClick={() => mainBulkPauseHandler("Enable")}
            className="bid-manipulate-box-selective border-radius"
          >
            <span> Bulk Enable</span>
          </button>
          <button
          onClick={() => mainBulkPauseHandler("Pause")}
            className="bid-manipulate-box-selective border-radius"
          >
            <span> Bulk Pause</span>
          </button>
          </div>
        </div>
        <div className="serch-and-button-container">
          <div>
            <SearchBarForCampaignListOnly
              onChange={(e) => onSearchHandler(e.target.value)}
              defaultValue={targetingNameLinkFromMasterTargeting}
              placeholder="Search For Targeting"
              onClickCancel={cancelIconInSearchInput}
              dynamicWidth="search-input-width-for-edit-campaignlist-section"
              id="edit-campaign-serach-input"
            ></SearchBarForCampaignListOnly>
          </div>
          <div className="add-keyword-btn">
            {btnToShowAsperTargetingType === "amz_keyword_targeting" ? (
              <button
                className="primary-btn btn btn-primary"
                onClick={() => {
                  addKeywordHandler("keyword");
                  setModalBody("keyword");
                }}
              >
                Add Keyword
              </button>
            ) : btnToShowAsperTargetingType === "amz_product_targeting" ? (
              <button
                className="primary-btn btn btn-primary"
                onClick={() => {
                  addKeywordHandler("product");
                  setModalBody("product");
                }}
              >
                Add Product
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className="edit-targeting-campaign-count-btn-container">
        <Stack direction="horizontal" gap={4}>
          <Stack className="d-flex flex-row">
            <div>
              <SecondaryBtnRb
                secondaryBtnText="All"
                className={
                  selectedBtn === "all-btn"
                    ? "primary-active-btn ennable-paused-btns-height"
                    : "primary-inactive-btn ennable-paused-btns-height"
                }
                id="all-btn"
                onClick={(e, type, value) =>
                  onButtonClickHandler(e, "status", "All")
                }
              ></SecondaryBtnRb>
            </div>
            <div>
              <SecondaryBtnRb
                secondaryBtnText={`Enabled (${
                  totalEnabledCampaign.length ? totalEnabledCampaign.length : 0
                })`}
                className={
                  selectedBtn === "enabled-btn"
                    ? "primary-active-btn  ennable-paused-btns-height"
                    : "primary-inactive-btn  ennable-paused-btns-height"
                }
                id="enabled-btn"
                onClick={(e, type, value) =>
                  onButtonClickHandler(e, "status", "ENABLED")
                }
              ></SecondaryBtnRb>
            </div>
            <div>
              <SecondaryBtnRb
                secondaryBtnText={`Paused (${
                  totalPausedCampaign.length ? totalPausedCampaign.length : 0
                })`}
                className={
                  selectedBtn === "PAUSED"
                    ? "primary-active-btn ennable-paused-btns-height"
                    : "primary-inactive-btn ennable-paused-btns-height"
                }
                id="PAUSED"
                value="PAUSED"
                onClick={(e, type, value) =>
                  onButtonClickHandler(e, "status", "PAUSED")
                }
              ></SecondaryBtnRb>
            </div>
          </Stack>
        </Stack>
      </div>
      <div className="edit-targetting-table-container">
        <TableRb table={editCampaigntableBody}></TableRb>
      </div>
    </div>
  );
};

export default EditCampaign;
