import React, { useState } from "react";
import TableRb from "../../Tables/tableRb";
import { useSelector } from "react-redux";
import { Trash } from "react-bootstrap-icons";
import CustomDropDown from "../../DropDown/CustomDropDown";
import addIcon from "../../../assets/svgs/plus_box.svg";
import MinusIcon from "../../../assets/svgs/minus_box.svg";
import deleteIcon from "../../../assets/icons/delete.png";
import RadioButtonRb from "../../Buttons/radioButtonRb/radioButtonRb";
import searchIcon from "../../../assets/icons/search_icon.svg";
import { useGlobalContext } from "../../../context/global";
import { useEffect } from "react";
import axios from "axios";
import AsinsTable from "./SP_Product_Targeting/AsinsTable";
import { generateFiltering } from "../../../helper/CampaignList/masterAds/generateFiltering";
import { encodeData } from "../../../helper/common/encodeData";
import {
  TOAST_DANGER,
  TOAST_POSITION_TOP_RIGHT,
} from "../../../constant/HardTypes";
import { masterApi } from "../../../apis/masterApi";
import dropdownIcon from '../../../assets/icons/downArrowForSplitButton.svg'
import useUserDetails from '../../../helper/userData/useUserDetails';
import { PageVersionsAccessSetting } from "../../../constant/settings/userRouteAccess";
import { useMemo } from "react";
import {toast} from 'react-toastify';

const AdDetails = () => {
  // State

  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const [dynamicHeaders, setDynamicHeaders] = useState(["ASINS", "Action"]);
  const [isLoading, setIsLoading] = useState(false);
  const [changeText, setChangeText] = useState("");
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [page, setPage] = useState("");
  const [smartProductSetData,setSmartProductSetData] = useState([])
  const smartProductSetsForDropdown = useMemo(() => {
   return smartProductSetData?.map((data) => {
      const productsCount = data?.products?.length;
      return `${data?.name} - Products Count (${productsCount})`
    })
  },[smartProductSetData]) 
  const [selectedDropDownFilters,setSelectedDropDownFilters] = useState("Choose Set");
  
  const PRODUCT_SET_RULES = {
    RULE_1: "top_selling_25_percent",
    RULE_2: "instock",
    RULE_3: "discounted",
  };
  const { RULE_1, RULE_2, RULE_3 } = PRODUCT_SET_RULES;
  const {
    adSetData,
    setAdSetData,
    products,
    setProducts,
    selectedCategory,
    selectedProducts,
    setSelectedProducts,
    clientId,
    adsetAsins,
    setAdsetAsins,
    manuallyAddedAsins,
    setManuallyAddedAsins,
    showAlert,
    setSmartAdsProductsData,
    productSetData,
    createCampaignErrors,
    dispatchErrors
  } = useGlobalContext();
  const [userDetails] = useUserDetails();
  const {smart_ads} = PageVersionsAccessSetting?.beta 
  const {
    RULE_1: TOP_SELLING,
    RULE_2: INSTOCK,
    RULE_3: DISCOUNTED,
  } = productSetData;

  const PreConfig_Data = [`TOP SELLING - Products Count(${TOP_SELLING})` , `INSTOCK - Products Count(${INSTOCK})` , `DISCOUNTED - Products Count(${DISCOUNTED})` , ]


  const dataForDropDown = [{subheading:"Pre Configured Templates",subHeadData:[...PreConfig_Data]},{subheading:"Your Templates",subHeadData:[...smartProductSetsForDropdown]}]

  const queryParamsParser = (data) => {
    let queries = "";
    for (let key in data) {
      let value = data[key];
      queries = queries + `${key}=${value}&`;
    }
    return queries;
  };


  const filterCampaigns = (e,data,subhead) => {
    setSelectedDropDownFilters(e?.target?.value)
    if(subhead.includes("Pre")) {
    if (e.target.value !== undefined) {
        const value = e.target.value.includes("TOP") ? "TOP_SELLING" : e.target.value.includes("DISCOUNTED") ? "DISCOUNTED" : "INSTOCK";
        setAdSetData((current) => {
          const copy = { ...current };
          copy["product_set_type"] = value;
          copy["product_set"] = selectedCategory;
          return copy;
        });
      }
    } else {
      if(e.target.value !== undefined) {
        const id = smartProductSetData?.find((data) => {
        const productsCount = data?.products?.length;
        const stringToSearchFor =  `${data?.name} - Products Count (${productsCount})`
          return stringToSearchFor === e.target.value
      })
        setAdSetData((prev) => {
          const copy = {...prev};
          if("product_set_type" in copy) {
            delete copy["product_set_type"]
          }
          copy["product_set_id"] = id?.id
          return copy
        })
      }
    }
  }

  function removeQuotesFromEncodedURL(encodedStr) {
    if (typeof encodedStr === "string" && encodedStr.length >= 6 && encodedStr.startsWith('%22') && encodedStr.endsWith('%22')) {
      return `${encodedStr.slice(3, -3)}`;
    }
    return encodedStr;
  }
  

  const fetchDynamicProducts = async (rule) => {
    let encodedData = encodeData(selectedCategory?.join(','));
    let decodedData = removeQuotesFromEncodedURL(encodedData)
    try {
      const dataForAPICall = {
        source: "amazon",
        rule: rule,
        categories: decodedData,
        order_by: "revenue",
        limit: 50000,
      };
      const params = queryParamsParser(dataForAPICall);
      const url = `${process.env.REACT_APP_CATALOG_API}/client/${clientId}:fetchProductSetProducts?${params}`;
      const response = await fetch(url);
      const result = await response.json();
      if (result?.data) {
        const { data } = result;
        return data?.length;
      } else {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: "Error while Fetching Smart Products Sets",
        // });
        toast.error('Error While Fetching Smart Products Sets')
        return 0;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSmartProductSets = async () => {
    try {
      let encData = encodeData(selectedCategory?.toString())
      let dcData = removeQuotesFromEncodedURL(encData)
      const data = {
        categories:""
      }
      const result = await masterApi(data,"getProductSet",false);
      if(result?.statusCode!==200) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: "Error while Fetching Smart Product Set Details",
        // });
        toast.error('Error while Fetching Smart Product Set Details')
      }
      if(result?.data) {
        setSmartProductSetData([...result?.data])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllDynamicProductsData = async () => {
    try {
      setIsLoading(true);
      const result1 = await fetchDynamicProducts(RULE_1);
      const result2 = await fetchDynamicProducts(RULE_2);
      const result3 = await fetchDynamicProducts(RULE_3);
      const dataToBeSet = {
        RULE_1: result1,
        RULE_2: result2,
        RULE_3: result3,
      };
      setSmartAdsProductsData({ ...dataToBeSet });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const fetchProducts = async () => {
    try {
      const generatedFilter = generateFiltering({ category: selectedCategory });
      const filtering = encodeData(generatedFilter);
      let request = await axios.get(
        `${process.env.REACT_APP_CATALOG_API}/client/${clientId}/products?source=amazon&limit=500&filtering=${filtering}&fields=name,sale_price,asin,image_url,product_id`
      );
      if (request?.data?.data) {
        setProducts(request?.data?.data);
      } else {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: "Error while Fetching Products",
        // });
        toast.error('Some unknown error occured while fetching Products')
      }
    } catch (error) {
      console.log(error);
      // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
      //   backgroundColor: TOAST_DANGER,
      //   title: "Error",
      //   description: `Some Unknown Error Occured ${error?.message}`,
      // });
      toast.error(`Some Unknown Error Occured ${error?.message}`)
    }
  };

  // Effects
  useEffect(() => {
    if(selectedCategory.length) {
      fetchProducts();
    }
    setSelectedProducts([]);
  }, [selectedCategory]);

  useEffect(() => {
    if (page === "first") {
      getAllDynamicProductsData();
      fetchSmartProductSets()
    }
  }, [selectedCategory, page]);

  // Refs

  // Methods

  const removeAll = () => {
    setManuallyAddedAsins([]);
    setAdSetData({ ...adSetData, products: [] });
  };

  const handleAsinDelete = (ProvidedWord) => {
    setManuallyAddedAsins((current) => {
      return current.filter((word) => {
        return ProvidedWord !== word;
      });
    });
    setAdSetData((current) => {
      const copy = { ...current };

      let UpdatedAsins = copy["products"].filter((word) => {
        return ProvidedWord !== word;
      });

      copy["products"] = UpdatedAsins;

      return copy;
    });
  };

  const tableBody1 = (
    <>
      <div className="generic-main-table-body-container">
        <table className="addetails-asins-table">
          <thead className="main-table-head">
            <tr className="table-heading-style top-2">
              <td colspan={3} className="table-upper">
                <div className="d-flex justify-content-between">
                <div className="text-dark p-3">
                  {manuallyAddedAsins && manuallyAddedAsins.length} Added
                </div>
                <div className="">
                  <button
                    type="button"
                    onClick={removeAll}
                    className={
                      manuallyAddedAsins && manuallyAddedAsins.length !== 0
                        ? "remove-all-btn-dark"
                        : "remove-all-btn"
                    }
                  >
                    Remove All
                  </button>
                </div>
                </div>
              </td>
            </tr>
            <tr style={{ height: "44px" }}>
              {dynamicHeaders.map((th, index) => {
                if (index === dynamicHeaders.length - 1) {
                  return (
                    <th
                      className="border border-right-white"
                      onClick={() => {
                        // setIsVisible(true)
                      }}
                    >
                      {th}
                    </th>
                  );
                }
                return (
                  <th
                    className="border border-right-white"
                    style={{ textAlign: "left", paddingLeft: "30px" }}
                  >
                    {th}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="table-body main-table-body">
            {manuallyAddedAsins &&
              manuallyAddedAsins.map((word, index) => {
                return (
                  <tr key={word.id} className="row-height">
                    <td
                      className="tabledata-td"
                      style={{ textAlign: "left", paddingLeft: "30px" }}
                    >
                      ID:- {word.id}
                    </td>
                    <td className="tabledata-td" style={{ cursor: "pointer" }}>
                      {/* <img
                        src={deleteIcon}
                        alt="deleteIcon"
                        onClick={() => handleAsinDelete(word)}
                      /> */}
                       <span
                            className="text-left cursor-pointer-global selected-icon-hover" 
                            // onClick={() => onDelete(product)}
                          >
                            <Trash
                              className="text-danger remove-item-btn"
                              height="12px"
                              width="12px" 
                              onClick={() =>
                               handleAsinDelete(word)
                              }

                            />
                          </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );

  const handleChange = (validation_type,name, value, productsKey, productsValue) => {
    // This method will change the state based on the below mentioned conditions:-

    // 1) If Campaign Smart:- then remove products array and add product set type
    // 2) if campaign custom:- then add products array and remove products set type
    if (value !== "amazon_smart_creative") {
      setSelectedDropDownFilters("Choose Set")
      setAdSetData((current) => {
        const copy = { ...current };
        delete copy["product_set_type"];
        copy[name] = value;
        copy[productsKey] = productsValue;
        return copy;
      });
      // }
    } else {
      setAdSetData((current) => {
        const copy = { ...current };
        delete copy["products"];
        copy["type"] = "amazon_smart_creative";
        copy["product_set"] = selectedCategory;
        copy["product_set_type"] = null;
        return copy;
      });
    }
    if (selectedProducts?.length > 0) {
      setSelectedProducts([]);
    }
  };

  const handleAsins = (e) => {
    setChangeText(e.target.value.replace(/\s/g, ""));
    if (changeText !== "") {
      const splittedKeywords = e.target.value.split(",");
      const modiefiedAsins = splittedKeywords.map((asin) => {
        return { name: "", id: asin, image_url: "", status: "ENABLED" };
      });
      setManuallyAddedAsins(modiefiedAsins);
      setAdSetData({ ...adSetData, products: modiefiedAsins });
    }
  };

  const addAllProducts = () => {
    // check if some of the products in the selectedProducts are already added or not

    // Get every product Id:-
    // const everyProductsId = products.map((product) => product.product_id);
    const noDuplicatesProducts = products.filter((product) => {
      for (let i = 0; i < selectedProducts.length; i++) {
        if (
          product.product_id.toLowerCase() ===
          selectedProducts[i].id.toLowerCase()
        ) {
          return false;
        }
      }
      return true;
    });
    const modifiedProducts = noDuplicatesProducts.map((product) => {
      const { product_id, name, image_url, asin } = product;
      return { id: asin, name, image_url, status: "ENABLED" };
    });
    // Append these Products in the selected Products
    setSelectedProducts((current) => {
      if (current.length > 0) {
        return current.concat(modifiedProducts);
      } else {
        return modifiedProducts;
      }
    });
    setAdSetData({
      ...adSetData,
      products: [...selectedProducts, ...modifiedProducts],
    });
  };

  const removeAllProducts = () => {
    setAdSetData({ ...adSetData, ["products"]: [] });
    setSelectedProducts([]);
  };

  const handleAddProduct = (id) => {
    const findedProduct = products.filter((product) => {
      return product.asin === id;
    });
    const { product_id, name, image_url, asin } = findedProduct[0];
    const modifiedProduct = { id: asin, name, image_url, status: "ENABLED" };
    // Checking if the product is already added or not.
    if (selectedProducts.length !== 0) {
      const isProductAvailable = selectedProducts.filter((productMain) => {
        return productMain.id === id;
      });
      if (isProductAvailable.length === 0) {
        setSelectedProducts([...selectedProducts, modifiedProduct]);
        setAdsetAsins([...adsetAsins, asin]);
        setAdSetData({
          ...adSetData,
          products: [...selectedProducts, modifiedProduct],
        });
      } else {
        handleDeleteProduct(id)
      }
    } else {
      setSelectedProducts([...selectedProducts, modifiedProduct]);
      setAdsetAsins([...adsetAsins, asin]);
      setAdSetData({
        ...adSetData,
        products: [...selectedProducts, modifiedProduct],
      });
    }
  };

  const handleDeleteProduct = (id) => {
    // This function will remove the selected product and as well as ASins related to that
    const newSelectedProducts = selectedProducts.filter((product) => {
      return product.id !== id;
    });
    const findedProduct = products.filter((product) => {
      return product.asin === id;
    });
    const { asin } = findedProduct[0];
    const newAdSetAsins = adsetAsins.filter((currentAsins) => {
      return currentAsins !== asin;
    });
    setAdsetAsins(newAdSetAsins);
    setSelectedProducts(newSelectedProducts);
    setAdSetData({ ...adSetData, products: newSelectedProducts });
  };

  const setSelectedClass = (id) => {
    const isIdAvailable = selectedProducts.some(item => item.id === id);
    return isIdAvailable ? "selected-product" : "";
  }

  const setSelectedProductIcon = (id) => {
    const isIdAvailable = selectedProducts.some(item => item.id === id);
    return isIdAvailable ? MinusIcon : addIcon;
  }

  const searchProducts = (e) => {
    const searchedProducts = products.filter((product) => {
      if (e.target.value === "") {
        return product;
      } else {
        return product.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      }
    });
    setSearchedProducts(searchedProducts);
  };

  const radioChange = (e, value) => {
    const mainBtn = e.target.closest(".redioButton-box");
    const allBtn = mainBtn.querySelectorAll(".redioButton");
    allBtn.forEach((item) => {
      item.classList.remove("active");
    });
    const add = e.target.closest(".redioButton");
    add.classList.add("active");
    add.children[0].children[0].children[0].checked = true;
    dispatchErrors({action:"setError",fieldName:"ad_details",value:false})

    // setPage(e.target.closest(".redioButton").id);
    if (value !== undefined) {
      setAdSetData((current) => {
        const copy = { ...current };
        copy["product_set_type"] = value;
        copy["product_set"] = selectedCategory;
        return copy;
      });
    }
  };

  

  return (
    <div className="key-wrap" style={{ marginTop: "-30px" }}>
      {/* <div
      className="sponsored-product-keyword-targeting" id="sp-keyword"> */}
     
      <span className="common-head">Ad Details </span>
      {createCampaignErrors?.ad_details && <small className="text-danger">{"Please Select Type*"}</small>}
      <div className="key-wrap smart-logic">
        <div className="redioButton-box">
          <div className="row">
            <div className="col-lg-4">
              <div
                className="redioButton"
                id="first"
                onClick={(e) => {
                  radioChange(e);
                  setPage("first");
                  handleChange("amazon_smart_creative","type", "amazon_smart_creative");
                }}
              >
                <RadioButtonRb
                  name="select-Campaign-addetails"
                  className={"low-width"}
                  label={<Leavle heading={"Smart Product Selection"}/>}
                ></RadioButtonRb>
              </div>
            </div>

            <div className="col-lg-4">
              <div
                className="redioButton"
                id="second"
                onClick={(e) => {
                  radioChange(e);
                  setPage("second");
                  handleChange(
                    "amazon_custom_creative",
                    "type",
                    "amazon_custom_creative",
                    "products",
                    selectedProducts
                  );
                }}
              >
                <RadioButtonRb
                  name="select-Campaign-addetails"
                  className={"low-width"}
                  label={<Leavle heading="Manual Product Selection" />}
                ></RadioButtonRb>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="redioButton"
                id="third"
                onClick={(e) => {
                  radioChange(e);
                  setPage("third");
                  handleChange(
                    "amazon_custom_creative_asin",
                    "type",
                    "amazon_custom_creative",
                    "products",
                    manuallyAddedAsins
                  );
                }}
              >
                <RadioButtonRb
                  name="select-Campaign-addetails"
                  className={"low-width"}
                  label={<Leavle heading="Enter ASIN" />}
                ></RadioButtonRb>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {First Page} */}
      {page === "first" && (
        <>
        <row className="d-flex mt-3 flex-column gap-1">
        <div className="col-lg-4">
        <span className="common-head">Select Smart Products Set</span>
          <CustomDropDown
          title={"Smart Product Set"}
          autoWidth={true}
          noSubhead={true}
          data={dataForDropDown}
          filterCampaigns={filterCampaigns}
          targettingValue={selectedDropDownFilters}
          type={"product_set_id"}
          src={dropdownIcon}
          />
        </div>
        </row>
        </>
      )}

      {page === "third" && (
        <div className={page === "third" ? "d-block" : "d-none"}>
          <span className="common-head my-3">
            Enter ASIN's
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1178_14743"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="21"
                height="20"
              >
                <rect
                  x="0.585693"
                  y="0.0175781"
                  width="20"
                  height="19.9643"
                  fill="#D9D9D9"
                />
              </mask>
              <g mask="url(#mask0_1178_14743)">
                <path
                  d="M10.5856 17.9022C9.48839 17.9022 8.45728 17.6943 7.49228 17.2783C6.52672 16.8624 5.68978 16.3009 4.98145 15.5939C4.27311 14.8868 3.71061 14.0513 3.29395 13.0875C2.87728 12.1242 2.66895 11.095 2.66895 9.9997C2.66895 8.90443 2.87728 7.87489 3.29395 6.91106C3.71061 5.94778 4.27311 5.11261 4.98145 4.40554C5.68978 3.69847 6.52672 3.13697 7.49228 2.72105C8.45728 2.30513 9.48839 2.09717 10.5856 2.09717C11.6828 2.09717 12.7142 2.30513 13.6798 2.72105C14.6448 3.13697 15.4814 3.69847 16.1898 4.40554C16.8981 5.11261 17.4606 5.94778 17.8773 6.91106C18.2939 7.87489 18.5023 8.90443 18.5023 9.9997C18.5023 11.095 18.2939 12.1242 17.8773 13.0875C17.4606 14.0513 16.8981 14.8868 16.1898 15.5939C15.4814 16.3009 14.6448 16.8624 13.6798 17.2783C12.7142 17.6943 11.6828 17.9022 10.5856 17.9022ZM10.5856 16.6545C12.4328 16.6545 14.0059 16.0065 15.3048 14.7104C16.6031 13.4139 17.2523 11.8436 17.2523 9.9997C17.2523 8.15577 16.6031 6.58553 15.3048 5.28896C14.0059 3.99294 12.4328 3.34494 10.5856 3.34494C8.73839 3.34494 7.16561 3.99294 5.86728 5.28896C4.56839 6.58553 3.91895 8.15577 3.91895 9.9997C3.91895 11.8436 4.56839 13.4139 5.86728 14.7104C7.16561 16.0065 8.73839 16.6545 10.5856 16.6545Z"
                  fill="#4D4184"
                />
                <path
                  d="M10.559 13.9509C10.7396 13.9509 10.889 13.8921 11.0073 13.7745C11.1251 13.6564 11.184 13.5072 11.184 13.327V9.77083C11.184 9.60446 11.1251 9.46221 11.0073 9.34409C10.889 9.22652 10.7396 9.16774 10.559 9.16774C10.3784 9.16774 10.2293 9.22652 10.1115 9.34409C9.99317 9.46221 9.934 9.61139 9.934 9.79162V13.3478C9.934 13.5141 9.99317 13.6564 10.1115 13.7745C10.2293 13.8921 10.3784 13.9509 10.559 13.9509ZM10.559 7.7536C10.7534 7.7536 10.9132 7.68761 11.0382 7.55562C11.1632 7.42419 11.2257 7.26143 11.2257 7.06733C11.2257 6.8871 11.1632 6.73099 11.0382 6.599C10.9132 6.46757 10.7534 6.40186 10.559 6.40186C10.3646 6.40186 10.2048 6.46757 10.0798 6.599C9.95483 6.73099 9.89233 6.8871 9.89233 7.06733C9.89233 7.26143 9.95483 7.42419 10.0798 7.55562C10.2048 7.68761 10.3646 7.7536 10.559 7.7536Z"
                  fill="#4D4184"
                />
              </g>
            </svg>
          </span>
          <div className="row">
            <div className="col-lg-6 asin-div">
              <AsinsTable ManuallyAsins={"manuallyasins"} />
            </div>
            <div className="col-lg-6 asin-div">
              <TableRb table={tableBody1} className={" asin-table"}/>
            </div>
          </div>
        </div>
      )}
      {/* // Second Page */}

      {page === "second" && (
        <div className={page === "second" ? "d-block" : "d-none"}>
          <span className="common-head my-3" id="select-products">
            Select Products
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1178_14743"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="21"
                height="20"
              >
                <rect
                  x="0.585693"
                  y="0.0175781"
                  width="20"
                  height="19.9643"
                  fill="#D9D9D9"
                />
              </mask>
              <g mask="url(#mask0_1178_14743)">
                <path
                  d="M10.5856 17.9022C9.48839 17.9022 8.45728 17.6943 7.49228 17.2783C6.52672 16.8624 5.68978 16.3009 4.98145 15.5939C4.27311 14.8868 3.71061 14.0513 3.29395 13.0875C2.87728 12.1242 2.66895 11.095 2.66895 9.9997C2.66895 8.90443 2.87728 7.87489 3.29395 6.91106C3.71061 5.94778 4.27311 5.11261 4.98145 4.40554C5.68978 3.69847 6.52672 3.13697 7.49228 2.72105C8.45728 2.30513 9.48839 2.09717 10.5856 2.09717C11.6828 2.09717 12.7142 2.30513 13.6798 2.72105C14.6448 3.13697 15.4814 3.69847 16.1898 4.40554C16.8981 5.11261 17.4606 5.94778 17.8773 6.91106C18.2939 7.87489 18.5023 8.90443 18.5023 9.9997C18.5023 11.095 18.2939 12.1242 17.8773 13.0875C17.4606 14.0513 16.8981 14.8868 16.1898 15.5939C15.4814 16.3009 14.6448 16.8624 13.6798 17.2783C12.7142 17.6943 11.6828 17.9022 10.5856 17.9022ZM10.5856 16.6545C12.4328 16.6545 14.0059 16.0065 15.3048 14.7104C16.6031 13.4139 17.2523 11.8436 17.2523 9.9997C17.2523 8.15577 16.6031 6.58553 15.3048 5.28896C14.0059 3.99294 12.4328 3.34494 10.5856 3.34494C8.73839 3.34494 7.16561 3.99294 5.86728 5.28896C4.56839 6.58553 3.91895 8.15577 3.91895 9.9997C3.91895 11.8436 4.56839 13.4139 5.86728 14.7104C7.16561 16.0065 8.73839 16.6545 10.5856 16.6545Z"
                  fill="#4D4184"
                />
                <path
                  d="M10.559 13.9509C10.7396 13.9509 10.889 13.8921 11.0073 13.7745C11.1251 13.6564 11.184 13.5072 11.184 13.327V9.77083C11.184 9.60446 11.1251 9.46221 11.0073 9.34409C10.889 9.22652 10.7396 9.16774 10.559 9.16774C10.3784 9.16774 10.2293 9.22652 10.1115 9.34409C9.99317 9.46221 9.934 9.61139 9.934 9.79162V13.3478C9.934 13.5141 9.99317 13.6564 10.1115 13.7745C10.2293 13.8921 10.3784 13.9509 10.559 13.9509ZM10.559 7.7536C10.7534 7.7536 10.9132 7.68761 11.0382 7.55562C11.1632 7.42419 11.2257 7.26143 11.2257 7.06733C11.2257 6.8871 11.1632 6.73099 11.0382 6.599C10.9132 6.46757 10.7534 6.40186 10.559 6.40186C10.3646 6.40186 10.2048 6.46757 10.0798 6.599C9.95483 6.73099 9.89233 6.8871 9.89233 7.06733C9.89233 7.26143 9.95483 7.42419 10.0798 7.55562C10.2048 7.68761 10.3646 7.7536 10.559 7.7536Z"
                  fill="#4D4184"
                />
              </g>
            </svg>
          </span>

          <div id="displayproduct">
            <div className="displayproduct-div-1">
              <span className="displayproduct-div-1-span">Product Catalog</span>

              <div id="serach-dispaly-product">
                <div>
                  <div className="search-bar-container2">
                    <span className=" search-icon-container">
                      <img src={searchIcon} alt=""></img>
                      {/* <Search color="#4D4184" className="search-icon" /> */}
                    </span>
                    <span className="seacrh-input-container">
                      <input
                        type="text"
                        className="seacrh-input-field create-campaign-outline"
                        placeholder="Search Products"
                        onChange={searchProducts}
                        id="manual-search"
                      />
                    </span>
                    <div className="manual-product-add-btn">
                      <button
                        type="button"
                        className={
                          products?.length > 0
                            ? "add-all-btn-dark"
                            : "add-all-btn"
                        }
                        onClick={addAllProducts}
                      >
                        Add All
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* input select all*/}
              <div
                className={
                  !dashboardSideBarVisibiltyState
                    ? "dispaly-product-images"
                    : "dispaly-product-images-open"
                }
              >
                {searchedProducts.length !== 0
                  ? searchedProducts.map((item, index) => (
                    <div className={setSelectedClass(item.asin)}>
                      <Product
                        name={item.name}
                        asin={item.asin}
                        add="add"
                        handleAddProduct={handleAddProduct}
                        id={item.asin}
                        image_url={item.image_url}
                        iconUrl={setSelectedProductIcon(item.asin)}
                      />
                      </div>
                    ))
                  : products.map((item, index) => {
                      return (
                        <div className={setSelectedClass(item.asin)}>
                        <Product
                          name={item.name}
                          asin={item.asin}
                          add="add"
                          handleAddProduct={handleAddProduct}
                          id={item.asin}
                          image_url={item.image_url}
                          iconUrl={setSelectedProductIcon(item.asin)}
                        />
                        </div>
                      );
                    })}
              </div>
            </div>

            {/* second div of image select */}
            <div className="displayproduct-div-1">
              <span className="displayproduct-div-1-span">
                Selected Products
              </span>
              <div id="serach-dispaly-product">
                <div>
                  <div className="search-bar-container2">
                    <span className=" search-icon-container"></span>
                    <span className="remove-input-container">
                      {/* <span className='min-para'>Select Min Of 3 Products</span> */}
                    </span>
                    <div className="manual-product-remove-btn">
                      <button
                        type="button"
                        className={
                          selectedProducts?.length > 0
                            ? "remove-all-btn-dark"
                            : "remove-all-btn"
                        }
                        onClick={removeAllProducts}
                      >
                        Remove All
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* input select all*/}
              <div
                className={
                  !dashboardSideBarVisibiltyState
                    ? "dispaly-product-images"
                    : "dispaly-product-images-open"
                }
              >
                {selectedProducts.map((item, index) => {
                  return (
                      <Product
                      name={item.name}
                      asin={item.id}
                      handleDeleteProduct={handleDeleteProduct}
                      deleteProp={"deleteProp"}
                      id={item.id}
                      image_url={item.image_url}
                      iconUrl={deleteIcon}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Leavle = ({ heading, para }) => {
  return (
    <>
    {  heading ?<span className="leavle-hover leavle-heading">{heading}</span>:null}
 {   heading ?  <br />:null}
      {para && <span className="leavle-hover leavle-para">{para}</span>}
    </>
  );
};

const Product = ({
  image_url,
  name,
  id,
  asin,
  handleAddProduct,
  add,
  deleteProp,
  handleDeleteProduct,
  iconUrl
}) => {
  return (
    <>
      <div className="product">
        <span className="image-container">
          <img src={image_url} alt="" />
        </span>
        <div className="add-Icon selected-icon-hover">
          <img
            src={iconUrl}
            onClick={() =>
              add ? handleAddProduct(id) : handleDeleteProduct(id)
            }
            alt="images"
          />
        </div>

        <div className="product_name">{`${name},${asin}`}</div>
      </div>
    </>
  );
};

export default AdDetails;
