import React from "react";
import "./masterSearchterm.css";

// Redux // React
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useGlobalContext } from "../../../context/global";
import { useRef, useEffect,useMemo } from "react";
import { campaignListActions } from "../../../redux/slices/campaignList";

// Components
import Loader from "../../../components/loader";
import FilterLogicModal from "../../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import MasterTagetinngActionAlertMModal from "../../../components/modal/masterTargetinngActionnAlertMModal/masterTargetingActionnAlertModal";
import { Stack } from "react-bootstrap";
import SectionTitle from "../../../components/sectionTitle";
import SearchBarForCampaignListOnly from "../../../components/searchBarForCampaignListOnly";
import MasterTargetingSearchSelectModal from "../../../components/modal/masterTargetinngActionnAlertMModal/search&select/MasterTargetingSearchSelectModal";
import CalenderComponent from "../../../components/calender/Calender";
import ProductNameTd from "../../../components/Tables/TableComponents/productNameTd";
import Spinner from "../../../components/spinners/spinner";
import LazyLoadSpinner from "../../../components/spinners/LazyLoadSpinner";
import MultiSelectCustomDropDown from "../../../components/DropDown/MultiSelectCustomDropDown";
import CustomDropDown from "../../../components/DropDown/CustomDropDown";
import Breadcrumb from '../../../components/Breadcrumb/ReportsBreadCrumb';
import EditBadge from "../../../components/Badges/EditBadge/EditBadge";
import DynamicFilterModal from "../../../components/modal/DynamicFilterModal";


// Helpers
import { generateFiltering } from "../../../helper/CampaignList/masterAds/generateFiltering";
import { encodeData } from "../../../helper/common/encodeData";
import { masterApi } from "../../../apis/masterApi";
import {
  TOAST_POSITION_TOP_RIGHT,
  TOAST_DANGER,
  TOAST_SUCCESS,
} from "../../../constant/HardTypes";
import customDropDownWithCheckboxDefaultDataArray from "../../../constant/dropDownWIthCheckDefaultStateList";
import { searchTermRecommendationsDropDown } from "../../../constant/searchTermRecommendationsDropDown";
import { leakingConvertingHeads } from "../../../constant/MasterSearchTerms/LeakingConvertingHeads";
import getCustomDataHeads from "../../../helper/CampaignList/getCustomDataHeads";
import { defaultMetrices } from "../../../constant/marketingConstant";

// Custom Hooks
import useInitialLoad from "../../../hooks/useInitialLoad";

// Assets
import settingIcon from "../../../assets/icons/overviewSeiingIcon.svg";
import dashIcon from "../../../assets/icons/dash-icon.png";
import downArrow from "../../../assets/icons/downArrowForSplitButton.svg";
import selectSideIcon from "../../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../../assets/icons/calender-icon-for-campaignlist.svg";
import closeIcon from "../../../assets/icons/close_icon.png";
import FilterChips from "../../../components/FilterChips/FilterChips";
import TableRb from "../../../components/Tables/tableRb";
import descendingIconSvg from "../../../assets/svgs/acendingIconSvg.svg";
import ascendingIconSvg from "../../../assets/svgs/descendingIcon.svg";
import whiteFindIcon from "../../../assets/icons/white_magnnifineIcon.png";
import AddIcon from "../../../assets/svgs/plus_box.svg";
import MinusIcon from "../../../assets/svgs/minus_box.svg";
import dropdownIcon from '../../../assets/icons/downArrowForSplitButton.svg';
import {HiPencilAlt} from 'react-icons/hi';
import CheckboxRb from "../../../components/Buttons/checkbox/checkbox";
import CampaignListTab from "../../../components/Tabs/CampaignListTab";
import AddToNegativeTargetingModal from "../../../components/modal/campaignListModals/addToNegativeTargetingModal/AddToNegativeTargeting";
import axios from "axios";
import {toast} from 'react-toastify';
import dropDownIcon from '../../../assets/icons/downArrowForSplitButton.svg'
import ReportsNavigator from "../../../components/Reports/ReportsNavigator";


const MasterSearchTerm = () => {
  // Variables // Global Imports // Redux Imports
  const upperCaseValues = ["cpm", "cvr", "roas", "cpc", "ctr", "acos"];
  const metricesWithNoDecimal = ["spends", "sale_value", "aov"];
  const metricesWithTwoDecimal = ["cpm", "ctr", "cpc", "cvr", "roas", "acos"];
  const upperCaseAsinTargetins = ["Product Targeting","Automatic"]
  const limit = 50;
  const initialFiltersData = {
    product_name: "",
    campaign_type: "All",
    campaign_objective: "Both",
    category: "",
    portfolio_id: "",
    campaign_id: "",
    adgroup_id: "",
    status: "",
    asin: "",
    recommendation:""
  };
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const getUpdatedConfigHeadsForMasterSearchTermSection = useSelector(
    (state) =>
      state.campaignListData.getUpdatedConfigHeadsForMasterSearchTermSection
  );
  const dispatch = useDispatch();
  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );
  const appliedFilterData = useSelector(
    (state) => state.campaignListData.appliedFilterData
  );
  const { dateDifference, calendarDate, showAlert,applyCalendar } = useGlobalContext();
  const [initialLoad,setInitialLoad] = useInitialLoad()
  // States
  const [allLoaders, setAllLoaders] = useState({
    mainLoader: false,
    childRowLoader: false,
  });
  const [allFiltersVisible, setAllFilterVisible] = useState({
    mainFilterLogicModal: false,
    negativeTargetingModal:false,
    ConfirmAlertModal: false,
    searchSortModal: false,
  });
  const [filters, setFilters] = useState(initialFiltersData);
  const [
    multiSelectDropDownSelectedDataCount,
    setMultiSelectDropDownSelecteddDataCount,
  ] = useState({
    adyogi_targeting_type: 0,
    campaign_type: 0,
    campaign_objective: 0,
  });
  const [checkedDropDownFilterButtonData, setCheckedDropDownFilterButtonData] =
    useState([]);
  const [DropDownDataList, setDropDownDataList] = useState(
    customDropDownWithCheckboxDefaultDataArray
  );
  const [searchTermValues, setSearchTermValues] = useState("");
  const [statFilters, setStatFilters] = useState([]);
  const [campaignsIds, setCampaignsIds] = useState("");
  const [paginationAction, setPaginationAction] = useState({
    currentPage: 1,
    currentOffSet: 0,
  });
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");
  const [parentRowsData, setParentRowsData] = useState([]);
  const [cancelSpecifiNumberFilter, setCancelSpecifiNumberFilter] =
    useState(null);
  const [minMaxFilterActivated, setMinMaxFilterActivated] = useState(false);
  const [actionAlertModalText, setActionAlertModalText] = useState("");
  const [lazyLoading, setLazyLoading] = useState(false);
  const [campaignTypeFilter, setCampaignTypeFilter] = useState(false);
  const [filterForCampaignType, setFilterForCampaignType] = useState([]);
  const [filterForMatchType,setFilterForMatchType] = useState([]);
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const [dataHeads, setDataHeads] = useState([]);
  const [groupBy,setGroupBy] = useState("Global");
  const isGlobal = groupBy === "Global";
  const [sortBy, setSortBy] = useState({ sort_head: "spends", order: "desc" });
  const [columnName, setColumnName] = useState("");
  const [tableDataToShow, setTableDataToShow] = useState([1]);
  const [selectedAds,setSelectedAds] = useState([])
  const [recievedText,setRecievedText] = useState("")
  const [searchFiltersState, setSearchFiltersState] = useState({
    portfolios: [],
    campaigns: [],
    adGroups: [],
    categories: [],
  });
  const [leakingConvertingFilters,setLeakingConvertingFilters] = useState("");
  const [userCustomConfigData,setUserCustomConfigData] = useState({});
  const [removeFilters,setRemoveFilters] = useState(false)
  const convertingPreheads = userCustomConfigData?.value?.converting?.values ?? [];
  const leakingPreheads = userCustomConfigData?.value?.leaking?.values ?? []

  // Derived Variables From States
  const dataForChips = generateChipFilters(filters, statFilters);
  const isCampaignFilterActive = filters?.campaign_id?.length > 0;
  const isPortfolioFilterActive = filters?.portfolio_id?.length > 0;
  const adGroupFilterActive = filters?.adgroup_id?.length > 0;
  const categoryFilterActive = filters?.category?.length > 0;
  const campaignTypeFilterCount = filterForCampaignType[0]?.values?.length ?? 0;
  const matchTypeFilterCount = filterForMatchType[0]?.values?.length ?? 0 
  const isRecommendationFilterActive = filters?.recommendation?.length>0 ? true : false;
  const currentHeads = getActiveHeads(dataHeads,filters);
  const [refreshFilteringData,setRefreshFilteringData] = useState(false)
  // This value will be sent when user will open the Add To Negative Targeting Modal // Get the campaign Names and Then Remove Duplicates
  const defaultSelectedAds = selectedAds && selectedAds?.map((search_term) => search_term?.campaign_name)?.filter((value,index,arr) => index === arr?.indexOf(value))

  // Constants 
  const {leaking,converting} = leakingConvertingHeads
  const convertingColumns = getUpdatedColumns(converting,convertingPreheads)
  const leakingColumns = getUpdatedColumns(leaking,leakingPreheads)
  const isLeakingFilterPresent = leakingConvertingFilters==="Leaking";
  const isConvertingFilterPresent = leakingConvertingFilters==="Converting";


  const filterLogicModalEnabledFilters = useMemo(() => getPreEnabledFilters(),[leakingConvertingFilters]);
  // Check whether Applied pre-Enabled Filters are same or not on every render
  const isAllFiltersPresent = (filterLogicModalEnabledFilters).every((data) => {
    const matchingFilter = appliedFilterData.find((filter) =>
      filter.id === data.field_key &&
      data.minValue === filter.minRange &&
      data.maxValue === filter.maxRange
    );
  
    return !!matchingFilter;
  }) && dataForChips?.length === filterLogicModalEnabledFilters?.length

  // Get All Filters Id 
  const allFilterKeys = appliedFilterData?.map((data) => data?.id)
  
  const isCustomHeadsRequired = useMemo(() => {
    return tableDataToShow?.some((data) => !data?.isPlusIconDisabled)
  },[tableDataToShow,groupBy]) 

  const columnsToHide = ["match_type","text","targeting"]

  const customDataHeads = !isCustomHeadsRequired && isGlobal ? getCustomDataHeads(dataHeads,columnsToHide) : dataHeads

  // Refs
  const masterSearchTermCalendarRef = useRef(null);
  const tableRef = useRef(null);

  // Effects

  useEffect(() => {
    getParentsRowsData();
  }, [
    groupBy,
    statFilters,
    applyCalendar,
    filters,
    searchTermValues,
    sortBy,
    filterForCampaignType,
    filterForMatchType
  ]);

  useEffect(() => {
    getAllCampaigns();
  }, [filters]);

  

  // useEffect(() => {
  //   const returnedData = [...modifyData(parentRowsData)];
  //   setAllLoaders({ ...allLoaders, mainLoader: false });
  //   setTableDataToShow(returnedData);
  // }, [parentRowsData]);

  useEffect(() => {
    setAllFilterVisible({
      ...allFiltersVisible,
      mainFilterLogicModal: filterModalInCampaignListVisibilty,
    });
  }, [filterModalInCampaignListVisibilty]);

// This Effect if Anything changes in the filter and make the button disable
  // useEffect(() => {
  //   let id;
  
  //   if (!isAllFiltersPresent && leakingConvertingFilters !== "") {
  //     id = setTimeout(() => {
  //       setLeakingConvertingFilters("");
  //     }, 500);
  //   }
  
  //   return () => {
  //     if (id) {
  //       clearTimeout(id);
  //     }
  //   };
  // }, [isAllFiltersPresent]);
  


  useEffect(() => {
    if (appliedFilterData?.length) {
      const statFiltering = transformData(appliedFilterData,"minRange","maxRange","id");
      if (
        statFiltering.length > 0 ||
        (appliedFilterData?.length === 4 && statFilters.length > 0) ||
        statFilters.length > 0
      ) {
        setStatFilters(statFiltering);
      }
    } else {
       //setStatFilters([]); // Removing this because it's causing component to render twice and calling API.
      setMinMaxFilterActivated(false);
    }
    // Everytime filter data get's changed make cancelSpecific Filter state empty;
    setCancelSpecifiNumberFilter("");
    
  }, [appliedFilterData]);

  useEffect(() => {
    const selectedAds = getUpdatedCount(tableDataToShow)
    setSelectedAds(selectedAds)
  },[tableDataToShow])

  useEffect(() => {
    setDataHeads(getUpdatedConfigHeadsForMasterSearchTermSection);
  }, [getUpdatedConfigHeadsForMasterSearchTermSection]);

  useEffect(() => {
    getUserCustomConfigData()
  },[refreshFilteringData])



  // Methods

  async function getUserCustomConfigData () {
    try {
      const data = await masterApi({key:"amz_search_term_config"},"updateClientConfig",false);
      if(data?.statusCode===200) {
        setUserCustomConfigData({...data?.data})
      } else {
        const payloadData = {
          key:"amz_search_term_config",
          value:{
            converting:{
              values:[]
            },
            leaking:{
              values:[]
            }
          }
        }
        // If No Data Received Then Update custom config with default config for every user
        const data = await masterApi(payloadData,"updateClientConfig",false,"post");
        if(data?.data?.id) {
          payloadData["id"] = data?.data?.id
          setUserCustomConfigData({...payloadData})
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  // Get Final Leaking & Converting Columns

  function getUpdatedColumns(originalColumns, savedColumns) {
    let newColumns = [];

    // Create a map to store saved columns by field_key for quick lookup
    const savedColumnsMap = {};
    for (const savedColumn of savedColumns) {
        savedColumnsMap[savedColumn.field_key] = savedColumn;
    }

    for (const originalColumn of originalColumns) {
        const savedColumn = savedColumnsMap[originalColumn.field_key];
        if (savedColumn) {
            // If a matching saved column exists, use it
            newColumns.push(savedColumn);
        } else {
            // If no matching saved column, use the original column
            newColumns.push(originalColumn);
        }
    }

    return newColumns;
}


  // Get Preenabled Filters 
 function getPreEnabledFilters () {
    let data = []
    if(isLeakingFilterPresent) {
      data = [...leakingPreheads]
    }
    if(isConvertingFilterPresent) {
      data = [...data,...convertingPreheads]
    }
    return data
  }

  const formatDateFn = (date) => {
    const selectedDate = new Date(date);
    const formattedDate =
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear();
    return (
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear()
    );
  };

  // Get Heads

  function getActiveHeads (heads,filters) {
    let updated
    const headers = ["Targeting Type","Targeting","Match Type","Portfolio"]
    if(filters.recommendation) {
      updated = heads.filter((head) => {
        return !headers.includes(head.fieldName) 
      })
      return updated
    } else {
      return heads
    }
  } 

  // Function to remove updated count
  
  const removeUpdatedCount = () => {
    const currentState = JSON.parse(JSON.stringify(tableDataToShow));
    let updatedCount = currentState.map((ad) => {
      ad.isTargetChecked = false
        ad?.childRows?.map((childAd) => {
          if (childAd.isTargetChecked) {
            childAd.isTargetChecked = false;  
          }
          return {...childAd};
        });
      return {...ad};
    });
    setTableDataToShow([...updatedCount]);
    setSelectedAds([]);
  }


  const getUpdatedCount = (currentState) => {
    let updatedCount = [];
    if(isGlobal) {
      currentState?.forEach((Ad) => {
        Ad?.childRows?.forEach((childAd) => {
          if (childAd.isTargetChecked) {
            updatedCount.push(childAd);
          }
        });
      });
    } else {
      currentState?.forEach((ad,index) => {
        if(ad.isTargetChecked) {
          updatedCount.push(ad)
        }
      })
    }
  
    return updatedCount;
  };

  const showNegativeModal = () => {
    setAllFilterVisible({...allFiltersVisible,negativeTargetingModal:!allFiltersVisible.negativeTargetingModal})
  }

  // Function to convert Filters Values into Single Array

  function mapValues(obj, filterKey) {
    const keysArray = ["campaign_id", "portfolio_id", "adgroup_id", "category"];
    const newObj = { ...obj };
    for (const key in newObj) {
      if (typeof newObj[key] === "object" || Array.isArray(newObj[key])) {
        if (keysArray.includes(key) && newObj[key].length > 0) {
          newObj[key] = newObj[key].map((item) =>
            key === "category" ? item["name"] : item[filterKey]
          );
        }
      }
    }
    return newObj;
  }

  function recommendationFilteringMapper(string) {
    switch (string) {
      case "Search Terms from Auto Campaigns which are neither in SP keyword Targeting nor in SB Keyword Targeting":
        return [{"field_name":"targeting","operator":"EQUALS","value":"Automatic"}];
      case "Search Terms from Manual campaigns which are neither in SP Keyword Targeting nor in SB Keyword Targeting":
        return [{"field_name":"targeting","operator":"EQUALS","value":"Keyword Targeting"}]
      case "Search Terms from both Auto and Manual campaigns which are neither in SP Keyword Targeting nor in SB Keyword Targeting":
        return [{"field_name":"targeting","operator":"IN","values":["Automatic","Keyword Targeting"]}]
      case "Product ASINs which are in category targeting but not in SP ASIN targeting":
        return [{"field_name":"text","operator":"EQUALS","value":"category="}]
      case "Product ASINs which are in Auto targeting but not in SP ASIN targeting":
        return [{"field_name":"targeting","operator":"EQUALS","value":"Automatic"}];
      case "Product ASINs which are in both category and Auto targeting but not in SP ASIN Targeting":
        return [{"field_name":"targeting_text","operator":"EQUALS","value":"Automatic"}]
      default:
        return false
    }
  }

  function generateChipFilters(data, dataForStatFilters) {
    let filteredData = mapValues(data, "name");
    if (filterForCampaignType[0]?.values?.length > 0) {
      filteredData["campaign_type"] = filterForCampaignType[0]?.values;
    }
    if(filterForMatchType[0]?.values?.length >0) {
      filteredData["match_type"] = filterForMatchType[0]?.values;
    }
    const keys = Object.keys(filteredData);
    const transformedData = keys
      .filter(
        (key) =>
          filteredData[key] !== undefined &&
          filteredData[key] !== null &&
          filteredData[key] !== "" &&
          filteredData[key] !== "All" &&
          filteredData[key] !== "Both" &&
          key !== "product_name" &&
          key !== "asin" &&
          key !== "status"
      )
      .map((key) => {
        const fieldName = key.includes("portfolio")
          ? "Portfolio"
          : key.includes("campaign")
          ? "Campaign"
          : key.includes("category")
          ? "Category"
          :key.includes("match") ? "Match"
          : key.includes("recommendation") ?
          "Recommendation"
          : "Adgroup";
        if( (key.includes("match") && filteredData["match_type"].includes("TARGETING_EXPRESSION_PREDEFINED")) || (key.includes("match") && filteredData["match_type"].includes("TARGETING_EXPRESSION"))) {
          filteredData["match_type"] = filteredData["match_type"].map((value) => {
            if(value === "TARGETING_EXPRESSION_PREDEFINED") {
              return "Automatic"
            } else if(value === "TARGETING_EXPRESSION") {
              return "Product"
            }
            return value
          })
        }
        return {
          fieldNameOfSelectedBtn: fieldName,
          field_name: key,
          checkedValue:
            typeof filteredData[key] !== "object" &&
            !Array.isArray(filteredData[key])
              ? [filteredData[key]]
              : filteredData[key],
        };
      });

    //this will create array witch merged duplicate key in single onject
    const newArray = [];
    dataForStatFilters.forEach((obj) => {
      let newObj = {
        fieldNameOfSelectedBtn: obj.fieldNameOfSelectedBtn,
        checkedValue: obj.value,
        operator: obj?.operator,
      };
      let foundIndex = newArray.findIndex(
        (x) => x.field_name === obj.field_name
      );
      if (foundIndex === -1) {
        newArray.push({
          field_name: obj.field_name,
          values: [newObj],
          fieldNameOfSelectedBtn: obj.fieldNameOfSelectedBtn,
        });
      } else {
        newArray[foundIndex].values.push(newObj);
      }
    });

    //thiw will loop through new array and create data for min max filter chip
    let listForChip = [];
    let nonnAbbrivaationFieldName = [
      "spends",
      "impressions",
      "clicks",
      "orders",
      "sale_value",
    ];
    newArray.map((currObj) => {
      let minValueForCurrentObj = 0;
      let maxValueForCurrentObj = "Infinity";

      currObj.values.map((currentDaata) => {
        if (currentDaata?.operator === "LESS_THAN") {
          maxValueForCurrentObj = currentDaata.checkedValue;
        }
        if (currentDaata?.operator === "GREATER_THAN") {
          minValueForCurrentObj = currentDaata.checkedValue;
        }
      });

      listForChip.push({
        field_name: currObj.field_name.toLowerCase(),
        fieldNameOfSelectedBtn:
          currObj.field_name === "sale_value"
            ? "Revenue "
            : nonnAbbrivaationFieldName.includes(currObj.field_name)
            ? currObj.field_name.replace(/^\w/, (c) => c.toUpperCase())
            : currObj.field_name.toUpperCase(),
        minValue: minValueForCurrentObj,
        maxValue: maxValueForCurrentObj,
      });
    });

    const allChipFiltersData = transformedData.concat(listForChip);
    return allChipFiltersData;
  }

  function modifyData(parentData = [], childData) {
    const modifiedData = parentData?.map((eachObject) => {
      return {
        ...eachObject,
        isPlusIconDisabled: true,
        isTargetChecked: false,
        childRows: [],
      };
    });
    return modifiedData;
  }

  // ******* Methods For Action Alert Modal *********
  const noclickhandler = () => {};

  const onYesClick = () => {};

  const yesclickhandler = () => {};

  // ********* Methods For Main Filter Logic Modal ********
  const onFilterIconhandler = () => {
    dispatch(campaignListActions.showFilterModalInCampaignList());
  };

  function transformData(input,minRangeKey,maxRangeKey,fieldNameKey) {
    const output = [];
    setMinMaxFilterActivated(false);
    for (let i = 0; i < input?.length; i++) {
      const obj = input[i];

      // Check if the object has non-empty and non-zero values for minRange or maxRange
      if (
        (obj[minRangeKey] !== "" && obj[minRangeKey] !== 0 && obj[minRangeKey] !== "-") ||
        (obj[maxRangeKey] !== "" && obj[maxRangeKey] !== 0 && obj[maxRangeKey] !== "-")
      ) {
        // Add a new object to the output array with "GREATER_THAN" operator and the "minRange" value, if minRange is not empty or zero
        if (obj.minRange !== "" && obj.minRange !== 0 && obj.minRange !== "-") {
          setMinMaxFilterActivated(true);
          output.push({
            field_name: obj[fieldNameKey].toLowerCase(),
            operator: "GREATER_THAN",
            value: obj[minRangeKey].toString(),
          });
        }

        // Add a new object to the output array with "LESS_THAN" operator and the "maxRange" value, if maxRange is not empty or zero
        if (obj[maxRangeKey] !== "" && obj[maxRangeKey] !== 0 && obj[maxRangeKey] !== "-") {
          setMinMaxFilterActivated(true);
          output.push({
            field_name: obj[fieldNameKey].toLowerCase(),
            operator: "LESS_THAN",
            value: obj[maxRangeKey].toString(),
          });
        }
      }
    }
    return output;
  }

  // *********** Methods For Calendar ***********
  const dateInputClickHandler = () => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  const onSearchTermHandler = (e) => {
    if (e.key === "Enter" || e.target.value === "") {
      setSearchTermValues(e.target.value);
    }
  };

  // ********** Methods For Campaign Type Modal *********

  const applyHandler = () => {};

  const handleDropDownModal = () => {
    setAllFilterVisible({
      ...allFiltersVisible,
      searchSortModal: !allFiltersVisible.searchSortModal,
    });
    setCampaignTypeFilter(true);
    setColumnName("");
  };

  const onSearchModalHandler = (fieldName) => {
    setColumnName(fieldName);
    setAllFilterVisible({ ...allFiltersVisible, searchSortModal: true });
    setCampaignTypeFilter(false);
  };

  //this function will handle Multiselect Dropdown apply btn action
  const onApplyBtnhandler = (fieldNameAsSendInApi) => {
    if(fieldNameAsSendInApi === "campaign_type") {
      const dataToBeSet = [
        {
          field_name: "campaign_type",
          operator: "IN",
          values: checkedDropDownFilterButtonData?.map((value) => value.value),
        },
      ];
      setFilterForCampaignType(dataToBeSet);
    }

    if(fieldNameAsSendInApi === "match_type") {
      const dataToBeSet = [
        {
          field_name: "match_type",
          operator: "IN",
          values: checkedDropDownFilterButtonData?.map((value) => value?.value === "Automatic"?"TARGETING_EXPRESSION_PREDEFINED": value?.value ==="Product"?"TARGETING_EXPRESSION": value?.value.toUpperCase()),
        },
      ]
      setFilterForMatchType(dataToBeSet)
    }
  
    let selectedValue = [];
    let selectedDropDownName;
    let buttonName;

    setMultiSelectDropDownSelecteddDataCount({
      ...multiSelectDropDownSelectedDataCount,
      [fieldNameAsSendInApi]: 0,
    });
    // const [droDownFilterDataForApi, setDroDownFilterDataForApi] = useState([]);
    let fieldNameOfDropDownData = [
      "adyogi_targeting_type",
      "campaign_type",
      "campaign_objective",
    ];
    if (checkedDropDownFilterButtonData.length) {
      for (let i = 0; i < checkedDropDownFilterButtonData.length; i++) {
        fieldNameOfDropDownData.map((currentFieldName) => {
          if (
            checkedDropDownFilterButtonData[i].fieldNameForAPIFilter ===
            currentFieldName
          ) {
            selectedDropDownName =
              checkedDropDownFilterButtonData[i].fieldNameForAPIFilter;
            buttonName =
              checkedDropDownFilterButtonData[i].fieldNameOfSelectedBtn;

            currentFieldName === "adyogi_targeting_type"
              ? selectedValue.push(
                  checkedDropDownFilterButtonData[i].valueToSendForFilter
                )
              : selectedValue.push(checkedDropDownFilterButtonData[i].value);
          }
        });
      }
      fieldNameOfDropDownData.map((currentFieldName) => {
        if (selectedDropDownName === currentFieldName) {
        }
      });
    } else {
    }
    setCheckedDropDownFilterButtonData([]);
  };

  const showCheckedAppliedFilteringWhenOPen = () => {};

  const onDashIconHandlerToremoveAllCheckedOption = (
    e,
    targettingValue,
    fieldName,
    uniqueFieldName,
    fieldNameAsSendInApi
  ) => {
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    currentList.map((list) => {
      if (
        list.buttonName === targettingValue &&
        list.fieldName === uniqueFieldName
      ) {
        list.isDataChecked = false;
        checkedDropDownFilterButtonData.map((checkedData, index) => {
          if (
            checkedData.fieldNameOfSelectedBtn === targettingValue &&
            checkedData.fieldNameForAPIFilter === fieldNameAsSendInApi
          ) {
            if (index > 0) {
              checkedDropDownFilterButtonData.splice(index, 1);
            } else {
              checkedDropDownFilterButtonData.splice(index);
            }
          }
        });
      }
    });
    setDropDownDataList(currentList);
    setCheckedDropDownFilterButtonData((checkedDropDownFilterButtonData) => [
      ...checkedDropDownFilterButtonData,
    ]);
  };

  const allSeletHandler = (
    e,
    displayedBtnName,
    BtnNameUniqueField,
    fieldNameForAPIFilter
  ) => {
    setCheckedDropDownFilterButtonData([]);
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    currentList.map((list) => {
      if (
        list.buttonName === displayedBtnName &&
        list.fieldName === BtnNameUniqueField
      ) {
        if (e.target.checked) {
          list.isDataChecked = true;
          setCheckedDropDownFilterButtonData(
            (checkedDropDownFilterButtonData) => [
              ...checkedDropDownFilterButtonData,
              {
                value: list.name,
                fieldNameOfSelectedBtn: displayedBtnName,
                fieldNameForAPIFilter: fieldNameForAPIFilter,
                valueToSendForFilter: list?.valueToSnedInApiCallForFilter,
              },
            ]
          );
        }
      }
    });
    setDropDownDataList(currentList);
  };

  const onOptionCheckedHandlerInDropDownFilter = (
    e,
    checkedValue,
    fieldNameForAPIFilter,
    fieldNameOfSelectedBtn,
    fieldName
    // DropDownDataList
  ) => {
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    currentList.map((list) => {
      if (list.name === checkedValue && list.fieldName === fieldName) {
        if (e.target.checked) {
          list.isDataChecked = true;
          setCheckedDropDownFilterButtonData(
            (checkedDropDownFilterButtonData) => [
              ...checkedDropDownFilterButtonData,
              {
                value: checkedValue,
                fieldNameOfSelectedBtn: fieldNameOfSelectedBtn,
                fieldNameForAPIFilter: fieldNameForAPIFilter,
                valueToSendForFilter: list?.valueToSnedInApiCallForFilter,
              },
            ]
          );
        } else {
          list.isDataChecked = false;
          if (
            checkedDropDownFilterButtonData &&
            checkedDropDownFilterButtonData.length
          ) {
            for (
              let index = 0;
              index < checkedDropDownFilterButtonData.length;
              index++
            ) {
              if (
                checkedDropDownFilterButtonData[index] &&
                checkedDropDownFilterButtonData[index].value === checkedValue
              ) {
                checkedDropDownFilterButtonData.splice(index, 1);
              }
            }
            setCheckedDropDownFilterButtonData(
              (checkedDropDownFilterButtonData) => [
                ...checkedDropDownFilterButtonData,
              ]
            );
          }
        }
      }
    });

    setDropDownDataList(currentList);
  };

  const getUpdatedDropDownFilters = (value, key) => {
    const dataToBeSet = value;
    // (value !=="") ? value?.map((data) => {
    //   return key === "category" ? data[filterKey] : parseInt(data[filterKey]);
    // }):""
    if (key === "campaign_id") {
      const campaignIdsData =
        value !== ""
          ? value?.map((data) => {
              return data?.id;
            })
          : "";
      setCampaignsIds(campaignIdsData);
    }
    setFilters({ ...filters, [key]: dataToBeSet });
  };

  const onDropdownImghandler = () => {

  }

  // ********* Methods For Chips Filters *********
  const onCancelClickRemoveFilteringHandlerr = (fieldName) => {
    const isPresent = defaultMetrices.includes(fieldName?.toLowerCase());
    if(isPresent) {
      if(leakingConvertingFilters!=="") {
        setLeakingConvertingFilters("")
      }
      setCancelSpecifiNumberFilter(fieldName);
    } else {
      setFilters({ ...filters, [fieldName]: "" });
      if (fieldName === "campaign_id") {
        setCampaignsIds("");
      }
      if (fieldName === "campaign_type") {
        setFilterForCampaignType([]);
        setCheckedDropDownFilterButtonData([]);
        setDropDownDataList(customDropDownWithCheckboxDefaultDataArray);
      }
      if(fieldName === "match_type") {
        setFilterForMatchType([]);
        setCheckedDropDownFilterButtonData([]);
        setDropDownDataList(customDropDownWithCheckboxDefaultDataArray)
      }
    }    
  };

  const resetEverything = () => {
    setFilters(initialFiltersData);
    setStatFilters([]);
    setFilterForCampaignType([]);
    setCheckedDropDownFilterButtonData([]);
    setDropDownDataList(customDropDownWithCheckboxDefaultDataArray);
    setFilterForMatchType([]);
    setCampaignsIds("");
    setSearchTermValues("");
    setCancelSpecifiNumberFilter("RemoveAllRanges");
    const searchBarElement = document.getElementById("ma-global-targeting-list")
    searchBarElement.value = "" // Remove the value from search bar as well.
  };

  // ********** Methods For Redirecting to different Pages
  const redirectToCampaignList = (campName) => {
    if (campName) {
      window.open(
        `/campaign-list?campName=${campName}`,
        "_blank",
        "noreferrer"
      );
    }
  };

  const redirectToAdgroupName = (childData) => {
    const { campaign_id, ad_group_id, ad_group_name, campaign_name, asin,search_term } =
      childData;
    const campaignIdAlphanumeric = searchFiltersState.campaigns.find(
      (campaign) => parseInt(campaign.platform_id) === campaign_id
    );
    const adgroupIdAlphanumeric = searchFiltersState.adGroups.find(
      (adgroup) => parseInt(adgroup?.platform_id) === ad_group_id
    );
    if (campaignIdAlphanumeric && adgroupIdAlphanumeric) {
      window.open(
        `/edit-adset-targeting?campaignId=${
          campaignIdAlphanumeric?.id
        }&adsetId=${
          adgroupIdAlphanumeric?.id
        }&numericCampaignId=${campaign_id}&numericAdsetId=${ad_group_id}&name=${ad_group_name}&adsetPlatformId=${ad_group_id}&campName=${campaign_name}&editsearchtermpage=${true}&search_term=${search_term}`,
        "_blank",
        "noreferrer"
      );
    }
  };

  // ********* Methods For Expanding Rows ********

  const expandRow = async (recievedTerm) => {
    setCurrentSearchTerm(recievedTerm);
    setAllLoaders({ ...allLoaders, childRowLoader: true });
    const searchTermForFiltering = [
      { field_name: "search_term", operator: "EQUALS", value: recievedTerm },
    ];
    const filteredData = mapValues(filters, "platform_id");
    const filtering = generateFiltering(filteredData);
    
    let mainFiltering = filtering.concat(statFilters);
    mainFiltering = mainFiltering.concat(searchTermForFiltering);

    if (filterForCampaignType[0]?.values?.length > 0) {
      mainFiltering = mainFiltering.concat(filterForCampaignType);
    }
    if (filterForMatchType[0]?.values?.length > 0) {
      mainFiltering = mainFiltering.concat(filterForMatchType);
    }
    const recommendationFilter = recommendationFilteringMapper(filters.recommendation);
    if(recommendationFilter?.length) {
      mainFiltering = mainFiltering.concat(recommendationFilter)
    }
    try {
      const childRowData = {
        source: "amazon",
        master_row: "false",
        order_by: "spends",
        // limit: limit,
        // offset: 0,
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        filtering: encodeData(mainFiltering),
      };
      let currentTableStateCopy = JSON.parse(JSON.stringify(tableDataToShow));
      const selectedAd = currentTableStateCopy.filter(
        (ad) => ad?.search_term === recievedTerm
      )[0];
      if (selectedAd?.childRows.length > 0) {
        currentTableStateCopy = currentTableStateCopy.map((ad) => {
          if (ad?.search_term === recievedTerm) {
            ad.isPlusIconDisabled = !ad?.isPlusIconDisabled;
          }
          return ad;
        });
      } else {
        if(filters.recommendation.includes("Keyword")) {
          childRowData["keyword_harvesting"] = true
        }
        if(filters.recommendation.includes("Product")) {
          childRowData["product_harvesting"] = true
        }
        const childRowsProducts = await masterApi(
          childRowData,
          "masterSearchTerm",
          false
        );
        if (childRowsProducts.statusCode !== 200) {
          // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          //   backgroundColor: TOAST_DANGER,
          //   title: "Error",
          //   description: `Unknown Error Occured with statusCode ${childRowsProducts?.statusCode}`,
          // });
          toast.error(`Unknown Error Occured with statusCode ${childRowsProducts?.statusCode}`)
        }
        currentTableStateCopy = currentTableStateCopy?.map((ad) => {
          if (ad?.search_term === recievedTerm) {
            ad.isPlusIconDisabled = false;
            ad.childRows = childRowsProducts?.data?.data;
          }
          return ad;
        });
      }
      setAllLoaders({ ...allLoaders, childRowLoader: false });
      setTableDataToShow(currentTableStateCopy);
      setCurrentSearchTerm("");
    } catch (error) {

    }
  };

  const childRowCheckboxHandler = (term,sentIndex) => {
    const updatedData = tableDataToShow?.map((search_term) => {
      if(search_term.search_term === term) {
        search_term["childRows"] = search_term["childRows"]?.map((child_term,index) => {
          if(index === sentIndex) {
            child_term.isTargetChecked = !child_term.isTargetChecked
          }
          return child_term
        })
      }
      return search_term
    })
    setTableDataToShow([...updatedData])
  }

  const parentRowCheckBoxHandler = async (recievedTerm,receivedIndex) => {
    if(isGlobal) {
      setCurrentSearchTerm(recievedTerm); 
    setAllLoaders({ ...allLoaders, childRowLoader: true });
    const searchTermForFiltering = [
      { field_name: "search_term", operator: "EQUALS", value: recievedTerm },
    ];
    const filteredData = mapValues(filters, "platform_id");
    const filtering = generateFiltering(filteredData);
    let mainFiltering = filtering.concat(statFilters);
    mainFiltering = mainFiltering.concat(searchTermForFiltering);
    if (filterForCampaignType[0]?.values?.length > 0) {
      mainFiltering = mainFiltering.concat(filterForCampaignType);
    }
    if (filterForMatchType[0]?.values?.length > 0) {
      mainFiltering = mainFiltering.concat(filterForMatchType);
    }
    try {
      const childRowData = {
        source: "amazon",
        master_row: "false",
        order_by: "spends",
        // limit: limit,
        // offset: 0,
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        filtering: encodeData(mainFiltering),
      };
      let currentTableStateCopy = JSON.parse(JSON.stringify(tableDataToShow));
      const selectedAd = currentTableStateCopy.filter(
        (ad) => ad?.search_term === recievedTerm
      )[0];
      if (selectedAd?.childRows.length > 0) {
        currentTableStateCopy = currentTableStateCopy.map((ad) => {
          if (ad?.search_term === recievedTerm) {
            ad.isPlusIconDisabled = false
            ad.isTargetChecked = !ad.isTargetChecked
            ad["childRows"] = ad["childRows"]?.map((childRow) => {
              if(!childRow?.campaign_type?.includes("SB")) {
                childRow.isTargetChecked = !childRow.isTargetChecked
              }
              return childRow
            })
          }
          return ad;
        });
      } else {
        const childRowsProducts = await masterApi(
          childRowData,
          "masterSearchTerm",
          false
        );
        if (childRowsProducts.statusCode !== 200) {
          // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          //   backgroundColor: TOAST_DANGER,
          //   title: "Error",
          //   description: `Unknown Error Occured with statusCode ${childRowsProducts?.statusCode}`,
          // });
          toast.error(`Unknown Error Occured with statusCode ${childRowsProducts?.statusCode}`)
        }
        currentTableStateCopy = currentTableStateCopy?.map((ad) => {
          if (ad?.search_term === recievedTerm) {
            ad.isPlusIconDisabled = false;
            ad.isTargetChecked = true
            ad.childRows = childRowsProducts?.data?.data;
            ad["childRows"] = ad["childRows"]?.map((childRow) => {
              if(!childRow?.campaign_type?.includes("SB")) {
                childRow.isTargetChecked = !childRow.isTargetChecked
              }
              return childRow
            })
          }
          return ad;
        });
      }
      setAllLoaders({ ...allLoaders, childRowLoader: false });
      setTableDataToShow(currentTableStateCopy);
      setCurrentSearchTerm("");
    } catch (error) {
      console.log(error)
    }
    } else {
      let currentTableStateCopy = JSON.parse(JSON.stringify(tableDataToShow));
      currentTableStateCopy = currentTableStateCopy?.map((data,index) => {
        if(data?.search_term === recievedTerm && index === receivedIndex) {
          data.isTargetChecked = !data.isTargetChecked
        }
        return {...data}
      })
      setTableDataToShow(currentTableStateCopy)
    }
   
  }

  // ********* Methods For Sorting ********
  const onSortHandler = (
    initialData,
    condition,
    status,
    type,
    currentIndex
  ) => {
    let convertedData = JSON.parse(JSON.stringify(dataHeads));
    convertedData = convertedData.map((head) => {
      if (head.id === status) {
        head.isSortingClicked = !head.isSortingClicked;
      } else {
        head.isSortingClicked = false;
      }
      return head;
    });
    setSortBy({
      ...sortBy,
      sort_head: status,
      order: condition ? "desc" : "asc",
    });
    // // Update Necessary States
    setDataHeads(convertedData);
  };

  // ********* Methods For Lazy Loading *********

  // const handleScroll = () => {};

  const handleScroll = async () => {
    const container = tableRef.current;
    let { currentPage, currentOffSet } = paginationAction;
    // Keep track of last scroll position
    const lastScrollTop = container.getAttribute("data-scroll-top") || 0;

    // Determine scroll direction
    const scrollDirection = container.scrollTop > lastScrollTop ? "down" : "up";

    // Update last scroll position
    container.setAttribute("data-scroll-top", container.scrollTop);
    if (
      scrollDirection === "down" &&
      Math.ceil(container.scrollTop + container.clientHeight) >=
        container.scrollHeight-10 &&
      tableDataToShow?.length >= 50
    ) {
      currentOffSet = currentOffSet + limit;
      currentPage = currentOffSet / limit + 1;
      if (!lazyLoading) {
        const moreData = await loadMoreData(currentOffSet);
        const returnedData = [...modifyData(moreData)];
        setTableDataToShow((prev) => [...prev,...returnedData]);
        setPaginationAction({
          ...paginationAction,
          currentPage,
          currentOffSet,
        });
      }
    }
  };

  // ********** API Calls Functions ************
  async function getParentsRowsData() {
    setAllLoaders({ ...allLoaders, mainLoader: true });
    try {
      const filteredData = mapValues(filters, "platform_id");
      const filtering = generateFiltering(filteredData);
      let mainFiltering = filtering.concat(statFilters);

      if (searchTermValues.length > 0) {
        const recommendationFilter = recommendationFilteringMapper(filters.recommendation);
        const operatorForSearchTerm = (recommendationFilter?.length || !isGlobal) ? "CONTAINS" : "EQUALS"
        const filteringAppending = [
          {
            field_name: "search_term",
            operator: operatorForSearchTerm,
            value: searchTermValues,
          },
        ];
        mainFiltering = mainFiltering.concat(filteringAppending);
      }
      if (filterForCampaignType[0]?.values?.length > 0) {
        mainFiltering = mainFiltering.concat(filterForCampaignType);
      }
      if (filterForMatchType[0]?.values?.length > 0) {
        mainFiltering = mainFiltering.concat(filterForMatchType);
      }
      const recommendationFilter = recommendationFilteringMapper(filters.recommendation);
      if(recommendationFilter?.length) {
        mainFiltering = mainFiltering.concat(recommendationFilter)
      }
      const parentRowData = {
        source: "amazon",
        master_row: isGlobal,
        order_by: sortBy.sort_head,
        sorting_order: sortBy.order,
        limit: limit,
        offset: 0, // Permanent offset for parent (Load more data function already exists with pagination state that will be triggered on scroll)
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        filtering: encodeData(mainFiltering),
      };

      if(filters.recommendation.includes("Keyword")) {
        parentRowData["keyword_harvesting"] = true
      }
      if(filters.recommendation.includes("Product")) {
        parentRowData["product_harvesting"] = true
      }
      const result = await masterApi(parentRowData, "masterSearchTerm", false);
      if (result && result?.data?.data) {
        const returnedData = [...modifyData(result?.data?.data)]?.map((data) => {
          return {...data}
        })
        setTableDataToShow((prev) => {
          return [...returnedData]
        })
      } else {
        toast.error(`Unknown Error Occurred with statusCode${result?.statusCode}`)
      }
      setAllLoaders({ ...allLoaders, mainLoader: false });
      setInitialLoad(false)
    } catch (error) {
      setAllLoaders({ ...allLoaders, mainLoader: false });
      setInitialLoad(false)
      console.log(error);
    }
    // If Offset is not 0 then reset it to 0 everytime when this function get's called
    if(paginationAction.currentOffSet!==0) {
      setPaginationAction((prev) => {
        return {
          ...prev,
          currentOffSet:0,
          currentPage:1
        }
        })
    }

    if(!isAllFiltersPresent&&leakingConvertingFilters!=="") {
      setRemoveFilters((prev) => !prev)
    }
   
  }

  const getAllCampaigns = async () => {
    const campaignFilteringBasedOnPortfolio = generateFiltering({
      amz_portfolio_id: mapValues(filters, "platform_id")?.portfolio_id,
    });
    const adGroupFilteringBasedonCampaign = generateFiltering({
      campaign_id: campaignsIds ?? [],
    });
    const dataForCampaign = {
      source: "amazon",
      fields: "id,name,platform_id",
      filtering: encodeData(campaignFilteringBasedOnPortfolio),
    };
    const dataForAdgroup = {
      source: "amazon",
      fields: "id,name,platform_id",
      filtering: encodeData(adGroupFilteringBasedonCampaign),
    };
    const dataForPortfolio = {
      source: "amazon",
      fields: "id,name,platform_id",
    };
    try {
      const campaignsResponse = await masterApi(
        dataForCampaign,
        "campaignList",
        false
      );
      const adgroupResponse = await masterApi(
        dataForAdgroup,
        "adsetList",
        false
      );
      const portfolioResponse = await masterApi(
        dataForPortfolio,
        "getPortfolios",
        false
      );
      const campaigns = campaignsResponse?.data?.data;
      const adgroups = adgroupResponse?.data?.data;
      const portfolios = portfolioResponse?.data?.data;
      setSearchFiltersState({
        ...searchFiltersState,
        campaigns: campaigns,
        adGroups: adgroups,
        portfolios,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const loadMoreData = async (currentOffset) => {
    try {
      setLazyLoading(true);
      const mappedData = mapValues(filters, "platform_id");
      const filtering = generateFiltering(mappedData);
      let mainFiltering = filtering.concat(statFilters);
      if (searchTermValues.length > 0) {
        const recommendationFilter = recommendationFilteringMapper(filters.recommendation);
        const operatorForSearchTerm = (recommendationFilter?.length || !isGlobal) ? "CONTAINS" : "EQUALS"
        const filteringAppending = [
          {
            field_name: "search_term",
            operator: operatorForSearchTerm,
            value: searchTermValues,
          },
        ];
        mainFiltering = mainFiltering.concat(filteringAppending);
      }
      if (filterForCampaignType[0]?.values?.length > 0) {
        mainFiltering = mainFiltering.concat(filterForCampaignType);
      }
      if (filterForMatchType[0]?.values?.length > 0) {
        mainFiltering = mainFiltering.concat(filterForMatchType);
      }
      const recommendationFilter = recommendationFilteringMapper(filters.recommendation);
      if(recommendationFilter?.length) {
        mainFiltering = mainFiltering.concat(recommendationFilter)
      }
      const parentRowData = {
        source: "amazon",
        master_row: isGlobal,
        order_by: sortBy?.sort_head,
        sorting_order: sortBy.order,
        limit: limit,
        offset: currentOffset,
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        filtering: encodeData(mainFiltering),
      };
      if(filters.recommendation.includes("Keyword")) {
        parentRowData["keyword_harvesting"] = true
      }
      if(filters.recommendation.includes("Product")) {
        parentRowData["product_harvesting"] = true
      }
      const result = await masterApi(parentRowData, "masterSearchTerm", false);
      setLazyLoading(false);
      return result?.data?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getRecommendationFilter = (e,data) => {
    setFilters({...filters,recommendation:e.target.value})
  }

  const [selectedChildRows, setSelectedChildRows] = useState([]);
  const handleCellClick = (rowIndex) => {
    setSelectedChildRows((prevSelectedRows) =>
      prevSelectedRows.includes(rowIndex)
        ? prevSelectedRows.filter((row) => row !== rowIndex)
        : [...prevSelectedRows, rowIndex]
    );
  }

 
  const getClassNameThead = (th, sortBy) => {
    let clsname = "targeting-table-heads text-nowrap"

    if (th.isSortFunctionalityNeededHere && th.id === sortBy.sort_head) {
      clsname += " selected";
    }else if((isCampaignFilterActive && th.fieldName === "Campaign")){
      clsname += " selected";
    }
    else if((isPortfolioFilterActive && th.fieldName === "Portfolio")){
      clsname += " selected";
    }
    else if((adGroupFilterActive && th.fieldName === "Ad Group")){
      clsname += " selected";
    }
    else if((categoryFilterActive && th.fieldName === "Category")){
      clsname += " selected";
    }

    return clsname;
  }

  const bulkCheckBoxHandler = (e) => {
   const status = e.target.checked;
   if(!isGlobal) {
    const parsedData = JSON.parse(JSON.stringify(tableDataToShow));
    const updatedData = parsedData?.map((data) => {
      data.isTargetChecked = status
      return {...data}
    })
    setTableDataToShow(updatedData)
   }
  }
 // Table Body
  let tableBody = (
    <>
      <div
        className={ lazyLoading ?  "main-campaignlist-table-body-container overflow-x-hidden master-ads-main-table-wrapper" :  "main-campaignlist-table-body-container master-ads-main-table-wrapper"}
        ref={tableRef}
        onScroll={handleScroll}
      >
        {tableDataToShow?.length && !allLoaders?.mainLoader ? (
          <>
            <table className="search-term-table reports">
              <thead className="fixed-table">
                <tr className="main-table-row-fixed">
                  {customDataHeads?.map((th, thIndex) => {
                    return (
                      <th
                        key={th.fieldName + thIndex + "12"}
                        className={getClassNameThead(th,sortBy)}
                        // className="targeting-table-heads"
                      >
                        {th.isSortFunctionalityNeededHere ? (
                          <div className="d-flex flex-row align-items-center justify-content-end ">
                            <div className="d-flex align-items-center">
                              <span
                                className={
                                  th.id === sortBy.sort_head
                                    ? "stat-heads-with-icon active-filter-higlighted-text"
                                    : "stat-heads-with-icon"
                                }
                              >
                                <span
                                  className={
                                    upperCaseValues?.includes(
                                      th.fieldName.toLowerCase()
                                    )
                                      ? "text-uppercase"
                                      : "text-capitalize"
                                  }
                                >
                                  {th.fieldName === "Campaign"
                                    ? "Campaigns"
                                    : !upperCaseValues?.includes(
                                        th.fieldName.toLowerCase()
                                      )
                                    ? th.fieldName.toLowerCase()
                                    : th.id === "aov"
                                    ? "AOV"
                                    : th.fieldName}
                                </span>
                              </span>
                            </div>
                            <span className="sort-icon cursor-pointer-global">
                              <img
                                className={
                                  th.id === sortBy.sort_head
                                    ? " d-flex align-items-end mt-spends-icon-activated "
                                    : " d-flex align-items-end"
                                }
                                src={
                                  !th.isSortingClicked
                                    ? ascendingIconSvg
                                    : descendingIconSvg
                                }
                                onClick={() =>
                                  onSortHandler(
                                    tableDataToShow,
                                    th.isSortingClicked,
                                    th.id,
                                    "masterAds",
                                    thIndex
                                  )
                                }
                                alt={"HeadsWithSorting"}
                              ></img>
                            </span>
                          </div>
                        ) : th.fieldName === "Portfolio" ||
                          th.fieldName === "Campaign" ||
                          th.fieldName === "Category" ||
                          th.fieldName === "Ad Group" ? (
                          <>
                            <div
                              className={`fieldnamme-with-search-iconn justify-content-start`}
                            >
                              <span className="d-flex align-items-center me-2">
                                <img
                                  // className="find-icon cursor-pointer-global"
                                  className={
                                    (isCampaignFilterActive &&
                                      th.fieldName === "Campaign") ||
                                    (isPortfolioFilterActive &&
                                      th.fieldName === "Portfolio") ||
                                    (adGroupFilterActive &&
                                      th.fieldName === "Ad Group") ||
                                    (categoryFilterActive &&
                                      th.fieldName === "Category")
                                      ? "find-icon cursor-pointer-global mt-find-icon-activated"
                                      : "find-icon cursor-pointer-global"
                                  }
                                  src={whiteFindIcon}
                                  onClick={() =>
                                    onSearchModalHandler(th.fieldName)
                                  }
                                  alt={"whiteFindIcon"}
                                ></img>
                              </span>
                              <div className="d-flex align-items-center test">
                                <span
                                  className={
                                    (isCampaignFilterActive &&
                                      th.fieldName === "Campaign") ||
                                    (isPortfolioFilterActive &&
                                      th.fieldName === "Portfolio") ||
                                    (adGroupFilterActive &&
                                      th.fieldName === "Ad Group") ||
                                    (categoryFilterActive &&
                                      th.fieldName === "Category")
                                      ? "active-filter-higlighted-text"
                                      : "stat-heads-with-icon "
                                  }
                                >
                                  {th.fieldName === "Campaign"
                                    ? "Campaigns"
                                    : th.fieldName}
                                </span>
                              </div>
                              {columnName === th.fieldName &&
                                allFiltersVisible.searchSortModal && (
                                  <MasterTargetingSearchSelectModal
                                    findClickedColumnName={columnName}
                                    keyToBeSet={
                                      th.fieldName === "Campaign"
                                        ? "campaign_id"
                                        : th.fieldName === "Ad Group"
                                        ? "adgroup_id"
                                        : th.fieldName === "Portfolio"
                                        ? "portfolio_id"
                                        : "category"
                                    }
                                    getUpdatedDrowDownFilters={
                                      getUpdatedDropDownFilters
                                    }
                                    isVisible={
                                      allFiltersVisible.searchSortModal
                                    }
                                    initialData={
                                      th.fieldName === "Campaign"
                                        ? searchFiltersState.campaigns
                                        : th.fieldName === "Ad Group"
                                        ? searchFiltersState.adGroups
                                        : th.fieldName === "Portfolio"
                                        ? searchFiltersState.portfolios
                                        : []
                                    }
                                    cancelFilter={setAllFilterVisible}
                                    currentFilters={allFiltersVisible}
                                  />
                                )}
                            </div>
                          </>
                        ) : th.fieldName === "Search Term" ? <div className="d-flex  align-items-center">
                         {!isGlobal ?  <CheckboxRb onChange={bulkCheckBoxHandler}/> : null}
                          {th.fieldName}
                        </div>: (
                          th.fieldName
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              {tableDataToShow?.length ? (
                <tbody className="another-body">
                  {tableDataToShow?.map((parentAd, paIndex) => {
                    const productNameToBeSent = (parentAd?.search_term?.length === 10 && upperCaseAsinTargetins.includes(parentAd?.targeting)) ? parentAd?.search_term.toUpperCase():parentAd?.search_term
                    return (
                      <React.Fragment key={paIndex+12}>
                        <tr  onClick={() => handleCellClick(paIndex + "mainrow")}
                          className={
                            selectedChildRows.includes(paIndex + "mainrow")
                              ? "cursor-pointer highlight"
                              : "cursor-pointer"
                          }>
                          <td className="ads-td-second d-flex" >
                            <div className="main-parent-table-product-container d-flex  justify-content-between align-item-center">
                              <div onClick={() => handleCellClick(paIndex + "mainrow")}><CheckboxRb checked={parentAd?.isTargetChecked} onChange={() => parentRowCheckBoxHandler(parentAd?.search_term,paIndex)}/></div>
                              <ProductNameTd
                                product_name={productNameToBeSent}
                                asin={parentAd?.asin}
                                image_url={parentAd?.imageurl}
                                noImage={true}
                                noSliced={true}
                                dynamicWidth="product-name-dyanamic-width-foe-master-ad"
                              />
                             {
                              // !isRecommendationFilterActive && (
                                <div
                                className={
                                  parentAd.isPlusIconDisabled
                                    ? `${isGlobal ? "plus-icon-sec-container d-flex align-items-center ":"empty-box-container"}`
                                    : `plus-icon-sec-container-when-clicked d-flex align-items-center`
                                }
                              >
                                {parentAd?.search_term && isGlobal &&  <div className="product-child-row-button cursor-pointer-global d-flex justify-content-end">
                                  <div className="td-with-loader d-flex align-items-center" onClick={() => handleCellClick(paIndex + "mainrow")}>
                                    {allLoaders.childRowLoader &&
                                    currentSearchTerm ===
                                      parentAd?.search_term ? (
                                      <Spinner
                                        shimmer={true}
                                        shimmerShape={"box"}
                                      />
                                    ) : (
                                      parentAd?.search_term && isGlobal && <img
                                        src={
                                          parentAd.isPlusIconDisabled
                                            ? AddIcon
                                            : MinusIcon
                                        }
                                        alt="open-close"
                                        onClick={() => {
                                          expandRow(parentAd?.search_term);
                                        }}
                                      />
                                      
                                    )}
                                  </div>
                                </div>}
                               
                              </div>  
                              // )
                             }
                             
                            </div>
                          </td>
                          {
                              (isCustomHeadsRequired && isGlobal) &&
                              <>
                              <td>{"-"}</td>
                              <td>{"-"}</td>
                              <td className="empty-td-fixed"> 
                                <span>
                                  {"-"}
                                </span>
                                </td>   
                              </>
                          }
                           {
                            !isGlobal && <>
                             <td className="text-nowrap">{parentAd?.text ?? "-"}</td>
                             <td>{parentAd?.targeting ?? "-"}</td>
                              <td>{parentAd?.targeting.includes("Keyword") ? parentAd?.match_type : "-"}</td>
                             
                            </>
                           }
                          <td>{ isGlobal ?  parentAd?.portfolio : parentAd?.portfolio_name ?? "-"}</td>

                        
                          <td>
                           { isGlobal ? <div className="d-flex flex-row  ">
                              <div className="campaign-count-circle ">
                                {parentAd?.campaign_count ?? "-"}
                              </div>
                              <span>{"Campaigns"}</span>
                            </div> : <div className="cursor-pointer-global adgroup-clickable-text-color" onClick={() => redirectToCampaignList(parentAd?.campaign_name)}> {parentAd?.campaign_name }</div>}
                          </td>
                          <td>
                          { isGlobal ? <div className="d-flex flex-row  ">
                              <div className="campaign-count-circle ">
                                {parentAd?.adgroup_count ?? "-"}
                              </div>
                              <span>{"Ad Groups"}</span>
                            </div> : <div className="cursor-pointer-global adgroup-clickable-text-color" onClick={() => redirectToAdgroupName(parentAd)}> {parentAd?.ad_group_name }</div> }
                          </td>
                          {customDataHeads?.map((currentDataHead, i) => {
                            if (!currentDataHead.isDefaultHead) {
                              return (
                                <td key={i + 11} className="stats-data-col">
                                  {
                                    parentAd[currentDataHead?.id]
                                      ? typeof parentAd[currentDataHead.id] ===
                                          "number" &&
                                        metricesWithNoDecimal.includes(
                                          currentDataHead.id
                                        )
                                        ? Math.round(
                                            parentAd[currentDataHead.id]
                                          )
                                        : typeof parentAd[
                                            currentDataHead.id
                                          ] === "number" &&
                                          metricesWithTwoDecimal.includes(
                                            currentDataHead.id
                                          )
                                        ? parentAd[currentDataHead.id].toFixed(
                                            2
                                          )
                                        : parentAd[currentDataHead.id]
                                      : "0"
                                    // campStatInst ? campStatInst[currentDataHead.id] : '-'
                                  }
                                </td>
                              );
                            }
                          })}
                        </tr>
                        {allLoaders.childRowLoader &&
                        currentSearchTerm === parentAd.search_term ? (
                          <>
                            {Array.from(Array(3)).map((dumyEl) => {
                              return (
                                <tr>
                                  {customDataHeads.map((el) => {
                                    return (
                                      <td className="ma-loader">
                                        {/* <div className="ma-loader"> */}
                                        <div className="td-with-loader">
                                          <Spinner shimmer={true} />
                                          {/* </div> */}
                                        </div>
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          parentAd["childRows"] &&
                          parentAd["childRows"].map((childAd, mainIndex) => {
                            const productNameToBeSent = (childAd?.search_term?.length === 10 && upperCaseAsinTargetins.includes(childAd?.targeting)) ? childAd?.search_term.toUpperCase():childAd?.search_term
                            return (
                              <tr
                                key={Math.random() * 10000}
                                // className={
                                //   parentAd.isPlusIconDisabled
                                //     ? "hide"
                                //     : "ad-campaign-details"
                                // }
                                onClick={() => handleCellClick(mainIndex + "subrow")}
                                className={parentAd.isPlusIconDisabled ? "hide"
                                    : selectedChildRows.includes(mainIndex + "subrow")
                                    ? "ad-campaign-details cursor-pointer highlight"
                                    : "ad-campaign-details cursor-pointer"
                                }
                              >
                                <td className="ads-td-second">
                                  <div className="main-child-table-product-containerr d-flex">
                                  <div onClick={() => handleCellClick(mainIndex + "subrow")}>
                                    <CheckboxRb checked={childAd?.isTargetChecked} disabled={childAd?.campaign_type?.includes("SB")} onChange={() => childRowCheckboxHandler(parentAd?.search_term,mainIndex)}/> </div>
                                    <ProductNameTd
                                      asin={childAd?.asin ?? "-"}
                                      product_name={productNameToBeSent}
                                      image_url={childAd?.imageurl}
                                      noSliced={true}
                                      noImage={true}
                                      dynamicWidth="product-name-dyanamic-width-foe-master-ad"
                                    />
                                  </div>
                                </td>
                                <td>{childAd?.text ?? "-"}</td>
                                <td>
                                  <span
                                    className="cursor-pointer-global"
                                    // onClick={() => redirectToAmazon(childAd?.asin)}
                                  >
                                    {childAd?.targeting ?? "-"}
                                  </span>
                                </td>
                                {/* If the targeting is Keyword then add then show matchType */}
                                <td>{childAd?.targeting?.includes("Keyword") ? childAd?.match_type : "-"}</td>
                                <td>{childAd?.portfolio_name ?? "-"}</td>
                                <td>
                                  <div
                                    className="chid-row-campaign cursor-pointer-global adgroup-clickable-text-color"
                                    onClick={() => {
                                      redirectToCampaignList(
                                        childAd?.campaign_name
                                      );
                                    }}
                                  >
                                    {childAd?.campaign_name ?? "-"}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className="chid-row-campaign cursor-pointer-global adgroup-clickable-text-color"
                                    onClick={() =>
                                      redirectToAdgroupName(childAd)
                                    }
                                  >
                                    {childAd?.ad_group_name ?? "-"}
                                  </div>
                                </td>
                                {customDataHeads?.map((currentDataHead, i) => {
                                  if (!currentDataHead.isDefaultHead) {
                                    return (
                                      <td
                                        key={i + 13}
                                        className="stats-data-col"
                                      >
                                        {parentAd?.childRows?.length
                                          ? typeof childAd[
                                              currentDataHead.id
                                            ] === "number" &&
                                            metricesWithNoDecimal.includes(
                                              currentDataHead.id
                                            )
                                            ? Math.round(
                                                childAd[currentDataHead.id]
                                              )
                                            : typeof childAd[
                                                currentDataHead.id
                                              ] === "number" &&
                                              metricesWithTwoDecimal.includes(
                                                currentDataHead.id
                                              )
                                            ? childAd[
                                                currentDataHead.id
                                              ].toFixed(2)
                                            : childAd[currentDataHead.id]
                                          : "0"}
                                      </td>
                                    );
                                  }
                                })}
                              </tr>
                            );
                          })
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              ) : (
                <>
                  { !tableDataToShow?.length && !allLoaders?.mainLoader   && !initialLoad  &&   <h3 className="text-center">No Data Found</h3>}
                </>
              )}
            </table>
            {lazyLoading && (
              <div className="lazy-loading-container d-flex p-5 justify-content-center align-items-center w-100">
                <LazyLoadSpinner text={"Loading 50 More Terms..."} />
              </div>
            )}
          </>
        ) : (
          <>
        { !tableDataToShow?.length && !allLoaders?.mainLoader && !initialLoad &&   <h3 className="text-center">No Data Found</h3>}
          </>
        )}
      </div>
    </>
  );

  const addNegativeTargeting = async (recievedData) => {
    const data = recievedData
    const {level,campaignIds} = data
    // Convert the data in API Payload Format
    const dataToBeSentInAPI = selectedAds.map((search_term) => {
      const type = search_term.targeting.includes("Keyword") ? "keyword" : "product";
      const KEYWORD_PAYLOAD = {
        ...(level==="account"?{negative_targeting_type:"NEGATIVE_KEYWORD"}:{type:"NEGATIVE_KEYWORD"}),
            amz_negative_keyword_targeting: [
              {
                text: search_term?.search_term?.toLowerCase(),
                status: "ENABLED",
                match: "exact"
              }
            ]
      }
      const PRODUCT_PAYLOAD = {
        ...(level==="account"?{negative_targeting_type:"NEGATIVE_PRODUCT"}:{type:"NEGATIVE_PRODUCT"}),
        amz_negative_product_targeting:[
          {
            status:"ENABLED",
            expression:[
              {
                type:"product",
                value:search_term?.search_term?.toLowerCase()
              }
            ]
          }
        ]
      }
      const payloadToReturn = (type === "keyword" && level === "account") 
      ? {type:"amazon_ad_account",...KEYWORD_PAYLOAD} : 
      (type === "keyword" && level === "campaign") 
      ? KEYWORD_PAYLOAD : (type === "product" && level === "account") 
      ? {type:"amazon_ad_account",...PRODUCT_PAYLOAD} 
      : PRODUCT_PAYLOAD
      return payloadToReturn
    })

    // Check the Type and Send Payload According to Client's Choosen Level
    const PAYLOAD_TO_BE_SENT_IN_API = level === "account" ? dataToBeSentInAPI : {campaign_ids:[...campaignIds],negative_targets:dataToBeSentInAPI}
    const client_id = JSON.parse(localStorage.getItem("clientId"));
    const BASE_URL_FOR_ACCOUNTS = `${process.env.REACT_APP_ADS_API}/accounts/client/${client_id}:`
    const BASE_URL_FOR_CAMPAIGNS = `${process.env.REACT_APP_ADS_API}/client/${client_id}:`
    
    // Modify BASE_URL according to Client's Choosen Level
    const CUSTOM_URL_FOR_API = level === "account" ? BASE_URL_FOR_ACCOUNTS + "add-amazon-negative-target-on-account?type=amazon_ad_account" : BASE_URL_FOR_CAMPAIGNS + "add-amazon-negative-target-on-campaigns";
    try {
      setAllLoaders({...allLoaders,mainLoader:true})
      const data = await axios.post(CUSTOM_URL_FOR_API,PAYLOAD_TO_BE_SENT_IN_API);
      // If Success Then Show Alert Success
      if(( level === "account" && data?.data?.success) || (data?.status===200)) {
        showNegativeModal()
        toast.success(`Negative Search Terms Added Successfully`)
      } else {
        toast.error(`Unknown Error Occured while adding Negative Search Terms`)
      }
      setAllLoaders({...allLoaders,mainLoader:false})
    } catch (error) {
      console.log(error)
      toast.error(`Unknown Error Occured while adding Negative Search Terms`)
      setAllLoaders({...allLoaders,mainLoader:false})
    }
  }

  function removeEmptyObjects (arrayOfObj){
    const filteredData = arrayOfObj?.filter((data) => {
      const minValue = Number(data?.minValue) 
      const maxValue = Number(data?.maxValue)
      return minValue>0 || maxValue>0
    })
    return filteredData
  }

  const addToConvertingandLeaking = async (data) => {
    const {percentageFilters,normalFilters} = data
    const converting = removeEmptyObjects(normalFilters);
    const leaking = removeEmptyObjects(percentageFilters);
    const payloadData = {
      key:"amz_search_term_config",
      value:{
        converting:{
          values:converting
        },
        leaking:{
          values:leaking
        }
      }
    }
    try {
      const requestType = Object.keys(userCustomConfigData)?.length ? "put":"post"
      const data = await masterApi(payloadData,"updateClientConfig",false,requestType,userCustomConfigData?.id);
      if(data?.data?.success) {
        setRefreshFilteringData(!refreshFilteringData)
        setLeakingConvertingFilters("")
        toast.success(`Conditions Updated Successfully. Please Select Again!`)
      } else {
        toast.error('Error While Updating Configs.')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setLeakingConvertingFilters((prev) => {
      return recievedText
     });
  },[recievedText])


  useEffect(() => {
    if(leakingConvertingFilters!=="" && !isAllFiltersPresent) {
      setLeakingConvertingFilters("");
    }
  },[removeFilters])

  const handleLeakingFilters = (present,text) => {
    // This will remove if there are any prexisting filters before applying this from the table
    setCancelSpecifiNumberFilter((prev) => prev==="RemoveSpecificFilters" ? "RemoveSpecialFilters":"RemoveSpecificFilters");
    // This will set the new filter after cancelSpecificNumberFilter is removed from the table after delay of 500
    // const id = setTimeout(() => {
    
    // clearTimeout(id)
    // },500); 
    // This will remove preexisting filters is user is removing current filter
    setRecievedText((prev) => {
      return (text===prev)?"":text
     });
    if(text === leakingConvertingFilters) {
      dispatch(campaignListActions.appliedFilterData([]));
      setStatFilters([])
    }
  }

  const reportMenus = [{ 
    label: "Reports",
    status: true,
    pages:[
      {
      label: "Search Terms Report",
      status: true,
      href:"/search-terms-report"
    },
  ]
  }]

  const handleGroupByChange = (e) => {
    setGroupBy(e.target.value)
  }

  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box-master-ads"
    >
      <Breadcrumb />
      <div className="campaign-list-container">
        <Loader visible={initialLoad} />
        <div>
          <FilterLogicModal
            show={allFiltersVisible.mainFilterLogicModal}
            preEnabledFilters={filterLogicModalEnabledFilters}
            specificFiltersKeys={allFilterKeys}
            filterFor={"master-search-term"}
            cancelSpecifiNumberFilter={cancelSpecifiNumberFilter}
          />
        </div>
        <div>
          {
            <MasterTagetinngActionAlertMModal
              alertText={actionAlertModalText}
              noclickhandler={noclickhandler}
              yesclickhandler={yesclickhandler}
              onYesClick={onYesClick}
            />
          }
        </div>
        <div>
          {allFiltersVisible.negativeTargetingModal && <AddToNegativeTargetingModal show={allFiltersVisible.negativeTargetingModal} onYesClick={addNegativeTargeting} defaultSelectedCampaigns={defaultSelectedAds} confirmationId={'add-to-negative-targeting-confirm'} onNoClick={showNegativeModal}/>}
        </div>
        <div
          className={
            selectedAds.length
              ? "et-filter-options-for-master-ads alignment-top"
              : "hide"
          }
        >
          <button className="selected-row-count mx-2">
            <img
              className="dash-icon"
              src={dashIcon}
              alt={"selectedIcon"}
              onClick={removeUpdatedCount}
            ></img>
            <span className="selected-text-with-count">
              {selectedAds && `${selectedAds?.length}  Selected`}
            </span>
          </button>
          <CampaignListTab
            onClickHandler={() => showNegativeModal("Enable")}
            classes={"mst-tab-dynamic-width"}
            imageSrc={settingIcon}
            imageText={"Add To Negative"}
          />
        </div>
        <Stack>
          <Stack
            direction="horizontal"
            className="d-flex align-item-center justify-content-between"
          >
            {/* <div className="d-flex gap-3"> */}
            <SectionTitle sectionTitleHeading={"Search Terms"} />
            <Stack className="d-flex align-item-start mx-4">
              <CustomDropDown
                title={""}
                filterCampaigns={getRecommendationFilter}
                targettingValue={
                  filters?.recommendation === ""
                    ? "Search Term Recommendation"
                    : filters?.recommendation
                }
                onDropdownImghandler={onDropdownImghandler}
                src={dropdownIcon}
                type={"targeting_type"}
                btnBgColor="#DBD9E6"
                borderRadius="0px 3px 3px 0px"
                menuZindex={100}
                noTitle={true}
                autoWidth={true}
                comingSoon={false}
                // selectDropDown={selectDropDown}
                // setSelectDropDown={setSelectDropDown}
                data={searchTermRecommendationsDropDown}
              />
            </Stack>
            {/* </div> */}

            {/* <Stack className="d-flex align-item-center justify-content-center"> */}
            <div className="filter-icon-container-mainmt-filter-gap filter-icon-container-main ">
              <div
                className={
                  minMaxFilterActivated ? "activated-min-max-filter" : "hide"
                }
              ></div>
              <img
                src={settingIcon}
                onClick={onFilterIconhandler}
                className="cursor-pointer-global mst-filterIcon"
                alt="FilterIcon"
                height={50}
              ></img>
            </div>
            {/* </Stack> */}
          </Stack>
          <div className="mt-config-row bottom-margin mt-3">
            <Stack className="mt-configs-btn-container d-flex flex-row">
              <Stack
                direction="horizontal"
                className="list-pages-search-input-container mb-1 search-bar-wrapper-ms"
              >
                <Stack
                  direction="vertical"
                  className="justify-content-end align-item-end"
                >
                  <SearchBarForCampaignListOnly
                    placeholder="Search for Terms"
                    // value={searchValues}
                    // onClickCancel={cancelIconInSearchInput}
                    searchBarForListPage={true}
                    // onChange={(e) => setSearchValues(e.target.value)}
                    onKeyUp={onSearchTermHandler}
                    isCancelIconNotVisible={true}
                    // onCancelIconClick={cancelIconInSearchInput}
                    // onKeyDown={onSearchBarEnterClicked}
                    id="ma-global-targeting-list"
                    className="mt-custom-search-bar"
                    dynamicWidth="search-input-width-for-listing-pages"
                  ></SearchBarForCampaignListOnly>
                </Stack>
              </Stack>
              <CustomDropDown
                targettingValue={groupBy}
                src={dropdownIcon}
                noTitle={isRecommendationFilterActive}
                filterCampaigns={handleGroupByChange}
                type={"group-by"}
                data={["Global", "Granular"]}
              />
              {!isRecommendationFilterActive && (
                <>
                  <div
                    className="mt-filter-gap mb-1 mt-filter-gap mb-1"
                    // direction="vertical"
                  >
                    <MultiSelectCustomDropDown
                      showCheckedAppliedFilteringWhenOPen={() =>
                        showCheckedAppliedFilteringWhenOPen(
                          "Campaign Type",
                          "mt-campaign_type",
                          "campaign_type"
                        )
                      }
                      checkedItemCount={campaignTypeFilterCount}
                      onDashIconHandlerToremoveAllCheckedOption={(
                        e,
                        targettingValue,
                        fieldName
                      ) =>
                        onDashIconHandlerToremoveAllCheckedOption(
                          e,
                          targettingValue,
                          fieldName,
                          "mt-campaign_type",
                          "campaign_type"
                        )
                      }
                      allSeletHandler={(e) =>
                        allSeletHandler(
                          e,
                          "Campaign Type",
                          "mt-campaign_type",
                          "campaign_type"
                        )
                      }
                      targettingValue={"Campaign Type"}
                      src={downArrow}
                      status={"campaign_type"}
                      fieldName={"mt-campaign_type"}
                      onApplyClickHandler={() =>
                        onApplyBtnhandler("campaign_type")
                      }
                      selectedOption={checkedDropDownFilterButtonData}
                      data={DropDownDataList}
                      onChange={(e) =>
                        onOptionCheckedHandlerInDropDownFilter(
                          e,
                          e.target.value,
                          "campaign_type",
                          "Campaign Type",
                          "mt-campaign_type"
                        )
                      }
                    />
                  </div>
                  <div
                    className="mt-filter-gap mb-1 mt-filter-gap mb-1"
                    // direction="vertical"
                  >
                    <MultiSelectCustomDropDown
                      showCheckedAppliedFilteringWhenOPen={() =>
                        showCheckedAppliedFilteringWhenOPen(
                          "Targeting Type",
                          "mt-match_type",
                          "match_type"
                        )
                      }
                      checkedItemCount={matchTypeFilterCount}
                      onDashIconHandlerToremoveAllCheckedOption={(
                        e,
                        targettingValue,
                        fieldName
                      ) =>
                        onDashIconHandlerToremoveAllCheckedOption(
                          e,
                          targettingValue,
                          fieldName,
                          "mt-match_type",
                          "match_type"
                        )
                      }
                      allSeletHandler={(e) =>
                        allSeletHandler(
                          e,
                          "Targeting Type",
                          "mt-match_type",
                          "match_type"
                        )
                      }
                      targettingValue={"Targeting Type"}
                      src={downArrow}
                      status={"match_type"}
                      fieldName={"mt-match_type"}
                      onApplyClickHandler={() =>
                        onApplyBtnhandler("match_type")
                      }
                      selectedOption={checkedDropDownFilterButtonData}
                      data={DropDownDataList}
                      onChange={(e) =>
                        onOptionCheckedHandlerInDropDownFilter(
                          e,
                          e.target.value,
                          "match_type",
                          "Targeting Type",
                          "mt-match_type"
                        )
                      }
                    />
                  </div>
                </>
              )}

              <Stack
                className="master-ads-calendar-container mb-1"
                direction="horizontal"
              >
                <Stack className="d-flex justify-content-end align-item-end d-none">
                  <button
                    className="date-container date-btn-campaignlist"
                    onClick={dateInputClickHandler}
                  >
                    <div className="d-flex justify-conntent-center">
                      <span className="date-btn-text">{dateDifference}</span>
                    </div>
                    <div className="btn-calender-img-holder">
                      <span className="">
                        <img
                          className="dropdown-img-for-select"
                          src={selectSideIcon}
                          alt={"SelecteSideIcon"}
                        ></img>

                        <img
                          className="calender-icon-for-campaignlist"
                          src={CalenderIconForCampaignList}
                          alt={"CalendarIconForCampaignList"}
                        ></img>
                      </span>
                    </div>
                  </button>
                </Stack>
                <div
                  ref={masterSearchTermCalendarRef}
                  className={
                    showCustomDateBox
                      ? "calender-masterads-container calender-mastersearch"
                      : "hide"
                  }
                >
                  {showCustomDateBox && (
                    <CalenderComponent
                      setShowCustomDateBox={setShowCustomDateBox}
                    ></CalenderComponent>
                  )}
                  <span className="calender-close-icon-campaignlist">
                    <img
                      onClick={() => setShowCustomDateBox(false)}
                      src={closeIcon}
                      alt={"closeIcon"}
                    ></img>
                  </span>
                </div>
              </Stack>
            </Stack>
          </div>
          <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center position-relative flex-row gap-3">
            <EditBadge
              text={"Converting"}
              handleClick={handleLeakingFilters}
              isSelected={isConvertingFilterPresent && isAllFiltersPresent}
            />
            <EditBadge
              text={"Leaking"}
              handleClick={handleLeakingFilters}
              isSelected={isLeakingFilterPresent && isAllFiltersPresent}
            />
            <DynamicFilterModal
              column1Heading={"Converting"}
              column2Heading={"Leaking"}
              columns={convertingColumns}
              percentageColumnRequired={true}
              getAppliedFilters={addToConvertingandLeaking}
              percentageColumns={leakingColumns}
              cancelFilterFor={""}
              dynamicIcon={<HiPencilAlt className="cursor-pointer" />}
              isDynamicIcon={true}
              saveFilter={true}
              buttonHeading={"Edit"}
              alignment={"left"}
            />
               
          </div>
          <div className="breadcrumb-container-master-search-term">
                <ReportsNavigator data={reportMenus}/>
          </div>
          </div>
        
          <Stack className={dataForChips?.length ? "pt-3" : "hide"}>
            <FilterChips
              filterChipList={dataForChips}
              onCancelClickRemoveFilteringHandler={(fieldNameForAPIFilter) =>
                onCancelClickRemoveFilteringHandlerr(fieldNameForAPIFilter)
              }
              onResetButtonClick={resetEverything}
              isResetButtonNeeded={true}
            />
          </Stack>
          <div className="search-term-table-wrapper mt-2">
            <Stack className="search-term-main-table">
              <TableRb
                noDataFound={
                  !tableDataToShow?.length &&
                  !allLoaders?.mainLoader &&
                  !initialLoad
                }
                tableHeads={customDataHeads}
                shimmer={allLoaders?.mainLoader}
                table={tableBody}
              />
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default MasterSearchTerm;
