import React from 'react'
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PrimaryBtnRB from '../../../Buttons/primaryBtnRB.js/primaryBtnRB';
import CreateAdGroup from '../../../modal/CampaignCreationPages/CreateAdGroup'
import AdDetails from '../../CampaignCreationPages/AdDetails';
import SDBrandAudienceAdGroup from '../../campaignAdGroupTables/SDBrandAudienceAdGroup';
import SDBrandProductAdGroupTable from '../../campaignAdGroupTables/SDBrandProductAdGroupTable';
import SDProductProspectAdGroupTable from '../../campaignAdGroupTables/SDProductProspectAdGroupTable'
import SpBrandProductAdGroupTable from '../../campaignAdGroupTables/SpBrandProductAdGroupTable';
import SpBrandKeywordAdGroupTable from '../../campaignAdGroupTables/SpBrandKeywordAdGroupTable';
import SpKeywordAdGroupTable from '../../campaignAdGroupTables/SpKeywordAdGroupTable'
import './adGroupModal.css';
import SpProductProspectAdGroupTable from '../../campaignAdGroupTables/SpProductProspectAdGroupTable';
import { useGlobalContext } from '../../../../context/global';
import { initialAdGroupData } from '../../../../constant/states/campaignCreation';
// import closeIcon from "../../../../assets/icons/close_dashlayout_icon.png";
import closeIcon from "../../../../assets/svgs/cross_icon.svg";
import Loader from '../../../loader';




const AdGroupModal = (props) => {

    // Common Variables and Destructuring
    const {targeting_objective,type,id} = props.data
    const {
      setKeywords,
      setBrandKeywordsToBeAdded,
      setKeywordsToBeShow,
      setSelectedProducts,
      setSpAsins,
      setSdAsins,
      setManuallyAddedAsins,
      isLoaderVisible,
      setSpSelectedSuggestedCategories,
      setAdsetAsins,
      setSpWholeCategories,
      setSdSelectedSuggestedCategories,
      setSdWholeCategories,
      setPage,
      setSpBrandSelectedSuggestedCategories,
      setSpBrandAsins,
      setSdBrandSelectedSuggestedCategories,
      setSdBrandAsins,
      setsdBrandWholeCategories,
      setspBrandWholeCategories,
      setBrandMatchTypes,
      setBrandKeywordsToBeShow,
      setmanualBrandMatchTypes,
      setManualMatchTypes,
      setKeywordsTobeAdded,
      setAdGroupData,
    } = useGlobalContext()


    // Table Bodies

    // Methods
    const handleClose = () => {
        props.setAdGroupModalVisible(false)
        setKeywords([]);
        setBrandKeywordsToBeAdded([]);
        setKeywordsTobeAdded([]);
        setKeywordsToBeShow([]);
        setSelectedProducts([]);
        setSpAsins([]);
        setSdAsins([]);
        setManuallyAddedAsins([]);
        setAdsetAsins([]);
        setSpSelectedSuggestedCategories([])
        setSpWholeCategories([]);
        setSdSelectedSuggestedCategories([]);
        setSdWholeCategories([]);
        setPage("");
        setSpBrandSelectedSuggestedCategories([]);
        setSpBrandAsins([]);
        setSdBrandSelectedSuggestedCategories([]);
        setSdBrandAsins([]);
        setsdBrandWholeCategories([]);
        setspBrandWholeCategories([]);
        setBrandMatchTypes([]);
        setBrandKeywordsToBeShow([]);
        setmanualBrandMatchTypes([]);
        setManualMatchTypes([]);
        setAdGroupData(initialAdGroupData)
    }

    useEffect(() => {
      setKeywords([]);
      setBrandKeywordsToBeAdded([]);
      setKeywordsTobeAdded([]);
      setKeywordsToBeShow([]);
      setSelectedProducts([]);
      setSpAsins([]);
      setSdAsins([]);
      setManuallyAddedAsins([]);
      setAdsetAsins([]);
      setSpSelectedSuggestedCategories([])
      setSpWholeCategories([]);
      setSdSelectedSuggestedCategories([]);
      setSdWholeCategories([]);
      setPage("");
      setSpBrandSelectedSuggestedCategories([]);
      setSpBrandAsins([]);
      setSdBrandSelectedSuggestedCategories([]);
      setSdBrandAsins([]);
      setsdBrandWholeCategories([]);
      setspBrandWholeCategories([]);
      setBrandMatchTypes([]);
      setBrandKeywordsToBeShow([]);
      setmanualBrandMatchTypes([]);
      setManualMatchTypes([]);
      setAdGroupData(initialAdGroupData)
    },[])


  return (
    <>
        <div className="modal-wrapper-adgroup-scroll">
            <Modal className="modal-wrapper-adgroup" show={props.adGroupModalVisible} onHide={handleClose}>
                <Modal.Body>
                  <Loader isVisible={isLoaderVisible}/>
                <div className="cursor-pointer-global" style={{paddingRight:"30px",paddingTop:"20px",position:"relative"}} >
                            <img src={closeIcon} onClick={handleClose} style={{width:"15px",position:"absolute",right:"5px"}} ></img>
                            </div>
                    <div className="sponsored-product-keyword-targeting create-new-adset-modal">

                            <CreateAdGroup data={props.data} update={"update"}/>
                            {
                              type === "SP-Auto" &&
                              targeting_objective.toLowerCase() === "prospect"
                              ? ""
                              : type === "SP-Keyword" &&
                                targeting_objective.toLowerCase() === "prospect"
                              ? <SpKeywordAdGroupTable/>
                              : type === "SP-Product" &&
                                targeting_objective.toLowerCase() === "prospect"
                              ? <SpProductProspectAdGroupTable/>
                              : type === "SD-Product" &&
                                targeting_objective.toLowerCase() === "prospect"
                              ? <SDProductProspectAdGroupTable/>
                              : type === "SP-Keyword" &&
                                targeting_objective.toLowerCase() === "retargeting"
                              ? <SpBrandKeywordAdGroupTable/>
                              : type === "SP-Product" &&
                                targeting_objective.toLowerCase() === "retargeting"
                              ? <SpBrandProductAdGroupTable/>
                              : type === "SD-Product" &&
                                targeting_objective.toLowerCase() === "retargeting"
                              ? <SDBrandProductAdGroupTable/>
                              : type === "SD-Audience" &&
                                targeting_objective.toLowerCase() === "retargeting"
                              ? <SDBrandAudienceAdGroup/>
                              : ""
                            }
                    </div>
                    <div className='sponsored-product-keyword-targeting ad-group-modal'>
                            <AdDetails/>
                    </div>
                    <div
            className={ "d-flex justify-content-end gap-10 keyword-targeting"
            }
            style={{ marginTop: "35px" }}
          >
            <button
              type="button"
              className="primary-outline-btn btn btn-outline-primary px-5"
              onClick={handleClose}
            >
              Go Back
            </button>
            <PrimaryBtnRB
              insideBtnText="Create AdGroup"
              id={"create-campaign-btn"}
              adGroupType={type}
              newCampaignId={id}
              className="width-100"
              setAdGroupModalVisible={props.setAdGroupModalVisible}
            ></PrimaryBtnRB>
          </div>
                </Modal.Body>
            </Modal>
        </div>
    </>
  )
}

export default AdGroupModal;