import React, { useEffect, useState, useRef } from "react";
import { Stack } from "react-bootstrap";
import moment from "moment/moment";
import LegendComponent from "../../components/legend/legend";
import SectionTitle from "../../components/sectionTitle";
import "./campaignList.css";
import settingIcon from "../../assets/icons/overviewSeiingIcon.svg";
import ascendingIconSvg from "../../assets/svgs/acendingIconSvg.svg";
import descendingIconSvg from "../../assets/svgs/descendingIcon.svg";
import { Search } from "react-bootstrap-icons";
import Spinner from "../../components/spinners/spinner";
import SecondaryBtnRb from "../../components/Buttons/secondaryBtnRb/secondaryBtnRb";
import TableRb from "../../components/Tables/tableRb";
import SwitchBtn from "../../components/Buttons/switchBtn";
import addIcon from "../../assets/svgs/plus_box.svg";
import lessIcon from "../../assets/svgs/minus_box.svg";
import editIcon from "../../assets/icons/edit.svg";
import cancelIcon from "../../assets/icons/cancelIcon.png";
// import downArrowIcon from "../assets/icons/arrow_down.png";
import consolidateData from "../../helper/CampaignList/consolidateData";
import sliceToCertainLength from "../../helper/common/sliceToCertainLength";
import {
  TOAST_POSITION_TOP_RIGHT,
  TOAST_SUCCESS,
  TOAST_DANGER
} from "../../constant/HardTypes";
import whiteFindIcon from '../../assets/icons/white_magnnifineIcon.png'
import checkCircle from "../../assets/svgs/check_circle.svg";
import { masterApi } from "../../apis/masterApi";
import useInitialLoad from '../../hooks/useInitialLoad'

import Loader from "../../components/loader";
import CalenderComponent from "../../components/calender/Calender";
import MasterTargetingSearchSelectModal from "../../components/modal/masterTargetinngActionnAlertMModal/search&select/MasterTargetingSearchSelectModal";
import { useDispatch, useSelector } from "react-redux";
import { campaignListActions } from "../../redux/slices/campaignList";
import ChoosePortfolio from "../../components/modal/CampaignCreationPages/ChoosePortfolio";
import pauseCircle from "../../assets/icons/pause_circle.svg";
import FilterLogicModal from "../../components/modal/campaignListModals/filterLogicModal/filterLogicModal";
import { useGlobalContext } from "../../context/global";
import redirect from "../../constant/Redirect";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import dropdownIcon from "../../assets/icons/downArrowForSplitButton.svg";
import { roundFigureNum } from "../../constant/roundFigureNum";
import AdGroupModal from "../../components/modal/campaignListModals/AdgroupModal/AdGroupModal";
import selectSideIcon from "../../assets/icons/select-btn-side-grey-icon.png";
import CalenderIconForCampaignList from "../../assets/icons/calender-icon-for-campaignlist.svg";
import closeIcon from "../../assets/icons/close_icon.png";
import SortBoxForHeaderOptions from "../../components/sortBoxForHeaderOptions/sortBoxForHeaderOptions";
import menuIcon from "../../assets/icons/menu-icon.png";
import dataParser from "../../helper/Graphs/CommonLineGraph/dataParser";
import CustomDropDown from "../../components/DropDown/CustomDropDown";
import { useAuthHeader } from "react-auth-kit";
import enablePauseFilter from "../../helper/CampaignList/enablePauseFilter";
import MasterTagetinngActionAlertMModal from "../../components/modal/masterTargetinngActionnAlertMModal/masterTargetingActionnAlertModal";
import LineColumnGraph from "../../components/Graphs/LineColumnGraph";
import MultiSelectCustomDropDown from "../../components/DropDown/MultiSelectCustomDropDown";
import customDropDownWithCheckboxDefaultDataArray from "../../constant/dropDownWIthCheckDefaultStateList";
import FilterChips from "../../components/FilterChips/FilterChips";
// import { onInputRangeChangeHandler } from "../../helper/CampaignList/filterByMetrices";
import SearchBarForCampaignListOnly from "../../components/searchBarForCampaignListOnly";
import { getCurrencySymbol } from "../../constant/defaultCurrencyConfigurations";
import Breadcrumb from '../../components/Breadcrumb/ReportsBreadCrumb';
import {toast} from 'react-toastify';
import RedirectPopup from "../../components/modal/redirectPopup";
import ReportsNavigator from "../../components/Reports/ReportsNavigator";
import {allConfirmationButtonsIds} from '../../constant/confirmActions'
const CampaignList = (props) => {
  const dispatch = useDispatch();
  const calenderIconRefForCampaignlist = useRef(null);
  const authHeader = useAuthHeader();
  const token = authHeader();
  const [cancelSpecifiNumberFilter, setCancelSpecifiNumberFilter] =
    useState(null);
  const btnNameForChipAccordingToFilteringFiledName = [
    {
      fieldName: "amz_targeting_type",
      btnNameAsPerFieldName: "Targeting Type",
    },
    { fieldName: "objective", btnNameAsPerFieldName: "Campaign Type" },
    { fieldName: "targeting_objective", btnNameAsPerFieldName: "Objective" },
    { fieldName: "spends", btnNameAsPerFieldName: "Spends" },
    { fieldName: "impressions", btnNameAsPerFieldName: "Impressions" },
    { fieldName: "clicks", btnNameAsPerFieldName: "Clicks" },
    { fieldName: "orders", btnNameAsPerFieldName: "Orders" },
    { fieldName: "sale_value", btnNameAsPerFieldName: "Revenue" },
    { fieldName: "cpm", btnNameAsPerFieldName: "CPM" },
    { fieldName: "ctr", btnNameAsPerFieldName: "CTR" },
    { fieldName: "cpc", btnNameAsPerFieldName: "CPC" },
    { fieldName: "cvr", btnNameAsPerFieldName: "CVR" },
    { fieldName: "aov", btnNameAsPerFieldName: "AOV" },
    { fieldName: "roas", btnNameAsPerFieldName: "ROAS" },
    { fieldName: "acos", btnNameAsPerFieldName: "ACOS" },
  ];
  // map Dtata
  // customDataMap = [1, 2, 3, 4];
  const customDataMap = [
    {
      title: "Campaign Type",
      type: "objective",
      data: ["All", "SP", "SD", "SB"],
    },
    {
      title: "",
      type: "",
      data: [""],
    },
    {
      title: "Objective",
      type: "targeting_objective",

      data: ["Both", "Prospect", "Retargeting"],
    },
    {
      title: "Targeting Type",
      type: "amz_targeting_type",
      data: ["All", "Keyword", "Product", "Automatic", "Audience"],
    },
  ];
  let totalNumberOfEnabledCampaign = [];
  let totalNumberOfPausedCampaign = [];
  const metricesWithTwoDecimalPlaces = [
    "cpm",
    "cpc",
    "roas",
    "acos",
    "ctr",
    "cvr",
    "dbr",
    "campaign_burn_rate"
  ];
  const [initialLoad,setInitialLoad] = useInitialLoad()
  const [totalEnabledCampaign, setTotalEnabledCampaign] = useState([]);
  const [totalPausedCampaign, setTotalPausedCampaign] = useState([]);
  const [adGroupModalVisible, setAdGroupModalVisible] = useState(false);
  const [currentCampaignData, setCurrentCampaignData] = useState({});
  const [isSortBoxVisible, setIsSortBoxVisible] = useState(true);
  const [selectedBtn, setselectedBtn] = useState("all-btn");
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const [showCustomDateBox, setShowCustomDateBox] = useState(false);
  const [searchfilteredData, setsearchfilteredData] = useState([]);
  const [consolidatedCampaigns, setConsolidatedCampaigns] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(9);
  const [currentSortingHead,setCurrentSortingHead] = useState("spends")
  const [minMaxFilterActivated, setMinMaxFilterActivated] = useState(false);
  const [showRedirectpopup,setShowRedirectpopup] = useState(false);

  const [DropDownDataList, setDropDownDataList] = useState(
    customDropDownWithCheckboxDefaultDataArray
  );
  const [
    multiSelectDropDownSelectedDataCount,
    setMultiSelectDropDownSelecteddDataCount,
  ] = useState({
    amz_targeting_type: 0,
    targeting_objective: 0,
    objective: 0,
  });
  //   const [minMaxFilterActivated, setMinMaxFilterActivated] = useState(false);

  const [allFetchLoaders, setAllFetchLoaders] = useState({
    mainPageLoader: false,
    campaignStatsLoader: false,
    campaignAdsetsLoader: false,
    campaignAdsetStatsLoader: false,
  });
  // const [enabledCampaignNumber, setEnabledCampaignNumber] = useState([]);
  const [campaignObjective, setCampaignObjective] = useState("Both");
  const [targettingType, setTargettingType] = useState("All Portfolios");

  const [amzTargetingType, setamzTargetingType] = useState("All");
  const [btnClickedForFilterCampaign, setisBtnClickedForFilterCampaign] =
    useState(false);
  const [campaignFiltering, setcampaignFiltering] = useState([]);
  const [campaignFilteringEncodedData, setCampaignFilteringEncodedData] =
    useState();
  const [initialConsolidatedCampaigns, setInitialConsolidatedCampaigns] =
    useState([]);
  const [bidStaretegyValue, setBidStaretegyValue] = useState("");
  const getPortfolio = useSelector(
    (state) => state.CampaignCreationData.getPortfolio
  );
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const [droDownFilterDataForApi, setDroDownFilterDataForApi] = useState([]);
  // let droDownFilterDataForApi  = []
  const editCampaignMainPageVisibilty = useSelector(
    (state) => state.campaignListData.editCampaignMainPageVisibilty
  );

  const currencySymbol = getCurrencySymbol();

  // const [inputText, setInputText] = useState("");setInputText
  const [
    targetingTypeSelectButtonOptionsVisibilty,
    setTargetingTypeSelectButtonOptionsVisibilty,
  ] = useState(false);
  const [
    objectiveTypeSelectButtonOptionsVisibilty,
    setObjectiveTypeSelectButtonOptionsVisibilty,
  ] = useState(false);
  const [
    amzTargetingTypeSelectButtonOptionsVisibilty,
    setAmzTargetingSelectButtonOptionsVisibilty,
  ] = useState(false);
  const [copyOfConsolidatedCampaigns, setCopyOfConsolidatedCampaigns] =
    useState(false);
  const editAdGroupSectionCurrentselectedBtnName = useSelector(
    (state) => state.campaignListData.editAdGroupSectionCurrentselectedBtnName
  );

  // const [modalToShow, setmodalToShow] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [dataHeads, setdataHeads] = useState([]);
  const [allFiltersVisible,setAllFilterVisible] = useState({
    searchSortModal:false
  })
  // dispatch(dashboardActions.sidebarButtonClickedName(selectedPageOp))
  const sidebarButtonClickedName = useSelector(
    (state) => state.dashboardData.sidebarButtonClickedName
  );

  const filterModalInCampaignListVisibilty = useSelector(
    (state) => state.campaignListData.filterModalInCampaignListVisibilty
  );
  const {
    calendarDate,
    setCalendarDate,
    applyCalendar,
    buttonStatus,
    setButtonStatus,
    dateDifference,
    setDateDifference,
    showAlert,
  } = useGlobalContext();
  const navigate = useNavigate();
  // const client_id = useSelector(State=> State.genralDataData.client_id)
  const client_id =
    JSON.parse(localStorage.getItem("clientId")) === null
      ? "aiekYz4hQy"
      : JSON.parse(localStorage.getItem("clientId"));
  const getUpdatedConfigHeadsForCampaignListSection = useSelector(
    (state) =>
      state.campaignListData.getUpdatedConfigHeadsForCampaignListSection
  );
  const [editBudgetValue, setEditBudgetValue] = useState({});
  const [showAlertMsgOf, setShowAlertMsgOf] = useState("");
  const confirmationId = allConfirmationButtonsIds["campaign-list"][showAlertMsgOf] ?? "cl-confirmation-modal"
  const [alertModalCauseAction, setAlertModalCauseAction] = useState(null);
  const [
    currentEditecCampaignDefaultDetail,
    setCurrentEditecCampaignDefaultDetail,
  ] = useState({});
  const [mainData, setMainData] = useState([]);
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const { campName } = params;
  const category_param = params?.category ?? ""
  const [campNameLinkFromMasterTargeting, setCampNameLinkFromMasterTargeting] =
    useState(campName ? campName : null);
  const [inputText, setInputText] = useState(campName ? campName : "");
  const [checkedDropDownFilterButtonData, setCheckedDropDownFilterButtonData] =
    useState([]);
  const [selectedCategory,setSelectedCategory] = useState(null);
  // const [inputText, setInputText] = useState("")
  // let domNode=useClickOutside(()=>{
  //   setOptionactive(false);
  // })Campaign Type
  useEffect(() => {
    getMainData();
    // const result = getTimePeriod(
    //   calendarDate[0].startDate,
    //   calendarDate[0].endDate
    // );
    // setDateDifference(result);
  }, [
    editCampaignMainPageVisibilty,
    editAdGroupSectionCurrentselectedBtnName,
    campaignFilteringEncodedData,
    applyCalendar,
  ]);
  //[editCampaignMainPageVisibilty,editAdGroupSectionCurrentselectedBtnName,campaignFilteringEncodedData, calendarDate,]

  async function fetchCampaignData() {
    const data = {
      level: "account",
      platform: "amazon",
      start_date: `${formatDateFn(moment().subtract(42, "days")._d)}`,
      end_date: `${formatDateFn(moment().subtract(1, "days")._d)}`,
      fields: `spends,campaign_type,sale_value,week `,
      sorting_order: "asc",
      time_increment: "week",
      order_by: "week",
      group_by: "campaign_type",
      // filtering: window.encodeURI(`[{"field_name":"campaign_type","operator":"IN","values":["${campaignData?.campaign_type}"]}]`),
    };
    const result = await masterApi(data);
    const returnedData = dataParser(result?.data?.data ?? []);
    setMainData(returnedData);
  }
  useEffect(() => {
    setIsFilterVisible(filterModalInCampaignListVisibilty);
  }, [filterModalInCampaignListVisibilty]);

  useEffect(() => {
    dispatch(campaignListActions.appliedFilterData([]));
  }, []);

  const appliedFilterData = useSelector(
    (state) => state.campaignListData.appliedFilterData
  );
  const inputHandler = (searchValue) => {
    if (campName) {
      searchParams.delete("campName");
      navigate("/campaign-list");
      setCampNameLinkFromMasterTargeting(null);
    }
    setInputText(searchValue);
  };

  const cancelIconInSearchInput = () => {
    inputHandler("");
  };

  const onFilterIconhandler = () => {
    dispatch(campaignListActions.showFilterModalInCampaignList());
  };

  const [campaignData, setCampaignData] = useState([]);
  const [campaignStats, setCampaignStats] = useState([]);
  const [adsetRecords, setAdsetRecords] = useState([]);
  const [adsetStats, setAdsetStats] = useState([]);
  const [categories,setCategories] = useState([]);





  const fetchCategories = async () => {
    try {
      const response = await fetch(
        `https://catalogapi.adyogi.com/client/${client_id}/categories?source=amazon&fields=name&limit=1000`
      );
      const result = await response.json();

      if(response?.status!==200) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: "Some Unknown Error Occured While Fetching Categories",
        // });
        toast.error('Some Unknown Error Occured While Fetching Categories')
      }
      if(result?.data?.length>0) {
        setCategories(result?.data ?? []);
      
      } else if(result?.data?.length === 0) {
        // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
        //   backgroundColor: TOAST_DANGER,
        //   title: "Error",
        //   description: "Categories Not Found",
        // });
        toast.error('Categories Not Found"')
      }
     
    } catch (error) {
      // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
      //   backgroundColor: TOAST_DANGER,  
      //   title: "Error",
      //   description: `Some Unknown Error Occured While Fetching Categories`,  
      // });
      toast.error('Some Unknown Error Occured While Fetching Categories')
    }
  };

  useEffect(() => {
    fetchCategories();
  },[])


  const [todayCampStats,setTodayCampStats] = useState([]);
  

  useEffect(() => {
    /**
     *@Note This useEffect will run when the raw data changes and filters will get changed.
     */
    // Data will be consolidated
    const newDataRecieved = [
      ...consolidateData(
        campaignData,
        adsetRecords,
        adsetStats,
        campaignStats,
        campNameLinkFromMasterTargeting && campNameLinkFromMasterTargeting,
        todayCampStats
      ),
    ];

    
    
    
    const searchedData = onSearch(inputText, newDataRecieved);
    // Data will go through filters
    const dataWithLogicFilters = onInputRangeChangeHandler(searchedData);

    // Data will go through enableDisable Sorting and will show Enable Data first
    const sortedData = sortByEnableDisable(dataWithLogicFilters);

    // Then Filtered Data will go through enable and paused filters
    const dataWithEnablePauseFilters = enablePauseFilter(
      selectedBtn === "enabled-btn"
        ? "ENABLED"
        : selectedBtn === "PAUSED"
          ? "PAUSED"
          : "All",
      sortedData
    );
    // It will set the enable and paused filters total count
    setTotalEnabledCampaign(
      sortedData
        .filter((campaign) => campaign.status === "ENABLED")
        .map((campaign) => campaign.status)
    );
    setTotalPausedCampaign(
      sortedData
        .filter((campaign) => campaign.status === "PAUSED")
        .map((campaign) => campaign.status)
    );
    // In the End it will set the state view layer State with enable paused filters.
      let finalData = dataWithEnablePauseFilters
      if(category_param) {
        finalData = dataWithEnablePauseFilters.filter((data) => {
          return data?.campaign_categories?.includes(category_param)
        })
      }
      if(selectedCategory !== null) {
        const searchProperties = selectedCategory.split(",").map(str => str.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
        const regex = new RegExp(`(?:^|,\\s*)(?:${searchProperties.join("|")})(?=,|$)`, "i");
        finalData = dataWithEnablePauseFilters.filter((data) => {
           return regex.test(data?.campaign_categories)
        });
      }
      // Sort Data in the end by By Current Sorting Head Dyanmic Function Now whenever sorting Heads will change these effect will be triggered
      const dataAfterSpendsSort = onSpendFilterClickHandler(currentSortingHead,currentIndex,finalData)
      
      setsearchfilteredData(dataAfterSpendsSort);
    if (campaignData?.length > 0) {
      if (isLoaderVisible) {
        setIsLoaderVisible(false);
        setInitialLoad(false)
      }
    }
    // setConsolidatedCampaigns(newDataRecieved);
    // checkedFilteringAndAddChips();
  }, [
    campaignData,
    campaignStats,
    todayCampStats,
    adsetStats,
    adsetRecords,
    selectedBtn,
    appliedFilterData,
    inputText,
    selectedCategory,
    currentSortingHead,
    isSortBoxVisible
  ]);
  

  useEffect(() => {
    // if (appliedFilterData.length) {
    let minMaxFilters = [];
    let listForChip = [];
    let arrayOfNumberFilteringFieldsForCampaignList = [
      { fieldName: "spends", btnNameAsPerFieldName: "Spends" },
      { fieldName: "impressions", btnNameAsPerFieldName: "Impressions" },
      { fieldName: "clicks", btnNameAsPerFieldName: "Clicks" },
      { fieldName: "orders", btnNameAsPerFieldName: "Orders" },
      { fieldName: "sale_value", btnNameAsPerFieldName: "Revenue" },
      { fieldName: "cpm", btnNameAsPerFieldName: "CPM" },
      { fieldName: "ctr", btnNameAsPerFieldName: "CTR" },
      { fieldName: "cpc", btnNameAsPerFieldName: "CPC" },
      { fieldName: "cvr", btnNameAsPerFieldName: "CVR" },
      { fieldName: "aov", btnNameAsPerFieldName: "AOV" },
      { fieldName: "roas", btnNameAsPerFieldName: "ROAS" },
      { fieldName: "acos", btnNameAsPerFieldName: "ACOS" },
    ];

    //in this we are checking filter data array with our default filters array & if match we are removing that data from our chip list array
    // this is beacuse we want chips to be only for filtering aarray which we are getting so we are cliaaring the array first and thenn aadding filter data to it
    arrayOfNumberFilteringFieldsForCampaignList.map((appliedData) => {
      droDownFilterDataForApi.map((chipData, index) => {
        if (appliedData.fieldName === chipData.field_name) {
          droDownFilterDataForApi.splice(index, 1);
          setDroDownFilterDataForApi((droDownFilterDataForApi) => [
            ...droDownFilterDataForApi,
          ]);
        }
      });
    });

    if (appliedFilterData.length) {
      // here we are checking if current filter data array which is (appliedFilterData) is haaving any numeric field to apply min max filter & if it their
      // then we are pushing that data to the list which created filter chips on front
      appliedFilterData.map((currentFil) => {
        if (
          currentFil.maxRange !== "" &&
          currentFil.maxRange !== null &&
          currentFil.maxRange != "-"
        ) {
          minMaxFilters.push({
            fieldNameOfSelectedBtn: currentFil.fieldName,
            // fieldNameOfSelectedBtn: currentFil.isAbbrivation === true ? currentFil.fieldName : currentFil.fieldName.toLowerCase().replace(/^\w/, c => c.toUpperCase()),
            field_name: currentFil.id,
            checkedValue: currentFil,
          });
        }
        if (
          currentFil.minRange !== "" &&
          currentFil.minRange !== null &&
          currentFil.minRange != "-" &&
          currentFil.minRange !== 0
        ) {
          minMaxFilters.push({
            fieldNameOfSelectedBtn: currentFil.fieldName,
            field_name: currentFil.id,
            checkedValue: currentFil,
          });
        }
      });

      //now we will create array of same field_name with different values( this case is only for min max numbers)
      //(this is beacuse for showing spends we have to same field name that is spends so we have to merge that data in one )
      const newArray = [];
      minMaxFilters.forEach((obj) => {
        let newObj = {
          fieldNameOfSelectedBtn: obj.fieldNameOfSelectedBtn,
          checkedValue: obj.checkedValue,
        };
        let foundIndex = newArray.findIndex(
          (x) => x.field_name === obj.field_name
        );
        if (foundIndex === -1) {
          newArray.push({
            field_name: obj.field_name,
            values: [newObj],
            fieldNameOfSelectedBtn: obj.fieldNameOfSelectedBtn,
          });
        } else {
          newArray[foundIndex].values.push(newObj);
        }
      });

      //now we will loop through created array for numbers and create required object with min & max key of same field_name & set it to main state list for chip list
      newArray.map((currObj) => {
        let minValueForCurrentObj = 0;
        let maxValueForCurrentObj = "Infinity";
        currObj.values.map((currentDaata) => {
          if (
            currentDaata.checkedValue.maxRange !== "-" &&
            currentDaata.checkedValue.maxRange !== null &&
            currentDaata.checkedValue.maxRange !== 0 &&
            currentDaata.checkedValue.maxRange !== ""
          ) {
            maxValueForCurrentObj = currentDaata.checkedValue.maxRange;
          }
          if (
            currentDaata.checkedValue.minRange !== "-" &&
            currentDaata.checkedValue.minRange !== null &&
            currentDaata.checkedValue.minRange !== 0 &&
            currentDaata.checkedValue.minRange !== ""
          ) {
            minValueForCurrentObj = currentDaata.checkedValue.minRange;
          }
        });
        if (
          (minValueForCurrentObj != 0 && maxValueForCurrentObj == "Infinity") ||
          (minValueForCurrentObj == 0 &&
            maxValueForCurrentObj !== "Infinity" &&
            maxValueForCurrentObj != 0) ||
          (minValueForCurrentObj != 0 && maxValueForCurrentObj != 0)
        ) {
          let nonnAbbrivaationFieldName = [
            "SPENDS",
            "IMPRESSIONS",
            "CLICKS",
            "ORDERS",
            "REVENUE",
          ];

          setDroDownFilterDataForApi((droDownFilterDataForApi) => [
            ...droDownFilterDataForApi,
            {
              field_name: currObj?.field_name,
              fieldNameOfSelectedBtn: nonnAbbrivaationFieldName.includes(
                currObj.fieldNameOfSelectedBtn
              )
                ? currObj.fieldNameOfSelectedBtn
                  .toLowerCase()
                  .replace(/^\w/, (c) => c.toUpperCase())
                : currObj.fieldNameOfSelectedBtn,
              minValue: minValueForCurrentObj,
              maxValue: maxValueForCurrentObj,
            },
          ]);
        }
      });
    }
    // }
  }, [appliedFilterData]);

  //this function will loop applied filtering & create filtering Chips Accordingly
  const checkedFilteringAndAddChips = () => {
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    // setDroDownFilterDataForApi([]);
    let multiselectDropdownFilteringFieldName = [];
    let listForChip = [];
    campaignFiltering.map((filList) => {
      droDownFilterDataForApi.map((listtData, index) => {
        if (listtData.field_name === filList.field_name) {
          droDownFilterDataForApi.splice(index, 1);
        }
      });
      btnNameForChipAccordingToFilteringFiledName.map((listToCheckType) => {
        if (filList.field_name === listToCheckType.fieldName) {
          setDroDownFilterDataForApi((droDownFilterDataForApi) => [
            ...droDownFilterDataForApi,
            {
              fieldNameOfSelectedBtn: listToCheckType.btnNameAsPerFieldName,
              field_name: filList.field_name,
              checkedValue: filList.values,
            },
          ]);
        }
      });
    });

    //this loop will show count of selected value of drodpdown data in dropdown filtering data
    campaignFiltering.map((filList) => {
      DropDownDataList.map((dropDownnData) => {
        if (
          dropDownnData.apiFieldNameForFilteringRequest === filList.field_name
        ) {
          filList.values.map((mainValue) => {
            if (mainValue === dropDownnData.name) {
              setMultiSelectDropDownSelecteddDataCount({
                ...multiSelectDropDownSelectedDataCount,
                [filList.field_name]: filList.values.length,
              });
            }
          });
        }
      });
    });
  };

  const onSearch = (text, initialData) => {
    const returnedData = initialData.filter((campaign) => {
      if (text === "") return campaign;
      else {
        if (campNameLinkFromMasterTargeting) {
          return campaign?.name?.toLowerCase() === text.toLowerCase();
        } else {
          return campaign?.name?.toLowerCase()?.includes(text?.toLowerCase());
        }
      }
    });
    return returnedData;
  };

  const sortByEnableDisable = (campaignArray) => {
    const sortedArray = campaignArray.sort((a, b) => {
      let x = a.status === "ENABLED" && 0;
      let y = b.status === "PAUSED" && 1;
      return x - y;
    });
    return sortedArray;
  };

  const onSearchModalHandler = (input) => {
    setAllFilterVisible({ ...allFiltersVisible, searchSortModal: true });
  };


  useEffect(() => {
    document.addEventListener("mousedown", closeCalender);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", closeCalender);
    };
  }, [showCustomDateBox]);

  useEffect(() => {
    setdataHeads(getUpdatedConfigHeadsForCampaignListSection);
  }, [getUpdatedConfigHeadsForCampaignListSection]);


  const formatDateFn = (date) => {
    const selectedDate = new Date(date);
    const formattedDate =
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear();
    return (
      selectedDate.getDate() +
      "-" +
      parseInt(selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear()
    );
  };

  useEffect(() => {
    // if (campaignFiltering.length) {
    setCampaignFilteringEncodedData(encodeData(campaignFiltering));
    // }
  }, [campaignFiltering, btnClickedForFilterCampaign, applyCalendar]);//[campaignFiltering, btnClickedForFilterCampaign, calendarDate]

  useEffect(() => {
    fetchCampaignData();
  }, []);

  async function getMainData() {
    try {
      const dataForCampaignList = {
        source: "amazon",
        limit: 5000,
      };
      const data = {
        source: "amazon",
        limit: 5000,
        fields:"campaign_id,name,status,source,type,platform_id,platform_status,platform_last_updated_at,tenant_id,account_id,amz_default_bid,id"
      };
      const data1 = {
        level: "campaign",
        platform: "amazon",
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        fields:
          "campaign_id,adset_id,spends,roas,clicks,sale_value,clicks,impressions,cpc,cvr,aov,acos,cpm,orders,ctr",
        group_by: "adset_id",
        limit: 5000,
      };
      const dataForCampaignStat = {
        level: "account",
        platform: "amazon",
        start_date: `${formatDateFn(calendarDate[0].startDate)}`,
        end_date: `${formatDateFn(calendarDate[0].endDate)}`,
        fields:
          "campaign_id,spends,roas,clicks,sale_value,clicks,impressions,cpc,cvr,aov,acos,cpm,orders,ctr,campaign_categories,campaign_burn_rate",
        group_by: "campaign_id",
        limit: 5000,
      };
      const dataForTodaysCampaignStat = {
        level:"account",
        platform:'amazon',
        start_date: `${formatDateFn(new Date())}`,
        end_date: `${formatDateFn(new Date())}`,
        fields:"campaign_id,spends,campaign_burn_rate",
        group_by:"campaign_id",
        limit:5000
      }
      const dataForFilters = {
        source: "amazon",
        filtering: campaignFilteringEncodedData,
      };
      setIsLoaderVisible(true);

      const campaignData = await masterApi(
        campaignFiltering.length ? dataForFilters : dataForCampaignList,
        "campaignList",
        false
      );
      setCampaignData(campaignData?.data?.data ?? []);
      checkedFilteringAndAddChips();
      const todayCampaignStats = await masterApi(dataForTodaysCampaignStat)
      setTodayCampStats([...(todayCampaignStats?.data?.data ?? [])]) ;
      // setIsLoaderVisible(false);
      setAllFetchLoaders({
        ...allFetchLoaders,
        campaignStatsLoader: true,
        campaignAdsetsLoader: true,
      });
      // setIsLoaderVisible(false);
      const campaignStatsModified = await masterApi(dataForCampaignStat);
      setCampaignStats([...(campaignStatsModified?.data?.data ?? [])]);
      setAllFetchLoaders({
        ...allFetchLoaders,
        campaignStatsLoader: false,
        campaignAdsetsLoader: true,
      });
      const adsetdata = await masterApi(data, "adsetList", false);
      setAdsetRecords([...(adsetdata?.data?.data ?? [])]);
      setAllFetchLoaders({
        ...allFetchLoaders,
        campaignStatsLoader: false,
        campaignAdsetsLoader: false,
      });
      const adsetStats = await masterApi(data1, "adsetStats", true);
      setAdsetStats(adsetStats?.data?.data ?? []);
    } catch (error) {
      setIsLoaderVisible(false);
    }
  }

  async function getCampaignList() {
    try {
      const dataForCampaignList = {
        source: "amazon",
        limit: 5000,
      };

      const dataForFilters = {
        source: "amazon",
        filtering: campaignFilteringEncodedData,
      };

      const campaignData = await masterApi(
        campaignFiltering.length ? dataForFilters : dataForCampaignList,
        "campaignList",
        false
      );
      setCampaignData(campaignData?.data?.data ?? []);
    } catch (error) {
      console.log(error);
    }
  }

  async function getAdsetsList() {
    try {
      const data = {
        source: "amazon",
        limit: 5000,
      };
      const adsetdata = await masterApi(data, "adsetList", false);
      setAdsetRecords([...(adsetdata?.data?.data ?? [])]);
    } catch (error) {
      console.log(error);
    }
  }

  const updateModifiedCampaignToServer = (updatedCampaign, fieldsToUpdate) => {
    var myHeaders = {
      "Content-Type": "application/merge-patch+json",
      Authorization: token,
    };
    let preRaw = {};
    if (fieldsToUpdate.indexOf("daily_budget") != -1) {
      preRaw.daily_budget = updatedCampaign.daily_budget;
    }
    if (fieldsToUpdate.indexOf("name") != -1) {
      preRaw.name = updatedCampaign.name;
    }
    if (fieldsToUpdate.indexOf("status") != -1) {
      preRaw.status = updatedCampaign.status;
    }
    if (fieldsToUpdate.indexOf("bidding") != -1) {
      preRaw.bidding = updatedCampaign.bidding;
    }
    var raw = JSON.stringify(preRaw);
    let toastMessage =
      fieldsToUpdate[0] === "status"
        ? "Campaign Status"
        : fieldsToUpdate[0] === "name"
          ? "Campaign Name"
          : fieldsToUpdate[0] === "daily_budget"
            ? "Daily Budget"
            : "Bidding Strategy";

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      `https://adyogiadsapi.adyogi.com/client/${client_id}/campaigns/${updatedCampaign.id}?source=amazon`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          getCampaignList();
          // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          //   backgroundColor: TOAST_SUCCESS,
          //   title: "Success",
          //   description: `${toastMessage} Updated Successfully`,
          // });
          toast.success(`${toastMessage} Updated Successfully`)
        }
      })
      .catch((error) => {
        setIsLoaderVisible(false);
      });
  };

  const encodeData = (data) => {
    data = JSON.stringify(data);
    let encodedData = encodeURIComponent(data);
    return encodedData;
  };

  const removePreviosSameFilterObject = (array, field_name) => {
    if (array.length) {
      for (let index = 0; index < array.length; index++) {
        if (array[index].field_name && array[index].field_name === field_name) {
          array.splice(index, 1);
        }
      }
    }
  };

  const filterCampaigns = (e, type) => {
    removePreviosSameFilterObject(campaignFiltering, type);
    if (type === "amz_portfolio_id") {
      for (let index = 0; index < getPortfolio.length; index++) {
        if (getPortfolio[index]?.id === e?.target?.value) {
          setcampaignFiltering((campaignFiltering) => [
            ...campaignFiltering,
            {
              field_name: type,
              operator: "EQUALS",
              value: getPortfolio[index].platform_id,
            },
          ]);
        }
      }
    }

    if (type === "amz_portfolio_id" && e.target.value === "All Portfolios") {
      removePreviosSameFilterObject(campaignFiltering, type);
      setcampaignFiltering((campaignFiltering) => [...campaignFiltering]);
    }
    if (type === "targeting_objective" && e.target.value === "Both") {
      removePreviosSameFilterObject(campaignFiltering, type);
      setCampaignObjective(e.target.value);
      setcampaignFiltering((campaignFiltering) => [...campaignFiltering]);
      setObjectiveTypeSelectButtonOptionsVisibilty(
        !objectiveTypeSelectButtonOptionsVisibilty
      );
    } else if (type === "objective" && e.target.value === "All") {
      removePreviosSameFilterObject(campaignFiltering, type);
      setisBtnClickedForFilterCampaign(false);
      setTargettingType(e.target.value);
      setTargetingTypeSelectButtonOptionsVisibilty(
        !targetingTypeSelectButtonOptionsVisibilty
      );
      setcampaignFiltering((campaignFiltering) => [...campaignFiltering]);
    } else if (type === "amz_targeting_type" && e.target.value === "All") {
      removePreviosSameFilterObject(campaignFiltering, type);
      setisBtnClickedForFilterCampaign(false);
      setamzTargetingType(e.target.value);
      setAmzTargetingSelectButtonOptionsVisibilty(
        !amzTargetingTypeSelectButtonOptionsVisibilty
      );
      setcampaignFiltering((campaignFiltering) => [...campaignFiltering]);
    } else if (type === "status" && e === "All") {
      removePreviosSameFilterObject(campaignFiltering, type);
      setcampaignFiltering((campaignFiltering) => [...campaignFiltering]);
    } else {
      if (type === "targeting_objective") {
        setCampaignObjective(e.target.value);
        setcampaignFiltering((campaignFiltering) => [
          ...campaignFiltering,
          { field_name: type, operator: "EQUALS", value: e.target.value },
        ]);
        setObjectiveTypeSelectButtonOptionsVisibilty(
          !objectiveTypeSelectButtonOptionsVisibilty
        );
      }
      if (type === "objective") {
        setTargetingTypeSelectButtonOptionsVisibilty(
          !targetingTypeSelectButtonOptionsVisibilty
        );
        setTargettingType(e.target.value);
        setcampaignFiltering((campaignFiltering) => [
          ...campaignFiltering,
          { field_name: type, operator: "EQUALS", value: e.target.value },
        ]);
      }
      if (type === "amz_targeting_type") {
        setamzTargetingType(e.target.value);
        setAmzTargetingSelectButtonOptionsVisibilty(
          !amzTargetingTypeSelectButtonOptionsVisibilty
        );
        setcampaignFiltering((campaignFiltering) => [
          ...campaignFiltering,
          { field_name: type, operator: "EQUALS", value: e.target.value },
        ]);
      }
      if (type === "ENABLED") {
        setcampaignFiltering((campaignFiltering) => [
          ...campaignFiltering,
          { field_name: type, operator: "EQUALS", value: e.target.id },
        ]);
      }
      if (type === "status") {
        setcampaignFiltering((campaignFiltering) => [
          ...campaignFiltering,
          { field_name: type, operator: "EQUALS", value: e },
        ]);
      }
      setisBtnClickedForFilterCampaign(true);
    }
  };

  // onclick this will show & hide the adset tablein table
  const onInputRangeChangeHandler = (initialData) => {
    setMinMaxFilterActivated(false);
    let tempCampaigns = JSON.stringify(initialData);
    tempCampaigns = JSON.parse(tempCampaigns);

    let filteredCampaigns = [];
    let filterWithDashAsZero = [];

    for (let i = 0; i < appliedFilterData.length; i++) {
      let temp = JSON.parse(JSON.stringify(appliedFilterData[i]));
      temp.minRange = isNaN(temp.minRange) ? 0 : Number(temp.minRange);
      temp.maxRange =
        temp.maxRange == "" || isNaN(temp.maxRange)
          ? Infinity
          : Number(temp.maxRange);

      if (
        (temp.minRange != 0 && temp.maxRange != Infinity) ||
        (temp.minRange == 0 && temp.maxRange != Infinity)
      ) {
        filterWithDashAsZero.push(temp);
      } else if (
        (temp.minRange != 0 && temp.maxRange == Infinity) ||
        (temp.minRange != 0 && temp.maxRange == "")
      ) {
        filterWithDashAsZero.push(temp);
      }
    }
    for (let r = 0; r < tempCampaigns.length; r++) {
      let shouldPass = true;
      for (let i = 0; i < filterWithDashAsZero.length; i++) {
        let currentFilter = filterWithDashAsZero[i];
        if (
          (currentFilter.minRange != 0 && currentFilter.maxRange != Infinity) ||
          (currentFilter.minRange == 0 && currentFilter.maxRange != Infinity) ||
          (currentFilter.minRange != 0 && currentFilter.maxRange == Infinity)
        ) {
          setMinMaxFilterActivated(true);
          let currentCampaign = tempCampaigns[r];
          if (!currentCampaign.campaignStat.length) {
            shouldPass = false;
          }
          for (let m = 0; m < currentCampaign.campaignStat.length; m++) {
            if (
              currentCampaign.campaignStat[m][currentFilter.id] &&
              currentCampaign.campaignStat[m][currentFilter.id] !== null
            ) {
              if (
                currentCampaign.campaignStat[m][currentFilter.id] <
                currentFilter.minRange ||
                (currentFilter.maxRange !== Infinity &&
                  currentCampaign.campaignStat[m][currentFilter.id] >
                  currentFilter.maxRange)
              ) {
                shouldPass = false;
              }
            } else {
              shouldPass = false;
            }
          }
        } else {
          shouldPass = false;
        }
      }
      if (shouldPass) {
        filteredCampaigns.push(tempCampaigns[r]);
      }
    }
    return filteredCampaigns;
  };

  const updateAdsetOnServer = (campaignId, adSetInstance, fieldsToUpdate) => {
    var myHeaders = {
      "Content-Type": "application/merge-patch+json",
      Authorization: token,
      Authorization: token,
    };
    let preRaw = {};
    if (fieldsToUpdate.indexOf("name") != -1) {
      preRaw.name = adSetInstance.name;
    }
    if (fieldsToUpdate.indexOf("status") != -1) {
      preRaw.status = adSetInstance.status;
    }
    var raw = JSON.stringify(preRaw);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    let toastMessage =
      fieldsToUpdate[0] === "name" ? "Adset Name" : "Adset Status";

    fetch(
      `https://adyogiadsapi.adyogi.com/client/${client_id}/campaigns/${campaignId}/adsets/${adSetInstance.id}?source=amazon`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success)
          // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
          //   backgroundColor: TOAST_SUCCESS,
          //   title: "Success",
          //   description: `${toastMessage} Updated Successfully`,
          // });
          toast.success(`${toastMessage} Updated Successfully`)
        getAdsetsList();
      })
      .catch((error) => {
        setIsLoaderVisible(false);
      });
  };

  //this will diable input editin field when user done writing new campaign set name
  // const addCampInputChekCircleHandler = (campaignId, adSetId) => {
  //   const foundedAdset = adsetRecords.filter((record) => record.id === adSetId )
  //   for (let i = 0; i < searchfilteredData.length; i++) {
  //     if (searchfilteredData[i].id === campaignId) {
  //       for (let j = 0; j < searchfilteredData[i].adsets.length; j++) {
  //         if (searchfilteredData[i].adsets[j].id === adSetId) {
  //           if (
  //             consolidatedCampaigns[i].adsets[j].name !=
  //             initialConsolidatedCampaigns[i].adsets[j].name
  //           ) {
  //             updateAdsetOnServer(
  //               campaignId,
  //               foundedAdset[0],
  //               ["name"]
  //             );
  //             setInitialConsolidatedCampaigns(consolidatedCampaigns);
  //           }
  //           consolidatedCampaigns[i].adsets[
  //             j
  //           ].iseditAdsetNameValueDisabled = false;
  //           let tempConsolidatedCampaigns = JSON.stringify(
  //             consolidatedCampaigns
  //           );
  //           tempConsolidatedCampaigns = JSON.parse(tempConsolidatedCampaigns);
  //           setConsolidatedCampaigns(tempConsolidatedCampaigns);
  //           setCopyOfConsolidatedCampaigns(tempConsolidatedCampaigns);
  //         }
  //       }
  //     }
  //   }
  //   //setEditAdsetName(false);
  // };

  const addCampInputChekCircleHandler = (campaignId, adsetId, type) => {
    let newAdset;
    searchfilteredData.map((campaign) => {
      return campaign.adsets.filter((adset) => {
        if (adset.id === adsetId) {
          newAdset = adset;
        }
        return adset;
      });
    });

    dispatch(campaignListActions.showActionAlertModal());
    setShowAlertMsgOf("Are you sure you want to Update Adset Name");
    setAlertModalCauseAction({
      alertCauseActionType: type,
      campaignId: campaignId,
      adsetId: adsetId,
      newAdset: newAdset,
      fieldToUpdate: ["name"],
    });
  };

  const editCampaignPencilIconClickHandler = (clickedAdsetDetails) => {

    dispatch(
      campaignListActions.editBtnclickedCampaignDetailsForEditTargetingTable(
        clickedAdsetDetails
      )
    );


    dispatch(campaignListActions?.ShowEditCmapignMainPageOnGobackBtnClikced());
    navigate(
      `/edit-adset-targeting?campaignId=${clickedAdsetDetails.adset.campaign_id}&adsetId=${clickedAdsetDetails.adset.id}&numericCampaignId=${clickedAdsetDetails.campaign_id}&numericAdsetId=${clickedAdsetDetails.adset_id}&name=${clickedAdsetDetails?.adset?.name}&campName=${clickedAdsetDetails.currentCampaign.name}&adsetPlatformId=${clickedAdsetDetails?.adset?.platform_id}`
    );
  };

  const onButtonClickHandler = (e, type, value) => {
    setselectedBtn(e.target.id);
    // filterCampaigns(value, type);
  };

  const dateInputClickHandler = (e) => {
    setShowCustomDateBox(!showCustomDateBox);
  };

  const checkCampaignsForChanges = (type, id) => {
    const findedCampaign = searchfilteredData.filter(
      (campaign) => campaign.id === id
    );
    if (type === "BudgetPencil") {
      updateModifiedCampaignToServer(findedCampaign[0], ["daily_budget"]);
      setsearchfilteredData(searchfilteredData);
    }
    if (type === "EditCampaignNamePencil") {
      updateModifiedCampaignToServer(findedCampaign[0], ["name"]);
      setsearchfilteredData(searchfilteredData);
    }
  };

  const onDropdownImghandler = (type) => {
    switch (type) {
      case "targeting_objective":
        setObjectiveTypeSelectButtonOptionsVisibilty(
          !objectiveTypeSelectButtonOptionsVisibilty
        );
        break;
      case "objective":
        setTargetingTypeSelectButtonOptionsVisibilty(
          !targetingTypeSelectButtonOptionsVisibilty
        );
        break;
      case "amz_targeting_type":
        setAmzTargetingSelectButtonOptionsVisibilty(
          !amzTargetingTypeSelectButtonOptionsVisibilty
        );
        break;
      default:
        break;
    }
  };

  const NoClickHandler = () => {
    if (
      alertModalCauseAction.alertCauseActionType === "EditCampaignNamePencil"
    ) {
      const NoClickedCampaign = searchfilteredData.filter(
        (campaign) => campaign.id === alertModalCauseAction.id
      );
      setInputValue(
        "",
        alertModalCauseAction.id,
        NoClickedCampaign[0].campaignNameCopy,
        "campaignNameInput"
      );
    }
    if (alertModalCauseAction.alertCauseActionType === "BudgetPencil") {
      const NoClickedCampaign = searchfilteredData.filter(
        (campaign) => campaign.id === alertModalCauseAction.id
      );
      let dailyBudgetInitialValurWithINR =
        NoClickedCampaign[0].dailyBudgetCopy + `${currencySymbol}`;
      setInputValue(
        "",
        alertModalCauseAction.id,
        dailyBudgetInitialValurWithINR,
        "campaignBudgetInput"
      );
    }
    if (
      alertModalCauseAction.alertCauseActionType === "EditBiddingFieldonChange"
    ) {
      const NoClickedCampaign = searchfilteredData.filter(
        (campaign) =>
          campaign.id === alertModalCauseAction.campaignInstanceToUpdate.id
      );

      onChangeBidStrtegyhandler(
        alertModalCauseAction.campaignInstanceToUpdate.id,
        NoClickedCampaign[0].biddingStategyCopy.bidding_strategy,
        "EditBiddingFieldonChange"
      );
    }

    if (
      alertModalCauseAction.alertCauseActionType ===
      "EditCampaignAdsetNameOnServerPencil"
    ) {
      addCampCancelCircleHandler(
        alertModalCauseAction.campaignId,
        alertModalCauseAction.newAdset.id
      );
    }
    if (alertModalCauseAction.alertCauseActionType === "enablePauseAdset") {
      onAdsetStatusChangeHandler(
        alertModalCauseAction.campId,
        alertModalCauseAction.adsetId
      );
      //   dispatch(campaignListActions.hideActionAlertModal());
    }
    if (alertModalCauseAction.alertCauseActionType === "campaignStatus") {
      onSwitchBtnHandler(
        alertModalCauseAction.id,
        alertModalCauseAction.checkedStatus,
        false,
        false
      );
      //   dispatch(campaignListActions.hideActionAlertModal());
    }
    dispatch(campaignListActions.hideActionAlertModal());
  };

  const onYesClickHandler = () => {
    if (
      alertModalCauseAction.alertCauseActionType === "EditCampaignNamePencil"
    ) {
      checkCampaignsForChanges(
        "EditCampaignNamePencil",
        alertModalCauseAction.id
      );
    }
    if (alertModalCauseAction.alertCauseActionType === "BudgetPencil") {
      updateModifiedCampaignToServer(
        alertModalCauseAction.campaignNeedToUpdate,
        ["daily_budget"]
      );
    }
    if (
      alertModalCauseAction.alertCauseActionType === "EditBiddingFieldonChange"
    ) {
      updateModifiedCampaignToServer(
        alertModalCauseAction.campaignInstanceToUpdate,
        alertModalCauseAction.bidding
      );
    }
    if (
      alertModalCauseAction.alertCauseActionType ==
      "EditCampaignAdsetNameOnServerPencil"
    ) {
      updateAdsetOnServer(
        alertModalCauseAction.campaignId,
        alertModalCauseAction.newAdset,
        alertModalCauseAction.fieldToUpdate
      );
    }
    if (alertModalCauseAction.alertCauseActionType === "enablePauseAdset") {
      updateAdsetOnServer(
        alertModalCauseAction.campId,
        alertModalCauseAction.status,
        alertModalCauseAction.fieldToUpdate
      );
    }
    if (alertModalCauseAction.alertCauseActionType === "campaignStatus") {
      onSwitchBtnHandler(
        alertModalCauseAction.id,
        alertModalCauseAction.checkedStatus === "ENABLED"
          ? "PAUSED"
          : "ENABLED",
        true,
        false
      );
    }
    dispatch(campaignListActions.hideActionAlertModal());
  };

  const campaignEditClickHandler = (id, type, adsetid, editState) => {
    let tempCampaigns = JSON.stringify(searchfilteredData);
    tempCampaigns = JSON.parse(tempCampaigns);

    switch (type) {
      case "BudgetPencil":
        let tempCampaigns = searchfilteredData.map((a) => {
          return { ...a };
        });
        tempCampaigns.map((campaignInstance) => {
          if (campaignInstance.id === id) {
            if (campaignInstance.daily_budget > 99) {
              if (!editState) {
                let campaignInsCopy = campaignInstance;
                dispatch(campaignListActions.showActionAlertModal());
                setShowAlertMsgOf("Are you sure you want to Change Budget");
                setAlertModalCauseAction({
                  alertCauseActionType: "BudgetPencil",
                  id: id,
                  campaignNeedToUpdate: campaignInsCopy,
                });
              }
            }
          }
        });
        break;
      case "EditCampaignNamePencil":
        if (!editState) {
          dispatch(campaignListActions.showActionAlertModal());
          setShowAlertMsgOf("Are you sure you want to Change Campaign Name");
          setAlertModalCauseAction({
            alertCauseActionType: "EditCampaignNamePencil",
            id: id,
          });
        }
        break;
      case "EditBiddingField":
        checkCampaignsForChanges("EditBiddingField", id);
        break;
      default:
        break;
    }
    tempCampaigns.map((campaignInstance) => {
      if (campaignInstance.id == id) {
        if (type === "BudgetPencil") {
          if (campaignInstance.id === id) {
            if (!campaignInstance.iseditBudgetValueDisabled) {
              if (campaignInstance.id === id) {
                if (campaignInstance.daily_budget >= 100) {
                  campaignInstance.iseditBudgetValueDisabled = true;
                  campaignInstance.daily_budget =
                    campaignInstance.daily_budget + `${currencySymbol}`;
                  return;
                } else {
                  alert("Please Select a Value Above 100 For DAILY BUDGET");
                }
              }
            }
            if (campaignInstance.id === id) {
              let indexOfINRText = campaignInstance.daily_budget.indexOf(`${currencySymbol}`);
              campaignInstance.daily_budget =
                campaignInstance.daily_budget.slice(0, indexOfINRText - 1);
              campaignInstance.iseditBudgetValueDisabled = false;
            }
          }
        }
        if (type === "EditCampaignNamePencil") {
          if (!campaignInstance.isEditCampaignNameDiabled) {
            campaignInstance.isEditCampaignNameDiabled = true;
            return;
          }
          if (campaignInstance.id === id) {
            campaignInstance.isEditCampaignNameDiabled = false;
          }
        }
        if (type === "EditCampaignAdsetPencil") {
          tempCampaigns.forEach((campaignInstance) => {
            campaignInstance.adsets.forEach((adset) => {
              adset.iseditAdsetNameValueDisabled = false;
              if (campaignInstance.id === id && adset.id === adsetid) {
                adset.iseditAdsetNameValueDisabled = true;
              }
            });
          });
        }
        if (type === "PlusIconHandler") {
          if (!campaignInstance.isPlusIconDisabled) {
            campaignInstance.isPlusIconDisabled = true;
            return;
          }
          if (campaignInstance.id === id) {
            campaignInstance.isPlusIconDisabled = false;
          }
        }
      }
    });
    setsearchfilteredData(tempCampaigns);
  };

  const onChangeBidStrtegyhandler = async (id, updatedValue, type) => {
    let tempCampaigns = JSON.stringify(searchfilteredData);
    let campaignInstanceToUpdate = undefined;
    tempCampaigns = JSON.parse(tempCampaigns);

    for (let i = 0; i < tempCampaigns.length; i++) {
      if (tempCampaigns[i].id === id) {
        tempCampaigns[i].bidding.bidding_strategy = updatedValue;
        setBidStaretegyValue(updatedValue);
        campaignInstanceToUpdate = tempCampaigns[i];
      }
    }
    setsearchfilteredData(tempCampaigns);

    if (campaignInstanceToUpdate) {
      // if (!editState) {
      dispatch(campaignListActions.showActionAlertModal());
      setShowAlertMsgOf("Are you sure you want to Change Bidding Strategy");
      setAlertModalCauseAction({
        alertCauseActionType: type,
        campaignInstanceToUpdate: campaignInstanceToUpdate,
        bidding: ["bidding"],
      });
      //   }
      //   updateModifiedCampaignToServer(campaignInstanceToUpdate, ["bidding"]);
    }
  };

  const addCampCancelCircleHandler = (campaignId, adsetId) => {
    let tempCampaigns = JSON.parse(JSON.stringify(searchfilteredData));
    let updatedTempCampaigns = tempCampaigns.map((eachCampaign) => {
      if (eachCampaign.id === campaignId) {
        eachCampaign.adsets.map((adset) => {
          if (adset.id === adsetId) {
            adset.iseditAdsetNameValueDisabled =
              !adset.iseditAdsetNameValueDisabled;
            adset.name = adset.adsetNameCopy;
          }
          return adset;
        });
      }
      return eachCampaign;
    });
    setsearchfilteredData(updatedTempCampaigns);
  };

  const setInputValue = (e, id, updatedValue, type) => {
    // setCurrentEditecCampaignDefaultDetail({
    //   campaignDefaultName: e.target.defaultValue,
    // });
    let tempCampaigns = searchfilteredData.map((a) => {
      return { ...a };
    });
    tempCampaigns.map((campaignInstance) => {
      switch (type) {
        case "campaignBudgetInput":
          if (campaignInstance.id === id) {
            campaignInstance.daily_budget = updatedValue;
          }
          break;
        case "campaignNameInput":
          if (campaignInstance.id === id) {
            campaignInstance.name = updatedValue;
          }
          break;
      }
    });
    setsearchfilteredData(tempCampaigns);
    // setCopyOfConsolidatedCampaigns(tempCampaigns);
  };

  const onAdsetEditChangeHandler = (id, updatedValue, type) => {
    let tempCampaigns = JSON.stringify(searchfilteredData);
    tempCampaigns = JSON.parse(tempCampaigns);

    for (let i = 0; i < tempCampaigns.length; i++) {
      for (let j = 0; j < tempCampaigns[i].adsets.length; j++) {
        if (tempCampaigns[i].adsets[j].id === id) {
          tempCampaigns[i].adsets[j].name = updatedValue;
        }
      }
    }
    setConsolidatedCampaigns(tempCampaigns);
    setCopyOfConsolidatedCampaigns(tempCampaigns);
    setsearchfilteredData(tempCampaigns);
  };

  // const onAdsetStatusChangeHandler = (campId, adsetId) => {
  //   // const [adsetStatusIcon, setAdsetStatusIcon] = useState("");
  //   console.log('Yes  it clicked me',campId,adsetId);
  //   let tempCampaigns = JSON.stringify(searchfilteredData);
  //   tempCampaigns = JSON.parse(tempCampaigns);

  //   for (let i = 0; i < tempCampaigns.length; i++) {
  //     if (tempCampaigns[i].id === campId) {
  //       for (let j = 0; j < tempCampaigns[i].adsets.length; j++) {
  //         if (tempCampaigns[i].adsets[j].id === adsetId) {
  //           tempCampaigns[i].adsets[j].status =
  //             tempCampaigns[i].adsets[j].status === "ENABLED"
  //               ? "DISABLED"
  //               : "ENABLED";
  //           updateAdsetOnServer(campId, tempCampaigns[i].adsets[j], ["status"]);
  //           // setConsolidatedCampaigns(tempCampaigns);
  //           // setCopyOfConsolidatedCampaigns(tempCampaigns);
  //           setsearchfilteredData(tempCampaigns);
  //         }
  //       }
  //     }
  //   }
  // };

  const onAdsetStatusChangeHandler = (campId, adsetId) => {
    const updatedCampaigns = searchfilteredData.map((campaign) => {
      if (campaign.id === campId) {
        return {
          ...campaign,
          adsets: campaign.adsets.map((adSet) => {
            if (adSet.id === adsetId) {
              const updatedStatus =
                adSet.status === "ENABLED" ? "DISABLED" : "ENABLED";
              if (adSet.status === "ENABLED") {
                dispatch(campaignListActions.showActionAlertModal());
                setShowAlertMsgOf("Are you sure you want to Paused adset");
                setAlertModalCauseAction({
                  alertCauseActionType: "enablePauseAdset",
                  // adset: adSet,
                  status: { ...adSet, status: updatedStatus },
                  campId: campId,
                  fieldToUpdate: ["status"],
                  currentAdsetState: adSet.status,
                  adsetId: adSet.id,
                });
              } else {
                dispatch(campaignListActions.showActionAlertModal());
                setShowAlertMsgOf("Are you sure you want to Enable adset");
                setAlertModalCauseAction({
                  alertCauseActionType: "enablePauseAdset",
                  // adset: adSet,
                  status: { ...adSet, status: updatedStatus },
                  campId: campId,
                  fieldToUpdate: ["status"],
                  adsetId: adSet.id,
                });
              }
              return { ...adSet, status: updatedStatus };
            }
            return adSet;
          }),
        };
      }
      return campaign;
    });

    setsearchfilteredData(updatedCampaigns);
  };

  const onSwitchBtnHandler = (
    id,
    currentStatus,
    isUpdateOnServer,
    shouldSetAlertModalDetail
  ) => {
    let tempCampaigns = searchfilteredData.map((a) => {
      return { ...a };
    });
    let campaignInstanceToUpdate = undefined;
    tempCampaigns.map((campaignInstance) => {
      if (campaignInstance.id === id) {
        if (currentStatus === "ENABLED") {
          campaignInstance.status = "PAUSED";
          if (shouldSetAlertModalDetail) {
            setShowAlertMsgOf("Are you sure you want to PAUSE Campaign");
            setAlertModalCauseAction({
              id: id,
              checkedStatus: "PAUSED",
              alertCauseActionType: "campaignStatus",
            });
          }
        } else {
          campaignInstance.status = "ENABLED";
          if (shouldSetAlertModalDetail) {
            setShowAlertMsgOf("Are you sure you want to ENABLED Campaign");
            setAlertModalCauseAction({
              id: id,
              checkedStatus: "ENABLED",
              alertCauseActionType: "campaignStatus",
            });
          }
        }
        campaignInstanceToUpdate = campaignInstance;
      }
    });
    setsearchfilteredData(tempCampaigns);
    dispatch(campaignListActions.showActionAlertModal());
    if (isUpdateOnServer) {
      if (campaignInstanceToUpdate) {
        updateModifiedCampaignToServer(campaignInstanceToUpdate, ["status"]);
      }
    }
  };



  const closeCalender = (e) => {
    if (
      calenderIconRefForCampaignlist.current &&
      showCustomDateBox &&
      !calenderIconRefForCampaignlist.current.contains(e.target)
    ) {
      setShowCustomDateBox(false);
    }
  };


  const onSortHandler = (columnName,index) => {
    setIsSortBoxVisible(!isSortBoxVisible)
    const mainValue = columnName === "REVENUE" ? "sale_value" : columnName;
    setCurrentIndex(index)
    setCurrentSortingHead(mainValue)
  }
  

  const onSpendFilterClickHandler = (value, index , data) => {
    // If there is data sent for sorting then sort other wise don't sort
    let tempTargetStat = JSON.parse(JSON.stringify(data??searchfilteredData));
    const mainValue = value === "REVENUE" ? "sale_value" : value;
    tempTargetStat = tempTargetStat.sort((a, b) => {
      let x = a?.campaignStat[0]?.[mainValue.toLowerCase()] ?? 0;
      let y = b?.campaignStat[0]?.[mainValue.toLowerCase()] ?? 0;
      return isSortBoxVisible ? y - x : x - y;
    });
    return tempTargetStat
  };

  const onDraftClickHandler = () => {
    navigate("./drafts");
  };

  const campaignCancelHandler = (campaignId) => {
    let tempCampaigns = JSON.parse(JSON.stringify(searchfilteredData));
    tempCampaigns = tempCampaigns.map((eachCampaign) => {
      if (eachCampaign.id === campaignId) {
        eachCampaign.isEditCampaignNameDiabled =
          !eachCampaign.isEditCampaignNameDiabled;
        eachCampaign.name = eachCampaign.campaignNameCopy;
      }
      return eachCampaign;
    });
    setsearchfilteredData(tempCampaigns);
  };

  const onApplyBtnhandler = (fieldNameAsSendInApi) => {
    //here we will clear previous chip data for current clicked
    setMultiSelectDropDownSelecteddDataCount({
      ...multiSelectDropDownSelectedDataCount,
      [fieldNameAsSendInApi]: 0,
    });
    removePreviosSameFilterObject(
      droDownFilterDataForApi,
      fieldNameAsSendInApi
    );
    setDroDownFilterDataForApi((droDownFilterDataForApi) => [
      ...droDownFilterDataForApi,
    ]);
    let selectedValue = [];
    let selectedDropDownName;
    let buttonName;
    // const [droDownFilterDataForApi, setDroDownFilterDataForApi] = useState([]);
    if (checkedDropDownFilterButtonData.length) {
      for (let i = 0; i < checkedDropDownFilterButtonData.length; i++) {
        if (
          checkedDropDownFilterButtonData[i].fieldNameForAPIFilter ===
          "amz_targeting_type"
        ) {
          removePreviosSameFilterObject(
            campaignFiltering,
            "amz_targeting_type"
          );
          setcampaignFiltering((campaignFiltering) => [...campaignFiltering]);
          selectedDropDownName = "amz_targeting_type";
          buttonName =
            checkedDropDownFilterButtonData[i].fieldNameOfSelectedBtn;
          selectedValue.push(checkedDropDownFilterButtonData[i].value);
        }
        if (
          checkedDropDownFilterButtonData[i].fieldNameForAPIFilter ===
          "objective"
        ) {
          removePreviosSameFilterObject(campaignFiltering, "objective");
          setcampaignFiltering((campaignFiltering) => [...campaignFiltering]);
          selectedDropDownName = "objective";
          buttonName =
            checkedDropDownFilterButtonData[i].fieldNameOfSelectedBtn;
          selectedValue.push(checkedDropDownFilterButtonData[i].value);
        }
        if (
          checkedDropDownFilterButtonData[i].fieldNameForAPIFilter ===
          "targeting_objective"
        ) {
          removePreviosSameFilterObject(
            campaignFiltering,
            "targeting_objective"
          );
          setcampaignFiltering((campaignFiltering) => [...campaignFiltering]);
          selectedDropDownName = "targeting_objective";
          buttonName =
            checkedDropDownFilterButtonData[i].fieldNameOfSelectedBtn;
          selectedValue.push(checkedDropDownFilterButtonData[i].value);
        }
      }

      if (selectedDropDownName === "amz_targeting_type") {
        setcampaignFiltering((campaignFiltering) => [
          ...campaignFiltering,
          {
            field_name: "amz_targeting_type",
            operator: "IN",
            values: selectedValue,
          },
        ]);
        // removePreviosSameFilterObject(droDownFilterDataForApi, buttonName);
        // setDroDownFilterDataForApi((droDownFilterDataForApi) => [
        //   ...droDownFilterDataForApi,
        //   {
        //     fieldNameOfSelectedBtn: buttonName,
        //     field_name: "amz_targeting_type",
        //     checkedValue: selectedValue,
        //   },
        // ]);
      } else if (selectedDropDownName === "objective") {
        setcampaignFiltering((campaignFiltering) => [
          ...campaignFiltering,
          {
            field_name: "objective",
            operator: "IN",
            values: selectedValue,
          },
        ]);
        // removePreviosSameFilterObject(droDownFilterDataForApi, buttonName);
        // setDroDownFilterDataForApi((droDownFilterDataForApi) => [
        //   ...droDownFilterDataForApi,
        //   {
        //     fieldNameOfSelectedBtn: buttonName,
        //     field_name: "campaign_type",
        //     checkedValue: selectedValue,
        //   },
        // ]);
      } else if (selectedDropDownName === "targeting_objective") {
        setcampaignFiltering((campaignFiltering) => [
          ...campaignFiltering,
          {
            field_name: "targeting_objective",
            operator: "IN",
            values: selectedValue,
          },
        ]);
        // removePreviosSameFilterObject(droDownFilterDataForApi, buttonName);
        // setDroDownFilterDataForApi((droDownFilterDataForApi) => [
        //   ...droDownFilterDataForApi,
        //   {
        //     fieldNameOfSelectedBtn: buttonName,
        //     field_name: "campaign_objective",
        //     checkedValue: selectedValue,
        //   },
        // ]);
      }
    } else {
      // // setTargetingFiltering((targetingFiltering) => [...targetingFiltering]);
      // setcampaignFiltering((campaignFiltering) => [...campaignFiltering]);
      removePreviosSameFilterObject(campaignFiltering, fieldNameAsSendInApi);

      setcampaignFiltering((campaignFiltering) => [...campaignFiltering]);
    }
    setCheckedDropDownFilterButtonData([]);
  };

  const uncheckAllOptions = () => {
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    currentList.map((list) => {
      list.isDataChecked = false;
    });

    setDropDownDataList(currentList);
  };
  const onOptionCheckedHandlerInDropDownFilter = (
    e,
    checkedValue,
    fieldNameForAPIFilter,
    fieldNameOfSelectedBtn,
    fieldName
  ) => {
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });

    currentList.map((list) => {
      if (list.name === checkedValue && list.fieldName === fieldName) {
        if (!list.isDataChecked) {
          list.isDataChecked = true;
          setCheckedDropDownFilterButtonData(
            (checkedDropDownFilterButtonData) => [
              ...checkedDropDownFilterButtonData,
              {
                value: checkedValue,
                fieldNameOfSelectedBtn: fieldNameOfSelectedBtn,
                fieldNameForAPIFilter: fieldNameForAPIFilter,
              },
            ]
          );
        } else {
          list.isDataChecked = false;
          if (
            checkedDropDownFilterButtonData &&
            checkedDropDownFilterButtonData.length
          ) {
            for (
              let index = 0;
              index < checkedDropDownFilterButtonData.length;
              index++
            ) {
              if (
                checkedDropDownFilterButtonData[index] &&
                checkedDropDownFilterButtonData[index].value === checkedValue
              ) {
                checkedDropDownFilterButtonData.splice(index, 1);
              }
            }
            setCheckedDropDownFilterButtonData(
              (checkedDropDownFilterButtonData) => [
                ...checkedDropDownFilterButtonData,
              ]
            );
          }
        }
      }
    });

    setDropDownDataList(currentList);
  };
  const allSeletHandler = (
    e,
    displayedBtnName,
    BtnNameUniqueField,
    fieldNameForAPIFilter
  ) => {
    setCheckedDropDownFilterButtonData([]);
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    currentList.map((list) => {
      if (
        list.buttonName === displayedBtnName &&
        list.fieldName === BtnNameUniqueField
      ) {
        if (e.target.checked) {
          list.isDataChecked = true;
          setCheckedDropDownFilterButtonData(
            (checkedDropDownFilterButtonData) => [
              ...checkedDropDownFilterButtonData,
              {
                value: list.name,
                fieldNameOfSelectedBtn: displayedBtnName,
                fieldNameForAPIFilter: fieldNameForAPIFilter,
              },
            ]
          );
        }
      }
    });
    setDropDownDataList(currentList);
  };

  //this function will handle check & unncheck state of currently apllied filtering of dropdown btn
  const showCheckedAppliedFilteringWhenOPen = (
    btnNameHeader,
    btnFieldName,
    fieldNameAsSendInApiForAplication
  ) => {
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });

    let appliedTargetingFieldNameArray = [];

    campaignFiltering.map((tf, i) => {
      appliedTargetingFieldNameArray.push(tf.field_name);
    });

    //this will empty check data list
    setCheckedDropDownFilterButtonData([]);

    //first this will uncheck all check data
    currentList.map((cl) => {
      cl.isDataChecked = false;
    });

    //this will loop through data list & applied filter list and if filter is having that value then we will check that value & add in CheckedDropDownFilterButtonData list
    campaignFiltering.map((tf, i) => {
      if (campaignFiltering.length) {
        currentList.map((list) => {
          if (
            list.buttonName === btnNameHeader &&
            list.fieldName === btnFieldName &&
            appliedTargetingFieldNameArray.includes(
              fieldNameAsSendInApiForAplication
            )
          ) {
            if (tf.values.includes(list.name)) {
              list.isDataChecked = true;
              setCheckedDropDownFilterButtonData(
                (checkedDropDownFilterButtonData) => [
                  ...checkedDropDownFilterButtonData,
                  {
                    value: list.name,
                    fieldNameOfSelectedBtn: btnNameHeader,
                    fieldNameForAPIFilter: tf.field_name,
                  },
                ]
              );
            }
          }
        });
      }
    });

    setDropDownDataList(currentList);
  };

  const onDashIconHandlerToremoveAllCheckedOption = (
    e,
    targettingValue,
    fieldName,
    uniqueFieldName,
    fieldNameAsSendInApi
  ) => {
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    currentList.map((list) => {
      if (
        list.buttonName === targettingValue &&
        list.fieldName === uniqueFieldName
      ) {
        list.isDataChecked = false;
        checkedDropDownFilterButtonData.map((checkedData, index) => {
          if (
            checkedData.fieldNameOfSelectedBtn === targettingValue &&
            checkedData.fieldNameForAPIFilter === fieldNameAsSendInApi
          ) {
            if (index > 0) {
              checkedDropDownFilterButtonData.splice(index, 1);
            } else {
              checkedDropDownFilterButtonData.splice(index);
            }
          }
        });
      }
    });
    setDropDownDataList(currentList);

    // checkedDropDownFilterButtonData.map((checkedData, index) => {
    //   if (checkedData.fieldNameOfSelectedBtn === targettingValue) {
    //       if (index > 0) {
    //       checkedDropDownFilterButtonData.splice(index, 1);
    //     } else {
    //       checkedDropDownFilterButtonData.splice(index);
    //     }

    //   }
    // });
    // value: checkedValue,
    //             fieldNameOfSelectedBtn: fieldNameOfSelectedBtn,
    //             fieldNameForAPIFilter: fieldNameForAPIFilter,
    setCheckedDropDownFilterButtonData((checkedDropDownFilterButtonData) => [
      ...checkedDropDownFilterButtonData,
    ]);

    // removePreviosSameFilterObject(targetingFiltering, fieldNameAsSendInApi);
  };

  const onCloseOutSideDropDownList = (
    ButtonDisplayNaME,
    uniqueFieldName,
    fieldNameAsApi
  ) => {
    let currentList = DropDownDataList.map((a) => {
      return { ...a };
    });
    for (let i = 0; i < campaignFiltering.length; i++) {
      for (let c = 0; c < campaignFiltering[i].values.length; c++) {
        for (let d = 0; d < currentList.length; d++) {
          if (
            currentList[d].buttonName === ButtonDisplayNaME &&
            currentList[d].fieldName === uniqueFieldName
          ) {
            if (
              campaignFiltering[i].values[c] === currentList[d].name &&
              currentList[d].isDataChecked
            ) {
              console.log("campaignFiltering[i].values[c]");
            } else {
              currentList[d].isDataChecked = false;
            }
          }
        }
      }
    }
    setDropDownDataList(currentList);
    // currentList.map((list)=>{
    //   checkedDropDownFilterButtonData.map((checkeddata)=> {
    //   })
    // })
    // // setCheckedDropDownFilterButtonData(
    // //   (checkedDropDownFilterButtonData) => [
    // //     ...checkedDropDownFilterButtonData,
    // //   ]
    // // );
  };

  const onCancelClickRemoveFilteringHandlerr = (fieldNameForAPIFilter) => {
    if (
      fieldNameForAPIFilter !== "amz_targeting_type" &&
      fieldNameForAPIFilter !== "objective" &&
      fieldNameForAPIFilter !== "targeting_objective"
    ) {
      setCancelSpecifiNumberFilter(fieldNameForAPIFilter);
    }
    removePreviosSameFilterObject(
      droDownFilterDataForApi,
      fieldNameForAPIFilter
    );
    setDroDownFilterDataForApi((droDownFilterDataForApi) => [
      ...droDownFilterDataForApi,
    ]);

    //   const filteredItems = items.filter(item => item.id !== idToDelete);
    // setItems(filteredItems);

    removePreviosSameFilterObject(campaignFiltering, fieldNameForAPIFilter);
    setcampaignFiltering((campaignFiltering) => [...campaignFiltering]);

    if (
      fieldNameForAPIFilter === "amz_targeting_type" ||
      fieldNameForAPIFilter === "objective" ||
      fieldNameForAPIFilter === "targeting_objective"
    ) {
      let currentList = DropDownDataList.map((a) => {
        return { ...a };
      });

      currentList.map((list) => {
        if (fieldNameForAPIFilter === list.apiFieldNameForFilteringRequest) {
          list.isDataChecked = false;
          switch (list.apiFieldNameForFilteringRequest) {
            case "amz_targeting_type":
              setMultiSelectDropDownSelecteddDataCount({
                ...multiSelectDropDownSelectedDataCount,
                amz_targeting_type: 0,
              });
              break;
            case "objective":
              setMultiSelectDropDownSelecteddDataCount({
                ...multiSelectDropDownSelectedDataCount,
                objective: 0,
              });
              break;
            case "targeting_objective":
              setMultiSelectDropDownSelecteddDataCount({
                ...multiSelectDropDownSelectedDataCount,
                targeting_objective: 0,
              });
              break;

            default:
              break;
          }
        }
      });
      setDropDownDataList(currentList);
    }
    if (
      fieldNameForAPIFilter === "portfolio_id" ||
      fieldNameForAPIFilter === "campaign_id" ||
      fieldNameForAPIFilter == "adgroup_id"
    ) {
      let dataHeadCopy = JSON.stringify(dataHeads);
      dataHeadCopy = JSON.parse(dataHeadCopy);

      dataHeadCopy.map((col) => {
        col.isFindTaskActive = false;
      });

      setdataHeads(dataHeadCopy);
    }

    if(fieldNameForAPIFilter === "category") {
      setSelectedCategory(null)
    }

    /*this function will tell if appliedFilterData arraay has numeric fileter or not & if yes
     it will maake state yes else false and this state handle the visibilty of 
     yellow icon aboce filter icon which tell if filter is applied */
    const hasNumericRange = appliedFilterData.some((obj) => {
      return (
        !isNaN(obj.minRange) ||
        (!isNaN(obj.maxRange) &&
          obj.maxRange !== 0 &&
          obj.maxRange !== "Infinity")
      );
    });
    hasNumericRange
      ? setMinMaxFilterActivated(true)
      : setMinMaxFilterActivated(false);
  };

  const onResetAllFilteringBTnHandler = () => {
    // dispatch(campaignListActions.appliedFilterData([]));
    setCancelSpecifiNumberFilter("RemoveAllRanges");
    setMinMaxFilterActivated(false);
    setDroDownFilterDataForApi([]);
    setcampaignFiltering([]);
    setSelectedCategory(null)
    // dispatch(campaignListActions.appliedFilterData([]));
    setMultiSelectDropDownSelecteddDataCount({
      adyogi_targeting_type: 0,
      campaign_type: 0,
      campaign_objective: 0,
    });
  };

  const getClassNameThead = (th, mainIndex, currentIndex) => {
      let clsname = "";
      if(th.isHeadRightAlign){
        clsname = "campaig-list-head cl-end-align"
      }else if(th.isHeadWithIcon === "sort"){
        clsname = "campaig-list-head active-filter-colmun selected"
      }else{
        clsname = "campaig-list-head"
      }

      if(th.fieldName.toLowerCase() !== 'status' && mainIndex === currentIndex){
        clsname += " selected";
      }

      return clsname;
  }
  const getStatusWiseValue = (status, val) => {
    return val === 0 || val === "" ? (status && status.toUpperCase() === 'ENABLED' ? 0 : '-') : val;
  }

  const bindChildValueColumns = (adsetIns, currentDataHead) => {
    if (adsetIns?.adsetStats?.length && adsetIns.adsetStats[0] && adsetIns?.adsetStats[0][currentDataHead.id] != null) {
      const value = adsetIns?.adsetStats[0][currentDataHead.id];
      if ((currentDataHead.id === "spends" || currentDataHead.id === "aov" || currentDataHead.id === "sale_value") && value !== null) {
        if (Number.isNaN(Math.round(value))
        ) {
          return (value === 0 || value === "") ? getStatusWiseValue(adsetIns.status, value) : getStatusWiseValue(adsetIns.status, 0);
        } else {
          return Math.round(value);
        }
      } else if (metricesWithTwoDecimalPlaces.includes(currentDataHead.id) && value !== null) {
        if (Number.isNaN(Math.round(value))) {
          return getStatusWiseValue(adsetIns.status, 0);
        } else {
          return (value === 0 || value === "") ? getStatusWiseValue(adsetIns.status, value) : roundFigureNum(value).toFixed(2);
        }
      } else {
        return getStatusWiseValue(adsetIns.status, value);
      }
    } else {
      return getStatusWiseValue(adsetIns.status, 0)
    }
  }

  const applyDynamicClass = (id,value) => {
    return (id === "dbr" && value>70) ? "cl-end-align td-with-loader tableSummary text-danger":"cl-end-align td-with-loader tableSummary"
  }
  
 const getUpdatedDrowDownFilters = (value,key) => {
  const data = value.map((val) => val.name);
  setSelectedCategory(data.join(','));
  const chipForFilters = {checkedValue:data,fieldNameOfSelectedBtn:"Categories",field_name:"category"}
  setDroDownFilterDataForApi([...droDownFilterDataForApi,chipForFilters])
 }

 const [selectedChildRows, setSelectedChildRows] = useState([]);
 const handleCellClick = (rowIndex) => {
   setSelectedChildRows((prevSelectedRows) =>
     prevSelectedRows.includes(rowIndex)
       ? prevSelectedRows.filter((row) => row !== rowIndex)
       : [...prevSelectedRows, rowIndex]
   );
 }

 const reportMenus = [{ 
  label: "Reports",
  status: true,
  pages:[
    {
    label: "Portfolio Report",
    status: true,
    href:"/portfolio-report"
  },
  {
    label: "Campaign Report",
    status: true,
    href:"/campaign-report"
  },
  {
    label: "Campaign Format Report",
    status: true,
    href:"/campaign-format-report"
  },
  {
    label: "Adgroup Report",
    status: true,
    href:"/ad-group-report"
  },
]
}]




  let tableBody = (
    <div className="main-campaignlist-table-body-container">
      {searchfilteredData?.length ? (
        <table className="campaign-list-table campaign-list-center reports">
          <thead className="fixed-table">
            <tr className="main-table-row-fixed">
              {dataHeads?.map((th, mainIndex) => {
                return (
                  <th
                    className={
                      getClassNameThead(th, mainIndex, currentIndex,isSortBoxVisible)
                    }
                    key={th.fieldName}
                  >
                    {th.isHeadWithIcon === "sort" ? (
                      <div
                        className={
                          !th.isAbbrivation
                            ? "d-flex flex-row align-item-center justify-content-end captital-first-letter"
                            : "d-flex flex-row align-item-center justify-content-end"
                        }
                      >
                        <span
                          className={
                            mainIndex === currentIndex
                              ? "active-filter-higlighted-text"
                              : ""
                          }
                        >
                          {!th.isAbbrivation
                            ? th.fieldName.toLowerCase()
                            : th.fieldName}
                        </span>
                        <span
                          className="spends-icon"
                          onClick={() =>
                            onSortHandler(th.id, mainIndex)
                          }
                        >
                          <img
                            src={
                              !isSortBoxVisible && mainIndex === currentIndex
                                ? ascendingIconSvg
                                : descendingIconSvg
                            }
                            className={
                              mainIndex === currentIndex
                                ? "mt-spends-icon-activated"
                                : ""
                            }
                            alt={"FilterIcon"}
                          ></img>
                        </span>
                        <div
                          className={
                            isSortBoxVisible ? "sortBox-container" : "hide"
                          }
                        ></div>
                      </div>
                    ) : th.fieldName === "Categories" ? (
                      <>
                         <div
                              className={`fieldnamme-with-search-iconn justify-content-start`}
                            >
                              <span className="d-flex align-items-center me-2">
                                <img
                                  // className="find-icon cursor-pointer-global"
                                  className={
                                     selectedCategory!==null
                                      ? "find-icon cursor-pointer-global mt-find-icon-activated"
                                      : "find-icon cursor-pointer-global"
                                  }
                                  src={whiteFindIcon}
                                  onClick={() =>
                                    onSearchModalHandler(th.fieldName)
                                  }
                                  alt={"whiteFindIcon"}
                                ></img>
                              </span>
                              <div className="d-flex align-items-center test">
                                <span
                                  className={
                                    selectedCategory !==null ?
                                   "stats-head-with-icon active-filter-higlighted-text"
                                   : "stats-head-with-icon"
                                  }
                                >
                                  {th.fieldName === "Campaign"
                                    ? "Campaigns"
                                    : th.fieldName}
                                </span>
                              </div>
                              {
                                allFiltersVisible.searchSortModal && (
                                  <MasterTargetingSearchSelectModal
                                    findClickedColumnName={"Category"}
                                    keyToBeSet={
                                      th.fieldName === "Campaign"
                                        ? "campaign_id"
                                        : th.fieldName === "Ad Group"
                                          ? "adgroup_id"
                                          : th.fieldName === "Portfolio"
                                            ? "portfolio_id"
                                            : "category"
                                    }
                                    getUpdatedDrowDownFilters={
                                      getUpdatedDrowDownFilters
                                    }
                                    isVisible={
                                      allFiltersVisible.searchSortModal
                                    }
                                    initialData={
                                    categories
                                    }
                                    defaultSelected={true}
                                    defaultSelectedValue={selectedCategory!==null?selectedCategory.split(","):[]}
                                    cancelFilter={setAllFilterVisible}
                                    currentFilters={allFiltersVisible}
                                  />
                                )}
                            </div>
                      </>
                    ) : (
                      <span className="captital-first-letter">
                        {th.fieldName.toLowerCase()}
                      </span>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          {searchfilteredData?.length ? (
            <tbody className="another-tbody">
              {searchfilteredData?.map((data, index1) => {
                data = JSON.stringify(data);
                data = JSON.parse(data);
                for (let i = 0; i < dataHeads?.length; i++) {
                  if (!dataHeads[i]?.isDefaultHead) {
                    data[dataHeads[i]?.id] = data[dataHeads[i]?.id]
                      ? data[dataHeads[i]?.id]
                      : "-";
                  }
                }
                return (
                  <React.Fragment key={index1+data?.id}>
                    {
                      // If Summary Row Then show Different UI
                      data?.isSummaryRow && index1 === 0 ? <tr onClick={() => handleCellClick(index1 + "summaryrow")}
                      className={
                        selectedChildRows.includes(index1 + "summaryrow")
                          ? "cursor-pointer highlight"
                          : "cursor-pointer"
                      } key={"summary-row"}>
                        <td className="first-column-sw-btn">-</td>
                        <td className="fw-bold second-column">Overall Summary</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        {data?.campaignStat?.length
                          ? data["campaignStat"].map((campStatInst, indo) => {
                              // if (data.campaignStat) {
  
                              for (let i = 0; i < dataHeads.length; i++) {
                                if (!dataHeads[i].isDefaultHead) {
                                  let value = getStatusWiseValue(data.status, campStatInst[dataHeads[i].id])
                                  if (campStatInst[dataHeads[i].id]) {
                                    campStatInst[dataHeads[i].id] = dataHeads[i].id === "spends" || dataHeads[i].id === "sale_value"
                                      ? value !== '-' ? `${currencySymbol}${Math.round(value)}` : value
                                      : metricesWithTwoDecimalPlaces.includes(dataHeads[i].id)
                                        ? value !== '-' ? `${Number(value).toFixed(2)}` : value
                                        : value !== '-' ? Math.round(value) : value;
                                  } else {
                                    campStatInst[dataHeads[i].id] = getStatusWiseValue(data.status, 0);
                                  }
                                }
                              }
    
  
                              {
                                return dataHeads.map((currentDataHead, i) => {
                                  if (!currentDataHead.isDefaultHead) {
                                    return (
                                      // <td key={i + 2} className="td-with-loader" >
                                      <td
                                      key={i + 10}
                                      className={
                                        currentDataHead.isHeadRightAlign
                                          ? "cl-end-align td-with-loader fw-bold"
                                          : " td-with-loader"
                                      }
                                    >
                                      {allFetchLoaders.campaignStatsLoader ? (
                                        <Spinner shimmer={true} />
                                      ) : campStatInst ? (
                                        campStatInst[currentDataHead.id]
                                      ) : (
                                        getStatusWiseValue(data.status, 0)
                                      )}
                                      {/* {campStatInst
                                          ? campStatInst[currentDataHead.id]
                                          : "-"} */}
                                      </td>
                                    );
                                  }
                                });

                                
                              }
                            })
                          : dataHeads.map((currentDataHead, i) => {
                            if (!currentDataHead.isDefaultHead) {
                              return (
                                <td
                                  key={i + 3}
                                  className={
                                    currentDataHead.isHeadRightAlign
                                      ? "cl-end-align td-with-loader fw-bold"
                                      : " td-with-loader"
                                  }
                                >
                                  {allFetchLoaders.campaignStatsLoader ? (
                                    <Spinner shimmer={true} />
                                  ) : (
                                    getStatusWiseValue(data.status, 0)
                                  )}
                                </td>
                              );
                            }
                          })}
                        </tr> 
                      : !data?.isSummaryRow ?   <>
                      <tr key={`${data?.id+3}`}   onClick={() => handleCellClick(index1 + "mainrow")}
                  className={
                    selectedChildRows.includes(index1 + "mainrow")
                      ? "cursor-pointer highlight"
                      : "cursor-pointer"
                  }>
                        <td className="first-column-sw-btn">
                          <div className="classlist-switch-btn" onClick={() => handleCellClick(index1 + "mainrow")}>
                            {
                              <SwitchBtn
                                checked={
                                  data.status === "ENABLED"
                                    ? true
                                    : data.status === "PAUSED"
                                    ? false
                                    : false
                                }
                                onChange={(e) =>
                                  onSwitchBtnHandler(
                                    data.id,
                                    data.status,
                                    //   data.status,
                                    false,
                                    true
                                  )
                                }
                              ></SwitchBtn>
                            }
                          </div>
                        </td>
                        <td className="second-column ">
                          <div className="campaign-name-container">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex flex-start align-item-center">
                                <div className={"td-with-loader padding-loader"}>
                                  {allFetchLoaders.campaignAdsetsLoader ? (
                                    <Spinner
                                      shimmer={true}
                                      shimmerShape={"box"}
                                    />
                                  ) : (
                                    <div onClick={() => handleCellClick(index1 + "mainrow")}
                                      className={
                                        data.isPlusIconDisabled
                                          ? "plus-icon-sec-container"
                                          : "plus-icon-sec-container-when-clicked"
                                      }
                                    >
                                      <img
                                        onClick={() =>
                                          campaignEditClickHandler(
                                            data.id,
                                            "PlusIconHandler"
                                          )
                                        }
                                        className="hoverGreen "
                                        src={
                                          data?.isPlusIconDisabled
                                            ? addIcon
                                            : lessIcon
                                        }
                                      ></img>
                                    </div>
                                  )}
                                </div>
  
                                <div>
                                  {/* {
                                  <input
                                    wrap="hard"
                                    cols="23"
                                    className={
                                      data?.isEditCampaignNameDiabled
                                        ? "campaign-name-input-disable "
                                        : "campaign-name-input "
                                    }
                                    onChange={(e) => {
                                      setInputValue(
                                        e,
                                        data.id,
                                        e.target.value,
                                        "campaignNameInput"
                                      );
                                    }}
                                    value={data.name}
                                    disabled={data.isEditCampaignNameDiabled}
                                  ></input>
                                } */}
                                  {data?.isEditCampaignNameDiabled ? (
                                    <div className="cl-name-text">
                                      {data.name}
                                    </div>
                                  ) : (
                                    <input
                                      wrap="hard"
                                      cols="23"
                                      className={
                                        data?.isEditCampaignNameDiabled
                                          ? "campaign-name-input-disable "
                                          : " "
                                      }
                                      onChange={(e) => {
                                        setInputValue(
                                          e,
                                          data.id,
                                          e.target.value,
                                          "campaignNameInput"
                                        );
                                      }}
                                      value={data.name}
                                      disabled={data.isEditCampaignNameDiabled}
                                    ></input>
                                  )}
                                </div>
                              </div>
                              <div className="pencil-icon-in-row-container" onClick={() => handleCellClick(index1 + "mainrow")}>
                                <div>
                                  <img
                                    src={
                                      data?.isEditCampaignNameDiabled
                                        ? editIcon
                                        : checkCircle
                                    }
                                    onClick={() =>
                                      campaignEditClickHandler(
                                        data.id,
                                        "EditCampaignNamePencil",
                                        "",
                                        data.isEditCampaignNameDiabled
                                      )
                                    }
                                    // className="main-edit-icon"
                                    alt="main-edit-icon"
                                  ></img>
                                </div>
                                <div className={data?.isEditCampaignNameDiabled? "d-none": "pencil-icon-hover"}>
                                  <img
                                    className={
                                      data?.isEditCampaignNameDiabled
                                        ? "d-none"
                                        : "cancel-check-circle cursor-pointer-global m-0"
                                    }
                                    onClick={() =>
                                      campaignCancelHandler(data.id, "1")
                                    }
                                    src={cancelIcon}
                                    alt={"cancelCircle"}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      <td className="campaign-category-column" title={data?.campaign_categories}>
                        {/* {data?.campaign_categories?.slice(0,80) ? data?.campaign_categories?.slice(0,80) + "..." : "-"} */}
                        {sliceToCertainLength(data?.campaign_categories,80) ?? "-"}
                        </td>    
                        <td>{data?.amz_targeting_type}</td>
                        <td>{data?.amz_portfolio_name}</td>
                        <td>
                          <div className="d-flex justify-content-evenly align-items-center budget-td-container">
                            <input
                              type="text"
                              className={
                                data?.iseditBudgetValueDisabled
                                  ? "budget-value"
                                  : "budget-value-edit"
                              }
                              disabled={data?.iseditBudgetValueDisabled}
                              value={data?.daily_budget}
                              onChange={(e) => {
                                setInputValue(
                                  e,
                                  data?.id,
                                  e.target.value,
                                  "campaignBudgetInput",
                                  data?.daily_budget
                                );
                              }}
                            ></input>
                            <span
                              className="edit-budget-icon-container"
                              onClick={() =>
                                campaignEditClickHandler(
                                  data.id,
                                  "BudgetPencil",
                                  "",
                                  data?.iseditBudgetValueDisabled
                                )
                              }
                            >
                              <img
                                src={
                                  data?.iseditBudgetValueDisabled
                                    ? editIcon
                                    : checkCircle
                                }
                                alt={"edit"}
                              ></img>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="bid-startegy-op-container">
                            <select
                              value={data?.bidding?.bidding_strategy}
                              onChange={(e) =>
                                onChangeBidStrtegyhandler(
                                  data.id,
                                  e.target.value,
  
                                  "EditBiddingFieldonChange"
                                )
                              }
                            >
                              <option
                                className="bid-startegy-op"
                                value="autoForSales"
                              >
                                Up & Down{" "}
                              </option>
                              <option
                                className="bid-startegy-op"
                                value="legacyForSales"
                              >
                                {" "}
                                Down{" "}
                              </option>
                            </select>
                          </div>
                        </td>
                        {data?.campaignStat?.length
                          ? data["campaignStat"].map((campStatInst, indo) => {
                              // if (data.campaignStat) {
  
                              for (let i = 0; i < dataHeads.length; i++) {
                                if (!dataHeads[i].isDefaultHead) {
                                  campStatInst[dataHeads[i].id] = campStatInst[
                                    dataHeads[i].id
                                  ]
                                    ? dataHeads[i].id === "spends" ||
                                      dataHeads[i].id === "sale_value"
                                      ? `${currencySymbol}${Math.round(
                                          campStatInst[dataHeads[i].id]
                                        )}`
                                      : metricesWithTwoDecimalPlaces.includes(
                                          dataHeads[i].id
                                        )
                                      ? `${Number(
                                          campStatInst[dataHeads[i].id]
                                        ).toFixed(2)}`
                                      : Math.round(campStatInst[dataHeads[i].id])
                                    : getStatusWiseValue(data.status, 0);
                                }
                              }
  
                              {
                                return dataHeads.map((currentDataHead, i) => {
                                  if (!currentDataHead.isDefaultHead) {
                                    return (
                                      // <td key={i + 2} className="td-with-loader" >
                                      <td
                                        key={i + 2}
                                        className={
                                          currentDataHead.isHeadRightAlign
                                            ? applyDynamicClass(currentDataHead.id , campStatInst[currentDataHead.id])
                                            : " td-with-loader"
                                        }
                                      >
                                        {allFetchLoaders.campaignStatsLoader ? (
                                          <Spinner shimmer={true} />
                                        ) : campStatInst ? (
                                          campStatInst[currentDataHead.id]
                                        ) : (
                                          getStatusWiseValue(data.status, 0)
                                        )}
                                        {/* {campStatInst
                                          ? campStatInst[currentDataHead.id]
                                          : "-"} */}
                                      </td>
                                    );
                                  }
                                });
                              }
                            })
                          : dataHeads.map((currentDataHead, i) => {
                              if (!currentDataHead.isDefaultHead) {
                                return (
                                  <td
                                    key={i + 3}
                                    className={
                                      currentDataHead.isHeadRightAlign
                                        ? "cl-end-align td-with-loader"
                                        : " td-with-loader"
                                    }
                                  >
                                    {allFetchLoaders.campaignStatsLoader ? (
                                      <Spinner shimmer={true} />
                                    ) : (
                                      getStatusWiseValue(data.status, 0)
                                    )}
                                  </td>
                                );
                              }
                            })}
                      </tr>
                      {data["adsets"].map((adsetIns, indo) => {
                        if (adsetIns?.adsetStats?.length) {
                          for (let i = 0; i < dataHeads.length; i++) {
                            if (
                              dataHeads[i] &&
                              !dataHeads[i]?.isDefaultHead &&
                              adsetIns.adsetStats &&
                              adsetIns.adsetStats[0]
                            ) {
                              adsetIns.adsetStats[0][dataHeads[i]?.id] = adsetIns
                                .adsetStats[0][dataHeads[i]?.id]
                                ? adsetIns.adsetStats[0][dataHeads[i]?.id]
                                : getStatusWiseValue(adsetIns.status, 0);
                            }
                          }
                        }
  
                        return (
                          <tr
                            key={indo + 4}
                            // className={
                            //   data.isPlusIconDisabled
                            //     ? "hide"
                            //     : "campaign-details"
                            // }
                            onClick={() => handleCellClick(indo + "subrow")}
                          className={data.isPlusIconDisabled ? "hide"
                              : selectedChildRows.includes(indo + "subrow")
                              ? "campaign-details cursor-pointer highlight"
                              : "campaign-details cursor-pointer"
                          }
                          >
                            <td className="blank-first-adset-td-column child-font">
                              <div className="child-switch-btn-container " onClick={() => handleCellClick(indo + "subrow")}>
                                {
                                  <SwitchBtn
                                    onChange={(e) =>
                                      onAdsetStatusChangeHandler(
                                        data.id,
                                        adsetIns.id
                                      )
                                    }
                                    checked={
                                      adsetIns.status === "ENABLED" ? true : false
                                    }
                                  
                                  ></SwitchBtn>
                                }
                              </div>
                            </td>
                            <td className="campaign-details-data child-font">
                              {
                                <div className="d-flex flex-column adset-name-data-cell">
                                  <div className="d-flex flex-row align-item-start justify-content-between">
                                    {adsetIns.iseditAdsetNameValueDisabled ? (
                                      <div className="add-set-name-container">
                                        <input
                                          // value={adsetIns.name}
                                          defaultValue={adsetIns.name}
                                          onChange={(e) => {
                                            onAdsetEditChangeHandler(
                                              adsetIns.id,
                                              e.target.value,
                                              "campaignAdsetInput"
                                            );
                                          }}
                                        ></input>
                                         <div className="pencil-icon-in-row-container">
                                         <div className="subrow-pencil-icon-hover">
                                          <img
                                            className="input-check-circle cursor-pointer-global"
                                            onClick={() =>
                                              addCampInputChekCircleHandler(
                                                data.id,
                                                adsetIns.id,
                                                "EditCampaignAdsetNameOnServerPencil"
                                              )
                                            }
                                            src={checkCircle}
                                            alt={"checkCircle"}
                                          ></img>
                                          </div>
                                          <div className="subrow-pencil-icon-hover">
                                          <img
                                            className="cancel-check-circle cursor-pointer-global"
                                            onClick={() =>
                                              addCampCancelCircleHandler(
                                                data.id,
                                                adsetIns.id
                                              )
                                            }
                                            src={cancelIcon}
                                            alt={"cancelCircle"}
                                          ></img>
                                        </div>
                                         </div>
                                         
                                      </div>
                                    ) : (
                                      <span
                                        className="cursor-pointer-global adgroup-clickable-text-color"
                                        onClick={() =>
                                          editCampaignPencilIconClickHandler({
                                            adset_id: adsetIns.platform_id
                                              ? adsetIns.platform_id
                                              : null,
                                            campaign_id: data.platform_id
                                              ? data.platform_id
                                              : null,
                                            adset: adsetIns ? adsetIns : null,
                                            alphaNumericCampaignid: data.id
                                              ? data.id
                                              : null,
                                            alphanumericAdsetId: adsetIns.id
                                              ? adsetIns.id
                                              : null,
                                            campaignType: data.type
                                              ? data.type
                                              : null,
                                            adsetCategory: adsetIns.amz_category
                                              ? adsetIns.amz_category
                                              : null,
                                            bidStaregy: data?.bidding
                                              ?.bidding_strategy
                                              ? data?.bidding?.bidding_strategy
                                              : "",
                                            currentCampaign: data,
                                          })
                                        }
                                      >
                                        {adsetIns.name}
                                      </span>
                                    )}
                                    <span onClick={() => handleCellClick(indo + "subrow")}>
                                      <img
                                        src={editIcon}
                                        onClick={() =>
                                          campaignEditClickHandler(
                                            data.id,
                                            "EditCampaignAdsetPencil",
                                            adsetIns.id,
                                            adsetIns.iseditAdsetNameValueDisabled
                                          )
                                        }
                                        className={
                                          adsetIns.iseditAdsetNameValueDisabled
                                            ? "hide"
                                            : "cursor-pointer-global"
                                        }
                                        alt="main-edit-icon"
                                      ></img>
                                    </span>
                                  </div>
                                </div>
                              }{" "}
                            </td>
                            <td>-</td>
                            <td className="">
                              {adsetIns.adsetStats.length &&
                              adsetIns.adsetStats[0]?.targetting ? (
                                <span>
                                  {" "}
                                  {adsetIns.adsetStats[0]?.targetting}{" "}
                                </span>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                            <td className="">
                              {adsetIns?.adsetStats?.length > 0 &&
                              adsetIns.adsetStats[0]?.portfolio ? (
                                <span>{adsetIns.adsetStats[0]?.portfolio}</span>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                            <td>
                              {adsetIns?.adsetStats?.length &&
                              adsetIns.adsetStats[0]?.budget != null ? (
                                <span>{adsetIns.adsetStats[0]?.budget}</span>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                            <td>
                              {adsetIns?.adsetStats?.length &&
                              adsetIns.adsetStats[0]?.bidding_strategy ? (
                                <span>
                                  {" "}
                                  {adsetIns.adsetStats[0]?.bidding_strategy}{" "}
                                </span>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
  
                            {dataHeads.map((currentDataHead, i) => {
                              if (!currentDataHead.isDefaultHead) {
                                return (
                                  <td
                                    key={i + 5}
                                    className={
                                      currentDataHead.isHeadRightAlign
                                        ? "text-right-alignn "
                                        : ""
                                    }
                                  >
                                    {bindChildValueColumns(adsetIns, currentDataHead)}
                                  </td>
                                );
                              }
                            })}
                          </tr>
                        );
                      })}
  
                      <tr
                        className={
                          data.isPlusIconDisabled
                            ? "hide"
                            : "edit-adgroup-text-row campaign-details"
                        }
                      >
                        {/* <td className="blank-first-adset-td-column"></td> */}
                        <td></td>
                        <td
                          className="edit-adgroup-td campaign-details-data"
                          onClick={() => {
                            setAdGroupModalVisible(true);
                            setCurrentCampaignData(data);
                          }}
                         
                        >
                          <div className=" text-center cursor-pointer-global">
                           <b>+</b> Create New Ad Group
                          </div>
                        </td>
                        <td  colSpan={(dataHeads?.length - 2)}></td>
                      </tr>
                      </>:""
                     
                    }
                  
                  </React.Fragment>
                );
              })}
            </tbody>
          ) : (
            <div className="d-flex ">
              <p>NO DATA FOUND</p>
            </div>
          )}
        </table>
      ) : (
        <div className="ndf-text-container">
          <p>NO DATA FOUND</p>
          {/* <Spinner size={"lg"} shape={"border"}/> */}
        </div>
      )}
    </div>
  );
  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box"
    >
      <Breadcrumb />
      {
        <div className="campaign-list-container">
          {
            adGroupModalVisible &&  <AdGroupModal
            setAdGroupModalVisible={setAdGroupModalVisible}
            data={currentCampaignData}
            adGroupModalVisible={adGroupModalVisible}
          />
          }
          <Loader visible={initialLoad}></Loader>
          <div>
            {
              <FilterLogicModal
                filterFor="campaignlist"
                show={isFilterVisible}
                cancelSpecifiNumberFilter={cancelSpecifiNumberFilter}
              ></FilterLogicModal>
            }
          </div>
          <div>
            {
              <MasterTagetinngActionAlertMModal
                // onYesClick={actioAlertYesClickhandler}
                alertText={showAlertMsgOf}
                onYesClick={onYesClickHandler}
                onNoClick={NoClickHandler}
                confirmationId={confirmationId}
              ></MasterTagetinngActionAlertMModal>
            }
          </div>
          <Stack>
            <div className="section-heading cl-bottom-margin">
              <Stack
                direction="horizontal"
                gap={4}
                className="d-flex align-item-center"
              >
                <SectionTitle sectionTitleHeading="Campaigns"></SectionTitle>
                {/* <Stack className="d-flex align-item-center justify-content-center">
                  <img
                    src={settingIcon}
                    onClick={onFilterIconhandler}
                    style={{ cursor: "pointer" }}
                    alt={"settingsIcon"}
                  ></img>
                </Stack> */}
              </Stack>
            </div>
            <div className="line-column-graph-main">
              <div
                className={
                  dashboardSideBarVisibiltyState
                    ? "line-column-graph-container-on-sidebar-open"
                    : "line-column-graph-container"
                }
              >
                <div className="position-relative-container">
                  <div className="line-column-graph-legends">
                    <span className="line-column-graph-title">
                      Campaigns Spends v/s ROAS
                    </span>
                    <div className="d-flex gap-3">
                      <LegendComponent
                        legendColor={"#1D216E"}//7078CC
                        legendText={"Prospect Campaign"}
                      ></LegendComponent>
                      <LegendComponent
                        legendColor={"#50A5B5"}//70BDCC
                        legendText={"Retargeting Campaign"}
                      ></LegendComponent>
                      <span className="dotted-line">.......</span>
                      ROAS
                    </div>
                  </div>
                  {/* <div> */}
                  <LineColumnGraph
                    LineData={mainData?.totalRoasArray}
                    ColumnData1={mainData?.retargetingSpends}
                    ColumnData2={mainData?.prospectSpends}
                    ColumnData3={mainData?.prospectRoasArray}
                    ColumnData4={mainData?.retargetRoasArray}
                    XaxisData={mainData?.datesData}
                  />
                </div>
                <div className="line-column-graph-foter">
                  <div className="dates-description-graph">
                    *Data shown of last {mainData?.datesData?.length} Weeks (
                    {moment(
                      moment().subtract(42, "days")._d,
                      "DD-MM-YYYY"
                    ).format("Do MMMM, YYYY")}{" "}
                    to{" "}
                    {moment(
                      moment().subtract(1, "days")._d,
                      "DD-MM-YYYY"
                    ).format("Do MMMM, YYYY")}
                    )
                  </div>

                  {/*Use Legend Component here  */}
                </div>
              </div>
            </div>
            <div className="cl-config-row bottom-margin">
              <Stack className="mt-config-btns-container flex-wrap">
                <Stack
                  direction="horizontal"
                  className="list-pages-search-input-container mb-1"
                >
                  <Stack
                    direction="vertical"
                    className="justify-content-end align-item-end"
                  >
                    <SearchBarForCampaignListOnly
                      placeholder="Campaign Name"
                      onClickCancel={cancelIconInSearchInput}
                      searchBarForListPage={true}
                      onChange={(e) => inputHandler(e.target.value)}
                      // onCancelIconClick={cancelIconInSearchInput}
                      // onKeyDown={onSearchBarEnterClicked}
                      id="cl-campaign-list"
                      className="mt-custom-search-bar"
                      dynamicWidth="search-input-width-for-listing-pages"
                      defaultValue={
                        campNameLinkFromMasterTargeting
                          ? campNameLinkFromMasterTargeting
                          : ""
                      }
                    ></SearchBarForCampaignListOnly>
                  </Stack>
                </Stack>

                <Stack direction="horizontal" className="d-flex justify-content-center flex-wrap">
                  <Stack className="mt-filter-gap mb-1" >
                    <MultiSelectCustomDropDown
                      checkedItemCount={
                        multiSelectDropDownSelectedDataCount.objective
                      }
                      allSeletHandler={(e) =>
                        allSeletHandler(
                          e,
                          "Campaign Type",
                          "cl-campaign_type",
                          "objective"
                        )
                      }
                      showCheckedAppliedFilteringWhenOPen={() =>
                        showCheckedAppliedFilteringWhenOPen(
                          "Campaign Type",
                          "cl-campaign_type",
                          "objective"
                        )
                      }
                      onDashIconHandlerToremoveAllCheckedOption={(
                        e,
                        targettingValue,
                        fieldName
                      ) =>
                        onDashIconHandlerToremoveAllCheckedOption(
                          e,
                          targettingValue,
                          fieldName,
                          "cl-campaign_type",
                          "objective"
                        )
                      }
                      targettingValue={"Campaign Type"}
                      // onDropdownImghandler={onDropdownImghandler}
                      src={dropdownIcon}
                      status={"objective"}
                      onApplyClickHandler={() => onApplyBtnhandler("objective")}
                      onCloseOutSideDropDownList={() =>
                        onCloseOutSideDropDownList(
                          "Campaign Type",
                          "cl-campaign_type",
                          "objective"
                        )
                      }
                      data={DropDownDataList}
                      fieldName={"cl-campaign_type"}
                      selectedOption={checkedDropDownFilterButtonData}
                      onChange={(e) =>
                        onOptionCheckedHandlerInDropDownFilter(
                          e,
                          e.target.value,
                          "objective",
                          "Campaign Type",
                          "cl-campaign_type"
                        )
                      }
                    />
                  </Stack>

                  <Stack className="mt-filter-gap mb-1">
                    <MultiSelectCustomDropDown
                      checkedItemCount={
                        multiSelectDropDownSelectedDataCount.targeting_objective
                      }
                      allSeletHandler={(e) =>
                        allSeletHandler(
                          e,
                          "Objective",
                          "cl-campaign_objective",
                          "targeting_objective"
                        )
                      }
                      showCheckedAppliedFilteringWhenOPen={() =>
                        showCheckedAppliedFilteringWhenOPen(
                          "Objective",
                          "cl-campaign_objective",
                          "targeting_objective"
                        )
                      }
                      onDashIconHandlerToremoveAllCheckedOption={(
                        e,
                        targettingValue,
                        fieldName
                      ) =>
                        onDashIconHandlerToremoveAllCheckedOption(
                          e,
                          targettingValue,
                          fieldName,
                          "cl-campaign_objective",
                          "targeting_objective"
                        )
                      }
                      targettingValue={"Objective"}
                      // onDropdownImghandler={onDropdownImghandler}
                      src={dropdownIcon}
                      status={"targeting_objective"}
                      onApplyClickHandler={() =>
                        onApplyBtnhandler("targeting_objective")
                      }
                      onCloseOutSideDropDownList={() =>
                        onCloseOutSideDropDownList(
                          "Objective",
                          "cl-campaign_objective",
                          "targeting_objective"
                        )
                      }
                      data={DropDownDataList}
                      fieldName={"cl-campaign_objective"}
                      selectedOption={checkedDropDownFilterButtonData}
                      onChange={(e) =>
                        onOptionCheckedHandlerInDropDownFilter(
                          e,
                          e.target.value,
                          "targeting_objective",
                          "Objective",
                          "cl-campaign_objective"
                        )
                      }
                    />
                  </Stack>

                  <Stack className="mt-filter-gap mb-1">
                    <MultiSelectCustomDropDown
                      checkedItemCount={
                        multiSelectDropDownSelectedDataCount.amz_targeting_type
                      }
                      // title={"Targeting Type"}
                      allSeletHandler={(e) =>
                        allSeletHandler(
                          e,
                          "Targeting Type",
                          "cl-targeting-type-filed",
                          "amz_targeting_type"
                        )
                      }
                      showCheckedAppliedFilteringWhenOPen={() =>
                        showCheckedAppliedFilteringWhenOPen(
                          "Targeting Type",
                          "cl-targeting-type-filed",
                          "amz_targeting_type"
                        )
                      }
                      onApplyClickHandler={() =>
                        onApplyBtnhandler("amz_targeting_type")
                      }
                      onCloseOutSideDropDownList={() =>
                        onCloseOutSideDropDownList(
                          "Targeting Type",
                          "cl-targeting-type-filed",
                          "amz_targeting_type"
                        )
                      }
                      // filterCampaigns={filterCampaigns}
                      targettingValue={"Targeting Type"}
                      // onDropdownImghandler={onDropdownImghandler}
                      src={dropdownIcon}
                      status={"amz_targeting_type"}
                      fieldName={"cl-targeting-type-filed"}
                      selectedOption={checkedDropDownFilterButtonData}
                      onDashIconHandlerToremoveAllCheckedOption={(
                        e,
                        targettingValue,
                        fieldName
                      ) =>
                        onDashIconHandlerToremoveAllCheckedOption(
                          e,
                          targettingValue,
                          fieldName,
                          "cl-targeting-type-filed",
                          "amz_targeting_type"
                        )
                      }
                      onChange={(e) =>
                        onOptionCheckedHandlerInDropDownFilter(
                          e,
                          e.target.value,
                          "amz_targeting_type",
                          "Targeting Type",
                          "cl-targeting-type-filed"
                        )
                      }
                      // targetingTypeSelectButtonOptionsVisibilty={
                      //   targetingTypeSelectButtonOptionsVisibilty
                      // }

                      data={DropDownDataList}
                    />
                  </Stack>

                    <>
                  <Stack className="mt-filter-gap mb-1">
                    <ChoosePortfolio
                      className="selected-opt-show-btn campaign-objective"
                      id="objective"
                      // title={"Portfolio"}
                      targettingValue={targettingType}
                      setTargettingType={setTargettingType}
                      src={dropdownIcon}
                      type={"amz_portfolio_id"}
                      // selectDropDown={selectDropDown}
                      // setSelectDropDown={setSelectDropDown}
                      portfolioWithoutLabel={true}
                      filterCampaigns={filterCampaigns}
                    ></ChoosePortfolio>
                  </Stack>
                  </>

                  <Stack className="mt-filter-gap mb-1 position-relative d-none">
                    <button
                      className="date-container date-btn-campaignlist "
                      onClick={dateInputClickHandler}
                    >
                      <div className="d-flex justify-conntent-center">
                        <span className="date-btn-text">{dateDifference}</span>
                      </div>
                      <button className="btn-calender-img-holder">
                        <span className="">
                          <img
                            className="dropdown-img-for-select"
                            src={selectSideIcon}
                            alt={"SelectSideIcon"}
                          ></img>

                          <img
                            className="calender-icon-for-campaignlist"
                            src={CalenderIconForCampaignList}
                            alt={"CalendarIcon"}
                          ></img>
                        </span>
                      </button>
                    </button>
                  </Stack>
                </Stack>
                <div
                  ref={calenderIconRefForCampaignlist}
                  className={
                    showCustomDateBox
                      ? dashboardSideBarVisibiltyState
                        ? "calender-margin-top-when-sidebar-open-for-campaignlist"
                        : "calender-inclasslist-container"
                      : "hide"
                  }
                >
                  <CalenderComponent setShowCustomDateBox={setShowCustomDateBox}></CalenderComponent>
                  <span className="calender-close-icon-campaignlist">
                    <img
                      onClick={() => setShowCustomDateBox(false)}
                      src={closeIcon}
                      alt={"CloseIcon"}
                    ></img>
                  </span>
                </div>
              </Stack>
            </div>

            <Stack direction="horizontal">
              <Stack className="d-flex flex-row ">
                <div className="d-flex margin-top-cl">
                  <div>
                    <SecondaryBtnRb
                      secondaryBtnText={`All (${totalEnabledCampaign.length + totalPausedCampaign.length
                        })`}
                      className={
                        selectedBtn === "all-btn"
                          ? "primary-active-btn cl-ennable-paused-btns-height"
                          : "primary-inactive-btn cl-ennable-paused-btns-height"
                      }
                      id="all-btn"
                      onClick={(e, type, value) =>
                        onButtonClickHandler(e, "status", "All")
                      }
                    ></SecondaryBtnRb>
                  </div>
                  <div>
                    <SecondaryBtnRb
                      secondaryBtnText={`Enabled (${totalEnabledCampaign.length
                          ? totalEnabledCampaign.length
                          : 0
                        })`}
                      className={
                        selectedBtn === "enabled-btn"
                          ? "primary-active-btn cl-ennable-paused-btns-height"
                          : "primary-inactive-btn cl-ennable-paused-btns-height"
                      }
                      id="enabled-btn"
                      onClick={(e, type, value) =>
                        onButtonClickHandler(e, "status", "ENABLED")
                      }
                    ></SecondaryBtnRb>
                  </div>
                  <div>
                    <SecondaryBtnRb
                      secondaryBtnText={`Paused (${totalPausedCampaign.length
                          ? totalPausedCampaign.length
                          : 0
                        })`}
                      className={
                        selectedBtn === "PAUSED"
                          ? "primary-active-btn cl-ennable-paused-btns-height "
                          : "primary-inactive-btn cl-ennable-paused-btns-height"
                      }
                      id="PAUSED"
                      value="PAUSED"
                      onClick={(e, type, value) =>
                        onButtonClickHandler(e, "status", "PAUSED")
                      }
                    ></SecondaryBtnRb>
                  </div>
                  <div
                    className="draft-text-container cursor-pointer-global"
                    onClick={onDraftClickHandler}
                  >
                    <span className="draft-text">Draft</span>
                  </div>
                </div>
                <Stack className="d-flex flex-row gap-2">
                  <div className="filter-icon-container-main">
                    <div
                      className={
                        minMaxFilterActivated
                          ? "activated-min-max-filter"
                          : "hide"
                      }
                    ></div>
                    {/* <div className="filter-icon-container-main"> */}
                    <img
                      src={settingIcon}
                      onClick={onFilterIconhandler}
                      style={{ cursor: "pointer" }}
                      alt={"settingsIcon"}
                      className={"settings-icon"}
                    ></img>
                    {/* </div> */}
                  </div>

                </Stack>
                <div className="cl-breadcrumb-wrapper">
                  <ReportsNavigator data={reportMenus}/>
                </div>
              </Stack>
            </Stack>
            <div className="d-flex align-items-start justify-content-end w-100 mt-2">
                <Link to={"#"} className={"text-muted mt-1"} style={{fontSize:"12px"}}>* SB campaigns Data Not Present</Link>
            </div>
            <Stack
              className={droDownFilterDataForApi.length ? "pt-3 " : "hide"}
            >
              <FilterChips
                filterChipList={droDownFilterDataForApi}
                onCancelClickRemoveFilteringHandler={(fieldNameForAPIFilter) =>
                  onCancelClickRemoveFilteringHandlerr(fieldNameForAPIFilter)
                }
                onResetButtonClick={onResetAllFilteringBTnHandler}
                isResetButtonNeeded={true}
              ></FilterChips>
            </Stack>
    
            <div className="campaign-list-table-data">
              <Stack className="campaign-list-main-table">

                <TableRb tableHeads={dataHeads} noDataFound={!searchfilteredData?.length} shimmer={isLoaderVisible} table={tableBody}></TableRb>
              </Stack>
            </div>
          </Stack>
        </div>
      }
    </div>
  );
};

export default CampaignList;
