import React, { useEffect, useMemo } from "react";
import { useState, useRef } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { campaignListActions } from "../../redux/slices/campaignList";

// Stylesheet
import "../bidOptimiser/optimisebid.css";

import editIcon from "../../assets/icons/edit.svg";
import checkCircle from "../../assets/icons/check_circle.png";
import ascendingIcon from "../../assets/svgs/acendingIconSvg.svg";
import descendingIcon from "../../assets/svgs/descendingIcon.svg";
import lessIcon from "../../assets/svgs/minus_box.svg";
import { formatDate } from "../../components/Reports/Components/utils";

// Helpers || API Functions
import { masterApi } from "../../apis/masterApi";
import { useGlobalContext } from "../../context/global";
import {
  TOAST_DANGER,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_SUCCESS,
} from "../../constant/HardTypes";
import { initialDataHeadsForBidsAdjustment } from "../../constant/dataHeadsForBidsAdjustment";
import { formatDateFn } from "../../helper/common/formatDateForAPI";
// Components
import Loader from "../../components/loader";
import { Stack } from "react-bootstrap";
import SectionTitle from "../../components/sectionTitle";
import { Link } from "react-router-dom";
import MultiSelect from "../../components/DropDown/Multiselect";
import TableRb from "../../components/Tables/tableRb";
import MasterTagetinngActionAlertMModal from "../../components/modal/masterTargetinngActionnAlertMModal/masterTargetingActionnAlertModal";
import FilterChips from "../../components/FilterChips/FilterChips";
import DynamicFilterModal from "../../components/modal/DynamicFilterModal";
import EditBidModalForTargeting from "../../components/modal/editBidModalForTargeting";
import CheckboxRb from "../../components/Buttons/checkbox/checkbox";
import { useCallback } from "react";
import useInitialLoad from "../../hooks/useInitialLoad";
import modifyValueByPercentage from "../../helper/common/modifyValueByPercentage";
import useHighlightList from "../../hooks/useHighlightList";
import Breadcrumb from "../../components/Breadcrumb/ReportsBreadCrumb";
import cubejs from "@cubejs-client/core";
import useToken from "../../helper/Reports/useToken";
import {toast} from 'react-toastify';
import ReportsNavigator from "../../components/Reports/ReportsNavigator";
import { allConfirmationButtonsIds } from "../../constant/confirmActions";
import SelectionBar from "../../components/Bars/SelectionBar";

const AdjustBid = () => {
  // States || Global Contexts || Redux Imports || Global Variables
  const dashboardSideBarVisibiltyState = useSelector(
    (state) => state.dashboardData.dashboardSideBarVisibiltyState
  );
  const bulkEditBidModalVisibilty = useSelector(
    (state) => state.campaignListData.bulkEditBidModalVisibilty
  );
  const { showAlert, applyCalendar, calendarDate } = useGlobalContext();
  const dispatch = useDispatch();
  const [initialLoad, setInitialLoad] = useInitialLoad();
  const [allLoaders, setAllLoaders] = useState({ mainLoader: false });
  const [actionModalText, setActionModalText] = useState("");
  const confirmationId = allConfirmationButtonsIds['placement-bid-optimiser'][actionModalText] ?? 'placement-bid-confirmation-modal'
  const [dataHeads, setDataHeads] = useState(initialDataHeadsForBidsAdjustment);
  const [allCampaigns, setAllCampaignNames] = useState([]);
  const [allPortfolios,setAllPortfolios] = useState([]);

  const [selectedDropDownFilters, setSelectedDropDownFilters] = useState({
    campaignName: [],
    placement: [],
    campaignType: [],
    campaignFormat: [],
    strategy: [],
    targetingType:[],
    portfolioName:[]
  });

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [token, refresh] = useToken("");
  const { cubeToken, setCubeToken, clientAccountId } = useGlobalContext();
  const cubejsApi = cubejs(cubeToken, {
    apiUrl: process.env.REACT_APP_CUBE_JS_URL,
  });
  const [resultSetDRR, setResultSetDRR] = useState(null);
  const [resultSetBurnRate, setResultSetBurnRate] = useState(null);
  const [bidValues, setBidValues] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [originalDataSource, setOriginalDataSource] = useState([]);
  const tableName = "ClientDailyPlacementAmazonTable";
  const [cubeQueryMeasure, setCubeQueryMeasure] = useState([
    "ClientDailyPlacementAmazonTable.cost",
    "ClientDailyPlacementAmazonTable.sales14d",
    "ClientDailyPlacementAmazonTable.conversions14d",
    "ClientDailyPlacementAmazonTable.roas",
    "ClientDailyPlacementAmazonTable.ctr",
    "ClientDailyPlacementAmazonTable.cpc",
  ]);
  const [cubeQueryOrder, setCubeQueryOrder] = useState([
    ["ClientDailyPlacementAmazonTable.cost", "desc"],
    ["ClientDailyPlacementAmazonTable.campaignName", "desc"],
  ]);
  const [cubeQueryDimensions, setCubeQueryDimensions] = useState([
    "ClientDailyPlacementAmazonTable.campaignName",
    "ClientDailyPlacementAmazonTable.placement",
    "ClientDailyPlacementAmazonTable.campaignId",
    "ClientDailyPlacementAmazonTable.campaignFormat"
  ]);
  const [cubeQueryTimeDimensions, setCubeQueryTimeDimensions] = useState([
    {
      dimension: "ClientDailyPlacementAmazonTable.date",
      dateRange: [
        formatDate(calendarDate[0].startDate),
        formatDate(calendarDate[0].endDate),
      ],
    },
  ]);
  const [cubeQueryFilters, setCubeQueryFilters] = useState([
    {
      member: "ClientDailyPlacementAmazonTable.profileId",
      operator: "equals",
      values: [clientAccountId],
    },
  ]);
  useEffect(() => {
    const startDate = formatDate(calendarDate[0].startDate);
    const endDate = formatDate(calendarDate[0].endDate);
    if (
      startDate !== cubeQueryTimeDimensions[0]["dateRange"][0] ||
      endDate !== cubeQueryTimeDimensions[0]["dateRange"][1]
    ) {
      setIsLoaderVisible(true);
      setCubeQueryTimeDimensions((prev) => {
        let data = [...prev];
        data[0]["dateRange"] = [startDate, endDate];
        return data;
      });
    }
  }, [applyCalendar]);

  const [tableDataToShow, setTableDataToShow] = useState([]);
  const [handleHighlightList] = useHighlightList(
    dataSource,
    setDataSource
  );
  const [selectedTargeting, setSelectedTargeting] = useState([]);
  const [currentEditingIndex, setCurrentEditingIndex] = useState(null);
  const [statFilters, setStatFilters] = useState([]);

  const [lazyLoading, setLazyLoading] = useState(false);
  const [activeCancelStatFilterField, setActiveCancelStatFilterField] =
    useState("");
  const [reloadMoreData, setReloadMoreData] = useState(false);
  const [sortBy, setSortBy] = useState({ sort_by: "spends", state: "desc" });

  // Chip Filters Variable
  let dataForChips = useMemo(
    () => generateChipFilters(selectedDropDownFilters, statFilters),
    [selectedDropDownFilters, statFilters]
  );

  // Refs
  const tableRef = useRef(null);

  useEffect(() => {
    //table data
    if (cubeToken && bidValues && bidValues.length > 0) {
      cubejsApi
        .load(
          {
            measures: cubeQueryMeasure,
            order: cubeQueryOrder,
            dimensions: cubeQueryDimensions,
            filters: cubeQueryFilters,
            timeDimensions: cubeQueryTimeDimensions,
            limit: 5000,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": true,
            },
          }
        )
        .then((resultSet) => {
          let resultData = resultSet.loadResponse.results[0].data.map(
            (data, index) => {
             const bidvalue = getCampaignBidvalue(
              bidValues,
              data[`${tableName}.campaignId`],
              data[`${tableName}.${dataHeads[1].id}`]
            )
        return  {
              ...data,
              id:getCampaignId(bidValues,data[`${tableName}.campaignId`],),
              bid: bidvalue,
              bid_copy: bidvalue,
              stratagy: getCampaignBidStratagy(
                bidValues,
                data[`${tableName}.campaignId`]
              ),
              drrBurnRate: getCampaignBurnRateById(
                resultSetDRR,
                data[`${tableName}.campaignId`]
              ),
              burnRate: getCampaignBurnRateById(
                resultSetBurnRate,
                data[`${tableName}.campaignId`]
              ),
              amz_targeting_type: getCampaignTargetingType(
                bidValues,
                data[`${tableName}.campaignId`]
              ),
              isEditable:false,
              isChecked:false,
              isDisabled:(data[`${tableName}.campaignFormat`].includes('SB'))
            }
            }
          );
          const data = updateDataFilters(resultData,statFilters,selectedDropDownFilters.strategy,selectedDropDownFilters.targetingType)
          setDataSource(data);
          setOriginalDataSource(resultData);
          setInitialLoad(false);
          setAllLoaders({ allLoaders, mainLoader: false });
        })
        .catch((error) => {
          if (error.message === "Invalid token") {
            setCubeToken(false);
            localStorage.setItem("cubeToken", null);
            refresh();
          }
        });
    }
  }, [cubeQueryFilters, cubeQueryTimeDimensions, cubeToken, cubeQueryOrder, bidValues]);

  useEffect(() => {
    const targetings = tableDataToShow?.filter(
      (targeting) => targeting?.isChecked
    );
    setSelectedTargeting(targetings);
  }, [tableDataToShow]);

  // Methods

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAllLoaders({ ...allLoaders, mainLoader: true });

        const startDate = new Date();
        const startDateInstance = new Date(startDate);
        startDateInstance.setDate(startDate.getDate() - 6).toString();
        const dataForCampaignStat = {
          level: "account",
          platform: "amazon",
          start_date: `${formatDateFn(startDateInstance)}`,
          end_date: `${formatDateFn(new Date())}`,
          fields: "campaign_id,campaign_burn_rate",
          group_by: "campaign_id",
          limit: 5000,
        };
        const dataForTodayCampaignStat = {
          level: "account",
          platform: "amazon",
          start_date: `${formatDateFn(new Date())}`,
          end_date: `${formatDateFn(new Date())}`,
          fields: "campaign_id,campaign_burn_rate",
          group_by: "campaign_id",
          limit: 5000,
        };
        const dataBidValues = {
          limit: 5000,
          source: "amazon",
        };
        const campaignStats = await masterApi(dataForCampaignStat);
        const todayCampStats = await masterApi(dataForTodayCampaignStat);
        const bidvalues = await masterApi(dataBidValues, "campaignList", false);
        const allPortfolios = await masterApi(dataBidValues,"getPortfolios",false);
        const mappedPortfolios = allPortfolios?.data?.data?.map((data) => data?.name) ?? []
        setAllPortfolios(mappedPortfolios);
        setResultSetDRR(campaignStats?.data?.data);
        setResultSetBurnRate(todayCampStats?.data?.data);
        setBidValues(bidvalues?.data?.data);
        setAllCampaignNames((prev) => {
          return bidvalues?.data?.data.map((item) => item.name);
        });
        if (campaignStats?.statusCode !== 200) {
          toast.error('Some Unknown Error Occurred While Fetching Average Burn Rate')
        }
        if (todayCampStats?.statusCode !== 200) {
          toast.error('Some Unknown Error Occurred While Fetching  Burn Rate')
        }
        if (bidvalues?.statusCode !== 200) {
          toast.error('Some Unknown Error Occurred While Fetching Bid')
        }
        setAllLoaders({ ...allLoaders, mainLoader: false });

      } catch (error) {
        setAllLoaders({ ...allLoaders, mainLoader: false });
      }
    };
    // Call the async function
    fetchData();
  }, []);

  useEffect(() => {
    const selectedTargeting = dataSource?.filter((data) => data?.isChecked);
    setSelectedTargeting([...selectedTargeting])
  },[dataSource])

  const getCampaignBurnRateById = (data, campaignId) => {
    if (data) {
      const campaign = data.find((item) => item.campaign_id == campaignId);
      if (campaign) {
        return campaign.campaign_burn_rate;
      }
    }
    return null;
  };

  const getCampaignBidvalue = (data, campaignId, key) => {
    if (data) {
      const campaign = data.find((item) => item.platform_id == campaignId);
      if (campaign) {
        if (key === "Detail Page on-Amazon") {
          return campaign?.amz_placement_product_page_bidding_percentage ?? '';
        } else if (key === "Top of Search") {
          return campaign?.amz_placement_top_bidding_percentage ?? '';
        } else if (key === "Other Placements") {
          return campaign?.amz_placement_rest_of_search_percentage ?? '';
        }
      }
    }
    return null;
  };

  const getCampaignId = (data,campaignId) => {
    if(data) {
      const campaign = data.find((item) => item.platform_id == campaignId);
      if(campaign) {
        return campaign?.id
      }
    }
    return null
  }

  const getCampaignBidStratagy = (data, campaignId) => {
    if (data) {
      const campaign = data.find((item) => item.platform_id == campaignId);
      if (campaign) {
        if (campaign.bidding.bidding_strategy === "autoForSales") {
          return "Up & Down";
        } else if (campaign.bidding.bidding_strategy === "legacyForSales") {
          return "Down";
        }
      }
    }
    return null;
  };


  const getCampaignTargetingType = (data, campaignId) => {
    if (data) {
      const campaign = data.find((item) => item.platform_id == campaignId);
      if (campaign) {
        return campaign.amz_targeting_type
      }
    }
    return null;
  };

  // *************** Function to generate Chip Filters ***************

  function fieldNameMapperForChips(name) {
    switch (name) {
      case "targeting_type":
        return "Targeting Type";
      case "campaign_type":
        return "Campaign Type";
      case "bid_strategy":
        return "Bid Strategy";
      case "campaign_objective":
        return "Campaign Objective";
      default:
        return name;
    }
  }

  function generateChipFilters(filters, dataForStatFilters) {
    const commonFilters = Object.entries(filters).flatMap(([key, value]) => {
      if (value.length < 1 || value === "All") return [];
      return {
        fieldNameOfSelectedBtn: fieldNameMapperForChips(key),
        field_name: key,
        checkedValue: !Array.isArray(value) ? [value] : value,
      };
    });

    //this will create array witch merged duplicate key in single onject
    const newArray = [];
    dataForStatFilters.forEach((obj) => {
      let newObj = {
        fieldNameOfSelectedBtn: obj.fieldNameOfSelectedBtn,
        checkedValue: obj.value,
        operator: obj?.operator,
      };
      let foundIndex = newArray.findIndex(
        (x) => x.field_name === obj.field_name
      );
      if (foundIndex === -1) {
        newArray.push({
          field_name: obj.field_name,
          values: [newObj],
          fieldNameOfSelectedBtn: obj.fieldNameOfSelectedBtn,
        });
      } else {
        newArray[foundIndex].values.push(newObj);
      }
    });
    let listForChip = [];
    let nonnAbbrivaationFieldName = [
      "spends",
      "impressions",
      "clicks",
      "orders",
      "sale_value",
    ];
    newArray?.map((currObj) => {
      let minValueForCurrentObj = 0;
      let maxValueForCurrentObj = "Infinity";
      currObj.values.map((currentDaata) => {
        if (currentDaata?.operator === "LESS_THAN_EQUAL_TO") {
          // Checking the case for -Infinity
          const checkIfNegative =
            currentDaata?.checkedValue < 0 &&
            currentDaata?.operator !== "GREATER_THAN_EQUAL_TO" &&
            currObj?.values?.length !== 2
              ? true
              : false;
          minValueForCurrentObj = checkIfNegative
            ? "-Infinity"
            : minValueForCurrentObj;
          maxValueForCurrentObj = currentDaata.checkedValue;
        }
        if (currentDaata?.operator === "GREATER_THAN_EQUAL_TO") {
          minValueForCurrentObj = currentDaata.checkedValue;
        }
      });
      listForChip.push({
        field_name: currObj.field_name.toLowerCase(),
        fieldNameOfSelectedBtn:
          currObj.field_name === "sale_value"
            ? "Revenue"
            : nonnAbbrivaationFieldName.includes(currObj.field_name)
            ? currObj.field_name.replace(/^\w/, (c) => c.toUpperCase())
            : convertComparisonFieldsForChips(currObj?.field_name),
        minValue: minValueForCurrentObj,
        maxValue: maxValueForCurrentObj,
      });
    });
    const allChipFiltersData = commonFilters.concat(listForChip);
    return allChipFiltersData;
  }

  function convertComparisonFieldsForChips(type) {
    const outputString = type.replace(/.*?_/, "");
    return outputString + "%";
    // currObj.field_name.replace(/_/g,' ').toUpperCase()
  }

  const onCancelClickRemoveFilteringHandlerr = useCallback(
    function (type) {
      setSelectedDropDownFilters((prev) => {
        return {
          ...prev,
          [type]: [],
        };
      });
      setCubeQueryFilters((prev) => {
        let data = [...prev];
        data.forEach((x, i) => {
          if (x.member === `${tableName}.${type}`) {
            data.splice(i, 1);
          }
        });
        return data;
      });
        if (statFilters?.length) {
          const statFilter = statFilters?.find((data) => data?.field_name?.toLowerCase() === type?.toLowerCase())
          setActiveCancelStatFilterField(statFilter?.field_key);
          setStatFilters((prev) => {
            return [...prev].filter((data) => data.field_name?.toLowerCase() !== type?.toLowerCase());
          });
        }
    },
    [selectedDropDownFilters, statFilters]
  );

  const onResetButtonClick = useCallback(
    function () {
      setSelectedDropDownFilters({
        ...{
          campaignName: [],
          placement: [],
          campaignType: [],
          campaignFormat: [],
          strategy: [],
          targetingType: [],
          portfolioName:[]
        },
      });
      setCubeQueryFilters([
        {
          member: "ClientDailyPlacementAmazonTable.profileId",
          operator: "equals",
          values: [clientAccountId],
        },
      ]);
      setStatFilters([]);
      setActiveCancelStatFilterField("reset");
    },
    [selectedDropDownFilters]
  );

  // ********** Methods For All DropDown Filters Change *********
  const handleDropDownsFilterChange = (e, type) => {
    setAllLoaders({ ...allLoaders, mainLoader: true });
    setSelectedDropDownFilters((prev) => {
      return {
        ...prev,
        [type]: e,
      };
    });
    if (type !== "strategy" && type !== "targetingType") {
      setCubeQueryFilters((prev) => {
        let data = [...prev];
        data.forEach((x, i) => {
          if (x.member === `${tableName}.${type}`) {
            data.splice(i, 1);
          }
        });
        if (e.length > 0) {
          data.push({
            member: `${tableName}.${type}`,
            operator: "equals",
            values: e,
          });
        }
        return data;
      });
    } else if (type === "strategy") {
      const data =  updateDataFilters(originalDataSource,statFilters,e, selectedDropDownFilters.targetingType)
      setDataSource(data)
      setAllLoaders({ allLoaders, mainLoader: false });
    } else if (type === "targetingType") {
        const data =  updateDataFilters(originalDataSource,statFilters,selectedDropDownFilters.strategy,e)
        setDataSource(data)
        setAllLoaders({ allLoaders, mainLoader: false });
      }
  };

  // *********** Methods For Fetching Data

  // ***************** All Methods For Table ********************

  // ******************** Methods For Sorting **************

  const onSort = (key, state) => {
    let convertedData = JSON.parse(JSON.stringify(dataHeads));
    convertedData = convertedData.map((head) => {
      if (head.id === key) {
        head.isSortingRequired = !head.isSortingRequired;
      } else {
        head.isSortingClicked = false;
      }
      return head;
    });
    setCubeQueryOrder([
      ["ClientDailyPlacementAmazonTable." + key, state ? "asc" : "desc"],
    ]);
    setSortBy({ ...sortBy, sort_by: key, state: state ? "asc" : "desc" });
    setDataHeads(convertedData);
  };

  // ******************* Methods For Editing Table ******************

  const onEditCurrentBid = (index) => {
    const data = structuredClone(dataSource);
    data[index].isEditable = true;
    setDataSource([...data]);
  };

  const onBidChange = (value, index) => {
    // const data = structuredClone(tableDataToShow);
    const visibleData = structuredClone(dataSource);
    // data[index].current_bid = e.target.value;
    visibleData[index].bid = value
    setDataSource([...visibleData]);
    // setTableDataToShow([...data]);
  };

  const onUpdateBid = (index) => {
    // using index recieved to send further informations and do actions
    setActionModalText("Are you sure that you want to update current bid?");
    setCurrentEditingIndex(index);
    dispatch(campaignListActions.showActionAlertModal());
  };

  const onYesClick = async () => {
    const isCurrent = actionModalText.includes("current") ? true : false;
    const data = structuredClone(dataSource);
    if (isCurrent) {
      const bidValue = parseFloat(data[currentEditingIndex]?.bid)
      const dataToPost = data[currentEditingIndex]["ClientDailyPlacementAmazonTable.placement"]=== "Top of Search" ? {amz_placement_top_bidding_percentage:bidValue}: data[currentEditingIndex]["ClientDailyPlacementAmazonTable.placement"]=== "Other Placements" ? {amz_placement_rest_of_search_percentage:bidValue} : {amz_placement_product_page_bidding_percentage:bidValue}
      const dataId = data[currentEditingIndex]?.id
      try {
        const response = await updateTargetingDataOnServer(dataToPost,dataId);
        if (response?.status !== 200) {
          toast.error('Some Unknown Error Occured while updating current Bid')
          data[currentEditingIndex].bid =
            data[currentEditingIndex]?.bid_copy; 
          setDataSource([...data]);
        } else {
          toast.success('Bid has been updated successfully!')
          data[currentEditingIndex].bid_copy =
            data[currentEditingIndex]?.bid;
          data[currentEditingIndex].isEditable = false
          setDataSource([...data]);
        }
      } catch (error) {
        console.log(error)
        toast.error(`Some Unknown Error Occured while updating current Bid`)
      }
    }
    // Commenting out as there is no need of this code. (Unnecessary code as bulk operation already being handled by other function)
    //  else {
    //   const updatedData = data?.map((dataInstance) => {
    //     const targeting = selectedTargeting?.find(
    //       (targeting) => targeting?.target_id === dataInstance?.target_id
    //     );
    //     if (targeting?.target_id === dataInstance?.target_id) {
    //       dataInstance.current_bid = dataInstance.suggested_bid;
    //       dataInstance.current_bid_copy = dataInstance.suggested_bid;
    //     }
    //     dataInstance.isChecked = false;
    //     return dataInstance;
    //   });
    //   const targetingArrayToBeSentForUpdate = selectedTargeting?.map(
    //     (targeting) => {
    //       const { targeting_expression } = targeting;
    //       targeting_expression.target[0].bid = Number(targeting?.suggested_bid);
    //       return {
    //         ...targeting_expression,
    //       };
    //     }
    //   );
    //   try {
    //     const response = {} 
    //     // await updateTargetingDataOnServer(
    //     //   targetingArrayToBeSentForUpdate
    //     // );
    //     if (response?.status !== 200) {
    //       // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
    //       //   backgroundColor: TOAST_DANGER,
    //       //   title: "Error",
    //       //   description: `Some Unknown Error Occured While Updating Bids`,
    //       // });
    //       toast.error('Some Unknown Error Occured While Updating Bids')
    //     } else {
    //       // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
    //       //   backgroundColor: TOAST_SUCCESS,
    //       //   title: "Success",
    //       //   description: `Bid has been updated successfully!`,
    //       // });
    //       toast.success(`Bid has been updated successfully!`)
    //       setDataSource([...updatedData]);
    //     }
    //   } catch (error) {
    //     // showAlert(TOAST_POSITION_TOP_RIGHT, 3000, {
    //     //   backgroundColor: TOAST_DANGER,
    //     //   title: "Error",
    //     //   description: `Some Unknown Error Occured while updating current Bid`,
    //     // });
    //     toast.error(`Some Unknown Error Occured while updating current Bid`)
    //   }
    // }
  };

  const updateTargetingDataOnServer = async (updateDataArray,campaignId=false) => {
    const myHeaders = {
      "Content-Type": 'application/merge-patch+json',
    };
    const dataToUpdate = updateDataArray;
    const raw = JSON.stringify(dataToUpdate);
    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setInitialLoad(true)
    try {
      const client_id = JSON.parse(localStorage.getItem("clientId"));
      const BASE_URL = process.env.REACT_APP_ADS_API
      const url = campaignId ? `${BASE_URL}/client/${client_id}/campaigns/${campaignId}?source=amazon&sync=false`: `${BASE_URL}/client/${client_id}/campaigns:bulk-patch-update-campaign?source=amazon`
      const response = await fetch(
        url,
        requestOptions
      );
      setInitialLoad(false)
      return response;
    } catch (error) {
      console.log(error);
      setInitialLoad(false)
    }
  };

  const onNoClick = () => {
    dispatch(campaignListActions.hideActionAlertModal());
    const data = structuredClone(dataSource);
    data[currentEditingIndex].bid =
      data[currentEditingIndex]?.bid_copy === undefined ? "" : data[currentEditingIndex]?.bid_copy
    data[currentEditingIndex].isEditable = false;
    setDataSource([...data]);
  };

  const redirectToCampaignList = (campName) => {
    if (campName) {
      window.open(
        `/campaign-list?campName=${campName}`,
        "_blank",
        "noreferrer"
      );
    }
  };

  const delegateEventsHandler = (e) => {
    if (e.target.className === "clickable-text-color") {
      redirectToCampaignList(e.target.innerText);
    }
  };

  // Table Body

  let tableBody = (
    <>
      <div
        className={
          lazyLoading
            ? "main-campaignlist-table-body-container overflow-x-hidden optimise-bids-main-table-wrapper"
            : "main-campaignlist-table-body-container optimise-bids-main-table-wrapper adjust-bid-table-wrapper"
        }
        ref={tableRef}
      >
        {dataSource && dataSource.length && resultSetDRR ? (
          <>
            <table
              className="optimise-bids-table adjustBid"
              onClick={delegateEventsHandler}
            >
              <thead className="fixed-table">
                <tr className="main-table-row-fixed">
                  {dataHeads?.map((th) => {
                    return (
                      <th
                        key={th.fieldName}
                        className={"targeting-table-heads"}
                      >
                        <div className="d-flex gap-2 align-items-center">
                          {th.fieldName === "Campaign Name" ? (
                            <>
                              <div className="targeting-selected-head captital-first-letter">
                                <span className="">
                                  <div className="parent-targeting-checkbox d-flex align-items-center">
                                    <CheckboxRb
                                      inputLabelClass="check-colum-head"
                                      // label={th.fieldName.toLowerCase()}
                                      // checked={mainTargetingSelected}
                                      onChange={(e) =>
                                        allCheckBoxHandler(e.target.checked)
                                      }
                                    ></CheckboxRb>
                                    {th.fieldName}
                                  </div>
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="d-flex gap-1 align-items-center">
                                <div
                                  className={
                                    th.id === sortBy.sort_by
                                      ? "mt-spends-icon-activated"
                                      : ""
                                  }
                                >
                                  {th.fieldName}
                                </div>
                              </div>
                            </>
                          )}
                          {th.hasOwnProperty('isSortingRequired') && (
                            <>
                              <div
                                className="cursor-pointer-global text-success"
                                onClick={() =>
                                  onSort(th.id, th.isSortingRequired)
                                }
                              >
                                <img
                                  alt="sort-icon"
                                  className={
                                    th.id === sortBy.sort_by
                                      ? "mt-spends-icon-activated"
                                      : ""
                                  }
                                  src={
                                    th.isSortingRequired
                                      ? descendingIcon
                                      : ascendingIcon
                                  }
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="another-body">
                {
                  <>
                    {dataSource?.map((data, index) => (
                      <React.Fragment key={data.id + index}>
                        {(() => {
                          return (
                            <>
                              <tr
                                key={`${data?.targeting}${index}`}
                                className={
                                  data?.isHighlighted
                                    ? "cursor-pointer highlight"
                                    : "cursor-pointer"
                                }
                                onClick={() => handleHighlightList(index)}
                              >
                                <td
                                  title={data?.targeting}
                                  style={{ minWidth: "200px" }}
                                >
                                  <div className="parent-targeting-checkbox d-flex align-items-center gap-1" title={data[`${tableName}.${dataHeads[0].id}`]}>
                                    <CheckboxRb
                                      inputLabelClass="check-colum-head"
                                      checked={data?.isChecked}
                                      disabled={data?.isDisabled}
                                      onChange={(e) => {
                                        e.stopPropagation();
                                        singleCheckBoxHandler(index);
                                      }}
                                    ></CheckboxRb>
                                    {data[`${tableName}.${dataHeads[0].id}`]
                                      ?.length > 38
                                      ? data[
                                          `${tableName}.${dataHeads[0].id}`
                                        ]?.slice(0, 35) + "..."
                                      : data[`${tableName}.${dataHeads[0].id}`]}
                                  </div>
                                </td>
                                <td>
                                  {data[`${tableName}.${dataHeads[1].id}`]}
                                </td>
                                <td>
                                  {(data[`${tableName}.campaignFormat`]==="SP") && (
                                    <div className="d-flex justify-content-evenly align-items-center budget-td-container">
                                      <input
                                        type="text"
                                        className={
                                          !data?.isEditable
                                            ? "budget-value"
                                            : "budget-value-edit"
                                        }
                                        placeholder="Set Value"
                                        disabled={!data?.isEditable}
                                        onChange={(e) => {
                                          const trimmedVal = e?.target?.value?.trim()
                                          if(!isNaN(trimmedVal)) {
                                            onBidChange(trimmedVal, index);
                                          }
                                          e.stopPropagation();
                                         
                                        }}
                                        value={data.bid}
                                      ></input>
                                      <span
                                        className={` ${data?.isDisabled ? 'disable-pointer-events' : 'edit-budget-icon-container'} `}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          !data?.isEditable
                                            ? onEditCurrentBid(index)
                                            : onUpdateBid(index);
                                        }}
                                      >
                                        <img
                                          src={
                                            !data?.isEditable
                                              ? editIcon
                                              : checkCircle
                                          }
                                          alt={"edit-update"}
                                        ></img>
                                      </span>
                                    </div>
                                  )}
                                </td>
                                <td>{data?.stratagy}</td>
                                <td>
                                  {parseFloat(
                                    data[`${tableName}.${dataHeads[4].id}`]
                                  ).toFixed(0)}
                                </td>
                                <td>
                                  {parseFloat(
                                    data[`${tableName}.${dataHeads[5].id}`]
                                  ).toFixed(0)}
                                </td>
                                <td>
                                  {data[`${tableName}.${dataHeads[6].id}`]}
                                </td>
                                <td>
                                  {data[`${tableName}.${dataHeads[7].id}`]}
                                </td>
                                <td>
                                  {data[`${tableName}.${dataHeads[8].id}`]}
                                </td>
                                <td>
                                  {data[`${tableName}.${dataHeads[9].id}`]}
                                </td>
                                <td>{parseFloat(data?.drrBurnRate).toFixed(2)}</td>
                                <td>{parseFloat(data?.burnRate).toFixed(2)}</td>
                              </tr>
                            </>
                          );
                        })()}
                      </React.Fragment>
                    ))}
                  </>
                }
              </tbody>
            </table>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  // Select one checkbox method

  const singleCheckBoxHandler = (index) => {
    const data = structuredClone(dataSource);
    if(data[index]?.bid && data[index][`${tableName}.campaignFormat`]==="SP") {
      data[index].isChecked = !data[index].isChecked;
    }
    setDataSource([...data]);
  };

  // Select All Checkbox Method

  
  const allCheckBoxHandler = (checked) => {
    setDataSource((prev) => {
      return [...prev]?.map((data) => {
        if(!data[`${tableName}.campaignFormat`]?.includes('SB') && data?.bid) {
          data.isChecked = checked;
        }
        return data;
      });
    });
  };

  // Remove all Selected Checkboxes

  const removeAllSelectedTargetings = () => {
    const data = structuredClone(dataSource);
    const updatedData = data?.map((dataInstance) => {
      dataInstance.isChecked = false;
      return dataInstance;
    });
    setDataSource([...updatedData]);
  };

  // Align with suggestedBid Handler

  const alignWithSuggestedBidhandler = () => {
    setActionModalText("Are you sure that you want to align suggested bids?");
    dispatch(campaignListActions.showActionAlertModal());
  };

  const editBidHandler = () => {
    dispatch(campaignListActions.showBulkEditBidModal());
  };

  const bidUpdationDataHandler = async (passedData) => {
    const { selectedAction, valuePercentage, setAsValue } = passedData;
    const updatedTargetingArrayData = selectedTargeting?.reduce((acc,targeting) => {
      const {id,bid} = targeting
      if(targeting["ClientDailyPlacementAmazonTable.campaignFormat"] === "SP" && bid) {
        const placement = targeting["ClientDailyPlacementAmazonTable.placement"]
      const isTopPlacement = placement?.includes("Top") ? true : false
      const isOtherPlacement = placement?.includes("Other") ? true : false
      const modifiedValue =
        selectedAction === "set_bid_as"
          ? Number(setAsValue)
          : modifyValueByPercentage(
              selectedAction,
              valuePercentage,
              bid
            );
      const data = {
        id,
        source:"amazon",
        ...(isTopPlacement ? {
          amz_placement_top_bidding_percentage:modifiedValue
        }: isOtherPlacement ? {
          amz_placement_rest_of_search_percentage:modifiedValue
        } : {
          amz_placement_product_page_bidding_percentage:modifiedValue
        })
      }
      acc.push(data)
      }
      return acc
    },[]);
    try {
      const response = await updateTargetingDataOnServer(
        updatedTargetingArrayData
      );
      if (response?.status !== 200) {
        toast.error('Some Unknown Error Occured While Updating Bids')
      } else {
        toast.success('Bids has been updated successfully!')
        // setTableDataToShow([...updatedData]);
      }
    } catch (error) {
      toast.error('Some Unknown Error Occured while updating current Bid')
    }
  };

  // FilterModal

  // Filter Modal Methods

  const columns = [
    {
      field_name: "Spends",
      field_key: "ClientDailyPlacementAmazonTable.cost",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "Orders",
      field_key: "ClientDailyPlacementAmazonTable.conversions14d",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "ROAS",
      field_key: "ClientDailyPlacementAmazonTable.roas",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "CTR",
      field_key: "ClientDailyPlacementAmazonTable.ctr",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "CPC",
      field_key: "ClientDailyPlacementAmazonTable.cpc",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "Bid Adjustment Value",
      field_key: "bid",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "DBR",
      field_key: "drrBurnRate",
      minValue: "-",
      maxValue: "-",
    },
    {
      field_name: "Burn rate",
      field_key: "burnRate",
      minValue: "-",
      maxValue: "-",
    },
  ];

  function updateDataFilters(data,metricFilters, dropDownFilters, targetingFilter) {
    let filteredData = structuredClone(data)
    if(metricFilters.length > 0) {
         filteredData = data.filter(item => {
            return metricFilters.every(filter => {
                const value = item[filter.field_key];
                if(!value) {
                  return false;
                }

                if (filter.operator === "GREATER_THAN_EQUAL_TO"   && parseFloat(value) <= parseFloat(filter.value) ) {
                    return false;
                }
        
                if (filter.operator === "LESS_THAN_EQUAL_TO" && parseFloat(value) >= parseFloat(filter.value)) {
                    return false;
                }
                
                return true;
            });
        });
    }
    
    if (dropDownFilters.length > 0) {
        filteredData =  filteredData.filter((item) => dropDownFilters.includes(item.stratagy));
    }
    if (targetingFilter.length > 0) {
        filteredData =  filteredData.filter((item) => {
            return targetingFilter.includes(item.amz_targeting_type)
        });
    }
      return filteredData      
  }
  const getAppliedFilters = (appliedFilters) => {
    setStatFilters(transformData(appliedFilters.normalFilters))
    const data = updateDataFilters(originalDataSource,transformData(appliedFilters.normalFilters),selectedDropDownFilters.strategy, selectedDropDownFilters.targetingType )
    setDataSource(data)  
  };

  function transformData(input) {
    const output = [];
    for (let i = 0; i < input?.length; i++) {
      const obj = input[i];

      // Check if the object has non-empty and non-zero values for minRange or maxRange
      if (
        (obj.minValue !== "" && obj.minValue !== 0 && obj.minValue !== "-") ||
        (obj.maxValue !== "" && obj.maxValue !== 0 && obj.maxValue !== "-")
      ) {
        // Add a new object to the output array with "GREATER_THAN" operator and the "minRange" value, if minRange is not empty or zero
        if (obj.minValue !== "" && obj.minValue !== 0 && obj.minValue !== "-") {
          output.push({
            field_name: obj?.field_name,
            field_key: obj?.field_key,
            operator: "GREATER_THAN_EQUAL_TO",
            value: obj.minValue.toString(),
          });
        }

        // Add a new object to the output array with "LESSER_THAN" operator and the "maxRange" value, if maxRange is not empty or zero
        if (obj.maxValue !== "" && obj.maxValue !== 0 && obj.maxValue !== "-") {
          output.push({
            field_name: obj?.field_name,
            field_key: obj?.field_key,
            operator: "LESS_THAN_EQUAL_TO",
            value: obj.maxValue.toString(),
          });
        }
      }
    }
    return output;
  }

  const reportMenus = [{ 
    label: "Reports",
    status: true,
    pages:[
      {
      label: "Placement Format Report",
      status: true,
      href:"/placement-format-report"
    },
  ]
  }]
   

  return (
    <div
      className={
        dashboardSideBarVisibiltyState
          ? "main-content-container-onopen-mode"
          : "main-content-container-oncolapse-mode"
      }
      id="main-box-master-ads"
    >
      <div className="campaign-list-container overflow-hidden">
        <MasterTagetinngActionAlertMModal
          alertText={actionModalText}
          onYesClick={onYesClick}
          onNoClick={onNoClick}
          confirmationId={confirmationId}
        />
        <EditBidModalForTargeting
          show={bulkEditBidModalVisibilty}
          setBidAs={true}
          getData={bidUpdationDataHandler}
          confirmationId={'bulk-placement-bid-update-confirm'}
        ></EditBidModalForTargeting>
        <Loader visible={initialLoad} />
        <Stack>
          <Breadcrumb />
          <Stack direction="horizontal" className="d-flex align-item-center">
            <SectionTitle
              sectionTitleHeading={"Placement Adjustment Bid Optimizer"}
            />
          </Stack>

          <Stack>
            <Stack
              direction="horizontal"
              className="d-flex align-item-center justify-content-between gap-3"
            >
              <Stack gap={4}>
                <MultiSelect
                  title={"Campaign Name"}
                  options={allCampaigns}
                  defaultSelected={selectedDropDownFilters.campaignName}
                  isSearch={true}
                  placeHolder={"Search Campaigns"}
                  onApply={(e) =>
                    handleDropDownsFilterChange(e, "campaignName")
                  }
                  label={"Campaign Name"}
                />
              </Stack>
              <Stack gap={4}>
                  <MultiSelect                   
                  title={"Portfolio Name"}
                  options={allPortfolios}
                  defaultSelected={selectedDropDownFilters?.portfolioName}
                  isSearch={true}
                  placeHolder={"Search Portfolios"}
                  onApply={(e) =>
                    handleDropDownsFilterChange(e, "portfolioName")
                  }
                  label={"Portfolio Name"}/>
              </Stack>
              <Stack gap={4}>
                <MultiSelect
                  title={"Campaign Type"}
                  options={["Retarget", "Prospect"]}
                  defaultSelected={selectedDropDownFilters.campaignType}
                  isSearch={false}
                  placeHolder={"Search Campaign"}
                  onApply={(e) =>
                    handleDropDownsFilterChange(e, "campaignType")
                  }
                  label={"Campaign Type"}
                />
              </Stack>
              <Stack gap={4}>
                <MultiSelect
                  title={"Targeting Type"}
                  options={["Keyword", "Product", "Automatic", "Audience"]}
                  defaultSelected={selectedDropDownFilters.targetingType}
                  isSearch={false}
                  onApply={(e) =>
                    handleDropDownsFilterChange(e, "targetingType")
                  }
                  label={"Targeting Type"}
                />
              </Stack>
              <Stack gap={4}>
                <MultiSelect
                  title={"Placement"}
                  options={[
                    "Top of Search",
                    "Detail Page on-Amazon",
                    "Other Placements",
                  ]}
                  defaultSelected={selectedDropDownFilters.placement}
                  isSearch={false}
                  placeHolder={"Search Campaign"}
                  onApply={(e) => handleDropDownsFilterChange(e, "placement")}
                  label={"Placement"}
                />
              </Stack>
              <Stack gap={4}>
                <MultiSelect
                  title={"Campaign Format"}
                  options={["SP", "SB(V)", "SB"]}
                  defaultSelected={selectedDropDownFilters.campaignFormat}
                  isSearch={false}
                  placeHolder={"Search Campaign"}
                  onApply={(e) =>
                    handleDropDownsFilterChange(e, "campaignFormat")
                  }
                  label={"Campaign Format"}
                />
              </Stack>
              <Stack gap={4}>
                <MultiSelect
                  title={"Bid Strategy"}
                  options={["Down", "Up & Down"]}
                  defaultSelected={selectedDropDownFilters.strategy}
                  isSearch={false}
                  placeHolder={"Search Campaign"}
                  onApply={(e) => handleDropDownsFilterChange(e, "strategy")}
                  label={"Bid Strategy"}
                />
              </Stack>
            </Stack>
          </Stack>
          <div className="d-flex justify-content-between">
          <div className="mt-2 d-flex gap-2">
            <DynamicFilterModal
              columns={columns}
              buttonHeading={"Filters"}
              cancelFilterFor={activeCancelStatFilterField}
              percentageColumnRequired={false}
              getAppliedFilters={getAppliedFilters}
              alignment={"left"}
            />
          </div>
          <div className="cstm__brdcrumb_wrapper mb-3">
          <ReportsNavigator data={reportMenus}/>
          </div>
          </div>
          <div className="d-flex justify-content-end">
            <SelectionBar barOptions={["Adjust Bid"]} handleCancel={removeAllSelectedTargetings} handleClick={editBidHandler} selectedCount={selectedTargeting}/>
          </div>
          <Stack className={dataForChips.length ? "pt-3 " : "hide"}>
            <FilterChips
              filterChipList={dataForChips}
              onCancelClickRemoveFilteringHandler={
                onCancelClickRemoveFilteringHandlerr
              }
              isResetButtonNeeded={true}
              onResetButtonClick={onResetButtonClick}
            ></FilterChips>
          </Stack>
          <div className="d-flex justify-content-end w-100 mt-2">
            <Link
              to={"#"}
              className={"text-muted mt-1"}
              style={{ fontSize: "12px" }}
            >
              * {`${dataSource?.length} / ${dataSource?.length} Rows`}
            </Link>
          </div>
          <small className="paginationtext">SB optimisation not supported*</small>
          <div className="optimise-bids-table-wrapper">
            <Stack className="optimise-bids-main-table">
              <TableRb
                tableHeads={dataHeads}
                noDataFound={
                  !allLoaders?.mainLoader && !initialLoad && !dataSource?.length
                }
                shimmer={allLoaders?.mainLoader}
                table={tableBody}
              ></TableRb>
            </Stack>
          </div>
        </Stack>
      </div>
    </div>
  );
};

export default AdjustBid;
