import React, { useContext, createContext, useState, useEffect , useReducer} from "react";
import { initialCampaignCreationState,initialAdgroupCreationState,initialCreativesCreationState } from "../constant/campaignCreationDefaultConfigs";
import moment from "moment/moment";
import { initialCreateCampaignErrors } from "../constant/states/campaignCreation";
import useSyncedState from "../hooks/usePersistentState";


const globalContext = createContext();
export function GlobalContextProvider({ children }) {
  // State for creating and reloading the portfolios
  const [portfolio, setPortfoliosArray] = useState([]);
  const [show, setShow] = useState(false);
  const [modalFor,setModalFor] = useState("")
  const [noMultiuserClients,setNoMultiuserClients] = useState(JSON.parse(localStorage.getItem("userAccounts"))??[]);

  // State for alert and notification
  const [alert, setAlert] = useState(null);
  const [alertDetails, setAlertDetails] = useState({
    position: "",
    autoDeleteTime: 0,
    toast: {
      backgroundColor: "",
      title: "",
      description: "",
    },
  });

  // State For Button Loading Status
  const [buttonStatus,setButtonStatus] = useState({status:false,text:"",isSpinnerRequired:false});
  const [showCMSLink,setShowCMSLink] = useState(JSON.parse(localStorage.getItem("product_type")) === "Self serve" ? false : true);

  // States For Reports
  const [reportData, setReportData] = useState(
    JSON.parse(localStorage.getItem("reportData"))
  );

  // State For adset pages
  const showAlert = (position, autoDeleteTime, toast) => {
    setAlert(true);
    setAlertDetails({ position, autoDeleteTime, toast: toast });
  };

  //multiselect dropdown open/close
  const [dropdownBtnOptionsVisibilty, setDropdownBtnOptionsVisibilty] = useState(true);

  // State for campaign creation data
  const [campaignData, setCampaignData] = useState(initialCampaignCreationState);

  // State for campaign Ad Groups
  const [adGroupData, setAdGroupData] = useState(initialAdgroupCreationState);

  // State for campaign Type

  const [campaignType, setCampaignType] = useState("prospect");

  // State for campaign Ad sets
  const [adSetData, setAdSetData] = useState(initialCreativesCreationState);

  const [adsetAsins, setAdsetAsins] = useState([]);
  const [manuallyAddedAsins, setManuallyAddedAsins] = useState([]);

  // Campaign Details page API
  const [page, setPage] = useState("");

  // Data for Searching the keywords

  const [searchData, setSearchData] = useState({});

  // Keywords Filtering Logic For Filter Keyword Modal
  const [keywords, setKeywords] = useState([]);
  const [formValues, setFormValues] = useState([
    { field_name: "keyword", operator: "CONTAINS", value: "" },
  ]); // Filters
  const [editFormValues, setEditFormValues] = useState([
    { field_name: "keyword", operator: "CONTAINS", value: "" },
  ]);
  const [isApplied, setIsApplied] = useState(false); // Will trigger API is filter apply button is clicked
  const [matchTypes, setMatchTypes] = useState(["Exact"]);
  const [manualMatchTypes, setManualMatchTypes] = useState(["Exact"]);
  const [editMatchTypes, setEditMatchTypes] = useState(["Exact"]);
  const [keywordsToBeShow, setKeywordsToBeShow] = useState([]);
  const [keywordsTobeAdded, setKeywordsTobeAdded] = useState([]);
  const [keywordsTobeFormatted, setKeywordsTobeFormatted] = useState([]);
  const [calendarFreeze,setCalendarFreeze] = useState(false)

  // Brand Keywords Filtering Options
  const [brandMatchTypes, setBrandMatchTypes] = useState(["Exact"]);
  const [manualBrandMatchTypes, setmanualBrandMatchTypes] = useState(["Exact"]);
  const [brandKeywordsToBeShow, setBrandKeywordsToBeShow] = useState([]);
  const [brandKeywordsToBeAdded, setBrandKeywordsToBeAdded] = useState([]);
  const [brandKeywordsToBeFormatted, setBrandKeywordsToBeFormatted] = useState(
    []
  );

  const keywordsPosted = {
    type: "SP_KEYWORD",
    asins: page === "third" ? manuallyAddedAsins : adsetAsins,
    keywords: keywordsTobeFormatted,
    bidding_strategy: "LEGACY_FOR_SALES",
  };

  // Categories for Campaign Creation

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedPortfolios, setselectedPortfolios] = useState("");

  // All Campaigns
  const [campaigns,setCampaigns] = useState([]);

  // Smart Ads While Campaign Creation Products Count 

  const [productSetData,setProductSetData] = useState({
    RULE_1:0,
    RULE_2:0,
    RULE_3:0
  })

  function setSmartAdsProductsData (data) {
    setProductSetData({...productSetData,...data})
  }
  
  const initialCalendarDate = [
    {
      startDate: moment().subtract(6, "days")._d,
      endDate: moment()._d,
      key: "selection",
    },
  ]

  const initialMultiCalendarDate = {
    selection1: {
      startDate: moment().subtract(6, "days")._d,
      endDate: moment()._d,
      key: "selection1",
    },
    selection2: {
        startDate: moment().subtract(13, "days")._d,
        endDate: moment().subtract(7, "days")._d,
        key: "selection2",
    }
}

  // Calendar States
  // const [calendarDate, setCalendarDate] = useState(initialCalendarDate);
  const {calendarDate,setCalendarDate} = useSyncedState(initialCalendarDate,'calendar-date','calendarDate','setCalendarDate')


  // ]);
  
  const {oldCalendarData,setOldCalendarData} = useSyncedState(initialCalendarDate,'old-calendar-date','oldCalendarData','setOldCalendarData')
  const {multiCalendarDate,setMultiCalendarDate} = useSyncedState(initialMultiCalendarDate,'multi-calendar-date','multiCalendarDate','setMultiCalendarDate')


  const {applyCalendar, setApplyCalendar} = useSyncedState(1,'apply-calendar','applyCalendar','setApplyCalendar');

  const {dateDifference, setDateDifference} = useSyncedState("Last 7 Days",'date-diff','dateDifference','setDateDifference');
  const {oldDateDifference, setOldDateDifference} = useSyncedState("Last 7 Days",'old-date-diff','oldDateDifference','setOldDateDifference')
  // Client ID and data States
  const [clientId, setClientId] = useState(
    JSON.parse(localStorage.getItem("clientId"))
  );
  const [clientAccountId, setClientAccountId] = useState(JSON.parse(localStorage.getItem("accountId")) ?? "");
  const [allClientsData, setAllClientsData] = useState([]);
  const [clientData, setClientData] = useState({});

  // Asins

  const [spAsins, setSpAsins] = useState([]);
  const [sdAsins, setSdAsins] = useState([]);
  const [spBrandAsins, setSpBrandAsins] = useState([]);
  const [sdBrandAsins, setSdBrandAsins] = useState([]);

  const [dashboardLayoutSequence, setDashboardLayoutSequence] = useState([
    "trend_graph",
    "campaign_summary",
    "category_product_performance_summary",
    "targeting",
    "low_performing_search_terms",
    "product_summary",
  ]);

  // Selected Suggested Categories of SP
  const [spSuggestedCategories, setSpSuggestedCategories] = useState([]);
  const [spSelectedSuggestedCategories, setSpSelectedSuggestedCategories] =
    useState([]);
  const [spWholeCategories, setSpWholeCategories] = useState([]);

  // Selected Suggested Categories of SD
  const [sdSelectedSuggestedCategories, setSdSelectedSuggestedCategories] =
    useState([]);
  const [sdSuggestedCategories, setSdSuggestedCategories] = useState([]);
  const [sdWholeCategories, setSdWholeCategories] = useState([]);

  // SP Brand Adgroup States
  const [
    spBrandSelectedSuggestedCategories,
    setSpBrandSelectedSuggestedCategories,
  ] = useState([]);
  const [spBrandSuggestedCategories, setSpBrandSuggestedCategories] = useState(
    []
  );
  const [spBrandWholeCategories, setspBrandWholeCategories] = useState([]);

  // SD Brand Adgroup States
  const [
    sdBrandSelectedSuggestedCategories,
    setSdBrandSelectedSuggestedCategories,
  ] = useState([]);
  const [sdBrandSuggestedCategories, setSdBrandSuggestedCategories] = useState(
    []
  );
  const [sdBrandWholeCategories, setsdBrandWholeCategories] = useState([]);

  // Edit Campaign Suggested Categories

  const [editSuggestedCategories, setEditSuggestedCategories] = useState([]);
  const [selectedEditSuggestedCategories, setSelectedEditSuggestedCategories] =
    useState([]);

  // Global State For Loader
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  // Global Campaign List
  const [editKeywords, setEditKeywords] = useState([]);
  const [editKeywordsToBeAdded, setEditKeywordsToBeAdded] = useState([]);
  const [editManualMatchTypes, setEditManualMatchTypes] = useState([]);
  const [editKeywordsLocal, setEditKeywordsLocal] = useState([]);
  const [editWholeKeywords, setEditWholeKeywords] = useState([]);
  const [editKeywordsToBeShow, setEditKeywordsToBeShow] = useState([]);
  const [cubeToken, setCubeToken] = useState(JSON.parse(localStorage.getItem("cubeToken")));

  // Manually Asins
  const [editAsins, setEditAsins] = useState([]);
  const [editWholeCategories, setEditWholeCategories] = useState([]);

  // Gloabal States For campaign List

  const [itemList, setItemList] = useState([]);
  const [overlayoutput, setoverlayoutput] = useState(false);
  const [reportsTime, setReportsTime] = useState();
  const [reportsFunnelType, setReportsFunnelType] = useState();

  const [config, setConfig] = useState();

  const createCampaignErrorsReducer = (state,payload) => {
    switch (payload.action) {
      case "setError":
      const fieldName = payload.fieldName
      return {
        ...state,
        [fieldName]:payload?.value
      }   
      case "submitErrors":
        return {
          ...state,
          ...payload?.value
        }     
      case "resetEveryError":
        return {...state,...initialCreateCampaignErrors}
      default:
      return state
    }
  }

  const [createCampaignErrors,dispatchErrors] = useReducer(createCampaignErrorsReducer,initialCreateCampaignErrors)


  

  // useEffect(() => {
  //   const getoverviewdata = () => {
  //     console.log("globalJs")
  //     axios
  //       .get(
  //         `
  //           https://adyogiadsapi.adyogi.com/client/${JSON.parse(localStorage.getItem('clientId'))}/custom-config?key=amz_dashboard_config`
  //       )
  //       .then((res) => {
  //         let overdata = res.data.value.layout_order;
  //         let sp = overdata.filter(function (item) {
  //           return item !== "overview";
  //         });
  //         setItemList(sp)
  //       });
  //   };
  //   getoverviewdata()
  // }, [clientId])

  // const getoverviewdata = () => {
  //   axios
  //     .get(
  //       `
  //       https://adyogiadsapi.adyogi.com/client/${JSON.parse(localStorage.getItem('clientId'))}/custom-config?key=amz_dashboard_config`
  //     )
  //     .then((res) => {
  //       console.log(res,"from global context")
  //       let overdata = res.data.value.layout_order;
  //       let sp = overdata.filter(function (item) {
  //         return item !== "overview";
  //       });
  //       setItemList(sp);
  //     });
  // };
  // useEffect(() => {
  //   getoverviewdata();
  // }, [clientId]);

  return (
    <>
      <globalContext.Provider
        value={{
          buttonStatus,
          setButtonStatus,
          editSuggestedCategories,
          setEditSuggestedCategories,
          selectedEditSuggestedCategories,
          setSelectedEditSuggestedCategories,
          editAsins,
          setEditAsins,
          editKeywords,
          setEditKeywords,
          editKeywordsToBeShow,
          setEditKeywordsToBeShow,
          editKeywordsToBeAdded,
          setEditKeywordsToBeAdded,
          editManualMatchTypes,
          setEditManualMatchTypes,
          editKeywordsLocal,
          setEditKeywordsLocal,
          editWholeKeywords,
          setEditWholeKeywords,
          editWholeCategories,
          setEditWholeCategories,
          isLoaderVisible,
          setIsLoaderVisible,
          overlayoutput,
          setoverlayoutput,
          portfolio,
          setPortfoliosArray,
          campaignData,
          setCampaignData,
          adGroupData,
          setAdGroupData,
          show,
          setShow,
          modalFor,
          setModalFor,
          noMultiuserClients,
          setNoMultiuserClients,
          adSetData,
          setAdSetData,
          formValues,
          setFormValues,
          editFormValues,
          setEditFormValues,
          isApplied,
          setIsApplied,
          keywords,
          setKeywords,
          matchTypes,
          setMatchTypes,
          keywordsToBeShow,
          setKeywordsToBeShow,
          keywordsTobeAdded,
          setKeywordsTobeAdded,
          categories,
          setCategories,
          calendarDate,
          setCalendarDate,
          oldCalendarData,
          setOldCalendarData,
          applyCalendar,
          setApplyCalendar,
          dateDifference,
          setDateDifference,
          oldDateDifference,
          setOldDateDifference,
          setProducts,
          products,
          selectedCategory,
          setSelectedCategory,
          clientId,
          setClientId,
          clientAccountId,
          setClientAccountId,
          allClientsData,
          setAllClientsData,
          campaignType,
          setCampaignType,
          selectedProducts,
          setSelectedProducts,
          selectedPortfolios,
          setselectedPortfolios,
          dashboardLayoutSequence,
          setDashboardLayoutSequence,
          setselectedPortfolios,
          setAlert,
          alert,
          spAsins,
          setSpAsins,
          sdAsins,
          setSdAsins,
          setManuallyAddedAsins,
          manuallyAddedAsins,
          adsetAsins,
          setAdsetAsins,
          setCalendarDate,
          spSuggestedCategories,
          setSpSuggestedCategories,
          spSelectedSuggestedCategories,
          setSpSelectedSuggestedCategories,
          spWholeCategories,
          setSpWholeCategories,
          sdSuggestedCategories,
          setSdSuggestedCategories,
          sdSelectedSuggestedCategories,
          setSdSelectedSuggestedCategories,
          sdWholeCategories,
          setSdWholeCategories,
          page,
          setPage,
          spBrandSelectedSuggestedCategories,
          setSpBrandSelectedSuggestedCategories,
          spBrandAsins,
          setSpBrandAsins,
          sdBrandSelectedSuggestedCategories,
          setSdBrandSelectedSuggestedCategories,
          sdBrandAsins,
          setSdBrandAsins,
          spBrandSuggestedCategories,
          setSpBrandSuggestedCategories,
          sdBrandSuggestedCategories,
          setSdBrandSuggestedCategories,
          sdBrandWholeCategories,
          setsdBrandWholeCategories,
          spBrandWholeCategories,
          setspBrandWholeCategories,
          clientData,
          setClientData,
          brandMatchTypes,
          setBrandMatchTypes,
          brandKeywordsToBeShow,
          setBrandKeywordsToBeShow,
          brandKeywordsToBeAdded,
          setBrandKeywordsToBeAdded,
          brandKeywordsToBeFormatted,
          setBrandKeywordsToBeFormatted,
          itemList,
          setItemList,
          showAlert,
          alertDetails,
          setAlertDetails,
          manualBrandMatchTypes,
          setmanualBrandMatchTypes,
          manualMatchTypes,
          setManualMatchTypes,
          editMatchTypes,
          setEditMatchTypes,
          reportData,
          setReportData,
          campaigns,
          setCampaigns,
          cubeToken,
          setCubeToken,
          dropdownBtnOptionsVisibilty,
          setDropdownBtnOptionsVisibilty,
          multiCalendarDate,
          setMultiCalendarDate,
          reportsTime,
          setReportsTime,
          reportsFunnelType,
          setReportsFunnelType,
          setSmartAdsProductsData,
          productSetData,
          config,
          setConfig,
          dispatchErrors,
          createCampaignErrors,
          showCMSLink,
          setShowCMSLink,
          setCalendarFreeze,
          calendarFreeze
        }}
      >
        {children}
      </globalContext.Provider>
    </>
  );
}

export function useGlobalContext() {
  return useContext(globalContext);
}
